import {
	BeslutsPeriodStudieforsakran,
	CsnBeslutsutfall,
	SearchYttrandenStatus,
	SearchYttrandenType,
} from '@local/src/Services/SwaggerApi/data-contracts'
import React from 'react'
import { Chip } from '@mui/material'

type FacetType = SearchYttrandenStatus | SearchYttrandenType | CsnBeslutsutfall | BeslutsPeriodStudieforsakran
interface Props {
	dataTestId?: string
	label: string
	facet: FacetType
	selectedFacet: FacetType
	onFacetClicked: (status: FacetType) => void
}
const FacetChip = ({ label, facet, selectedFacet, onFacetClicked, dataTestId }: Props) => {
	const handleOnClick = () => onFacetClicked(facet)
	return (
		<Chip
			data-testid={dataTestId}
			label={label}
			color="primary"
			variant={selectedFacet === facet ? 'filled' : 'outlined'}
			onClick={handleOnClick}
		/>
	)
}

export default FacetChip
