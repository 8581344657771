import { RangeDropdown } from '@local/src/Components/FormComponents/Dropdown'
import { SvarKompletterandeBidragApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { currentYear } from '@local/src/Utils/dateFormatHelper'
import { Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

interface Props {
	field: 'fromVecka' | 'tomVecka'
	index: number
	labelYear: string
	labelWeek: string
	begaranPeriod: string
	isReadOnly?: boolean
}
const FromTomVeckaInput = ({ field, labelYear, labelWeek, index, begaranPeriod, isReadOnly = false }: Props) => {
	const {
		formState: { errors },
		setValue,
	} = useFormContext<SvarKompletterandeBidragApiModel>()

	const errorMsg = errors.perioder?.[index]?.[field]?.message

	const [fromVecka, setFromVecka] = useState(begaranPeriod?.substring(4, 6))
	const [fromYear, setFromYear] = useState(Number(begaranPeriod?.substring(0, 4)))

	useEffect(() => {
		setValue(`perioder.${index}.${field}`, `${fromYear}${fromVecka}`)
	}, [fromVecka, fromYear, setValue, index, field])

	return (
		<Stack
			gap={1}
			flexDirection="row"
			mt={1}
			sx={(theme) => ({
				[theme.breakpoints.down('md')]: {
					flexDirection: 'column',
				},
			})}
		>
			<RangeDropdown
				label={labelYear}
				rangeFrom={currentYear - 1}
				rangeLength={5}
				value={fromYear}
				setValue={setFromYear}
				errorMsg={errorMsg}
				testId={`perioder.${index}.${field}.year`}
				isReadOnly={isReadOnly}
				wrapperSx={{
					background: 'white',
				}}
			/>

			<RangeDropdown
				label={labelWeek}
				rangeFrom={1}
				rangeLength={52}
				value={fromVecka}
				setValue={setFromVecka}
				testId={`perioder.${index}.${field}.week`}
				isReadOnly={isReadOnly}
				wrapperSx={{
					background: 'white',
				}}
			/>
		</Stack>
	)
}

export default FromTomVeckaInput
