import { useEffect } from 'react'
import { DeepRequired, FieldErrorsImpl } from 'react-hook-form'

export const scrollElementIntoView = (el: HTMLElement, offsetTop = 0) => {
	if (el) {
		const currentPosition = el.style.position
		const currentTop = el.getBoundingClientRect().top.toString()
		el.style.position = 'relative'
		el.style.top = `${offsetTop}px`
		el.scrollIntoView({ behavior: 'smooth', block: 'start' })
		el.style.top = currentTop
		el.style.position = currentPosition
	}
}

const useScrollToError = <T extends object>(errors: FieldErrorsImpl<DeepRequired<T>>, isSubmitted: boolean, delay = 100) => {
	useEffect(() => {
		setTimeout(() => {
			if (isSubmitted) {
				const hasErrors = Object.keys(errors).length > 0
				if (hasErrors) {
					const muiErrors = document.querySelectorAll("[class*='MuiFormHelperText-root Mui-error']")
					const errors = document.querySelectorAll("[class*='Error-module_Error_']")

					const allErrors: HTMLElement[] = []

					muiErrors.forEach((element) => {
						allErrors.push(element as HTMLElement)
					})
					errors.forEach((element) => {
						allErrors.push(element as HTMLElement)
					})

					const firstError = allErrors.sort((a, b) => a.getBoundingClientRect().top - b.getBoundingClientRect().top)[0]
					scrollElementIntoView(firstError, -200)
				}
			}
		}, delay)
	}, [isSubmitted, errors, delay])
}

export default useScrollToError
