import { SvarYttrandeEjCsnUtbildningApiModel } from '@local/Services/SwaggerApi/data-contracts'
import { TextInputField } from '@local/src/Components/FormComponents/TextInputField'
import { NumberInputField } from '@local/src/Components/FormComponents/NumberInputField'
import { useCommonTranslation } from '@local/src/Hooks'
import { Stack, Typography } from '@mui/material'
import React from 'react'
import { Path } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export interface Props {
	index: number
	isReadOnly?: boolean
}

const SvarEjCsnUtbildning = ({ index, isReadOnly }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'begaranYttrande.svar.utbildning.ejCsn' })
	const { tCommon } = useCommonTranslation()

	const currentEjCsnUtbildningPath = `yttrande.utbildningar[${index}].ejCsnUtbildning`
	const getEjCsnUtbildningPath = (path: Path<SvarYttrandeEjCsnUtbildningApiModel>) => `${currentEjCsnUtbildningPath}.${path}`
	return (
		<Stack spacing={3} data-testid={'ejcsnutbildning-testid'}>
			<Typography variant="h4">{t('isEjCsnUtbildning')}</Typography>
			<NumberInputField label={t('antalVeckor')} name={getEjCsnUtbildningPath('antalVeckor')} disabled={isReadOnly} />
			<NumberInputField label={t('omfattning')} name={getEjCsnUtbildningPath('omfattning')} disabled={isReadOnly} />
			<TextInputField label={t('organisationsnamn')} name={getEjCsnUtbildningPath('organisationsnamn')} disabled={isReadOnly} />
			<TextInputField label={t('organisationsnummer')} name={getEjCsnUtbildningPath('organisationsnummer')} disabled={isReadOnly} />
			<TextInputField label={tCommon('glossary.kontaktperson')} name={getEjCsnUtbildningPath('kontaktperson')} disabled={isReadOnly} />
			<TextInputField label={t('kontaktperson.epostadress')} name={getEjCsnUtbildningPath('epostadress')} disabled={isReadOnly} />
			<TextInputField label={t('kontaktperson.telefonnummer')} name={getEjCsnUtbildningPath('telefonnummer')} disabled={isReadOnly} />
		</Stack>
	)
}

export default SvarEjCsnUtbildning
