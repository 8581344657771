import { SelectOption } from '@local/src/Components/FormComponents/Dropdown/Dropdown.types'
import FormDropdown from '@local/src/Components/FormComponents/Dropdown/FormDropdown'
import { useAdHocYttrandeFormContext } from '@local/src/Pages/AdHocYttrande/Hooks'
import { useGetBegaranYttranden } from '@local/src/Pages/AdHocYttrande/Hooks/useGetBegaranYttranden'
import { JaNejRadioButtonGroup } from '@local/src/Pages/Common/Fragments'
import { BegaranCsnYttrandeListItemApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { isFalse } from '@local/src/Utils/BooleanHelper'
import { formatDateTime } from '@local/src/Utils/dateFormatHelper'
import { Stack } from '@mui/material'
import { isNil } from 'ramda'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	adHocYttrandeId: string
}

const SvarForBegaran = ({ adHocYttrandeId }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'adHocYttrande.form.labels' })
	const { watch, isReadOnly, setValue, resetField } = useAdHocYttrandeFormContext()
	const { isSvarForBegaran, personnummer, begaranId, ansokanId } = watch()
	const { begaranYttranden, isFetching } = useGetBegaranYttranden(personnummer)

	const filterLinkableBegaranListItems = (begaran: BegaranCsnYttrandeListItemApiModel): boolean => {
		const hasNotBeenSentToCsn = !begaran.shouldSendToCsn
		const isOkoppladOrMatchAnsokan = isNil(begaran.ansokanId) || begaran.ansokanId === ansokanId
		const isNotLinkedOrMatchAdHoc = isNil(begaran.adHocYttrandeId) || begaran.adHocYttrandeId === adHocYttrandeId
		return hasNotBeenSentToCsn && isOkoppladOrMatchAnsokan && isNotLinkedOrMatchAdHoc
	}

	const linkableBegaranList = begaranYttranden?.filter(filterLinkableBegaranListItems)
	const linkedBegaran = begaranYttranden?.find((begaran) => begaran.idBegaran === begaranId)

	const dropdownBegaranList = linkedBegaran
		? [linkedBegaran, ...linkableBegaranList.filter((begaran) => begaran.idBegaran !== linkedBegaran.idBegaran)]
		: linkableBegaranList

	const options: SelectOption[] = dropdownBegaranList.map((begaran) => ({
		label: `${begaran?.malMedStudier} - ${formatDateTime(begaran?.tidpunktBegaran)}`,
		value: begaran?.idBegaran,
	}))

	const hasLinkableBegarans = Boolean(linkableBegaranList?.length)
	const isLinked = Boolean(linkedBegaran)
	const showLinkOptions = hasLinkableBegarans || isLinked

	useEffect(() => {
		if (isFetching) return

		if (isFalse(showLinkOptions)) {
			setValue('isSvarForBegaran', false)
		}
	}, [showLinkOptions, isFetching, setValue])

	useEffect(() => {
		if (isSvarForBegaran) {
			resetField('begaranId')
		} else {
			setValue('begaranId', null)
		}
	}, [isSvarForBegaran, resetField, setValue])

	return showLinkOptions ? (
		<Stack spacing={2}>
			<JaNejRadioButtonGroup header={t('isSvarForBegaran')} name="isSvarForBegaran" valueType="boolean" disabled={isReadOnly} />
			{isSvarForBegaran && <FormDropdown disabled={isReadOnly} label={t('begaranId')} name="begaranId" options={options} />}
		</Stack>
	) : null
}

export default SvarForBegaran
