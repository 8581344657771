import { TextInputField } from '@local/src/Components'
import { useAdHocYttrandeFormContext } from '@local/src/Pages/AdHocYttrande/Hooks'
import { Delete } from '@mui/icons-material'
import { Button, IconButton, Stack } from '@mui/material'
import React, { useEffect } from 'react'
import { useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface Props {
	utbildningIndex: number
}

const OspecificeradeKurser = ({ utbildningIndex }: Props) => {
	const { control, isReadOnly, setFocus } = useAdHocYttrandeFormContext()

	const {
		append,
		remove,
		fields: ospecificeradeKurser,
	} = useFieldArray({
		control,
		name: `utbildningar.${utbildningIndex}.csnUtbildning.kurserUtbildning`,
	})

	const handleAddOspecificeradKurs = () => {
		append({ kurs: '' })

		setTimeout(() => {
			setFocus(`utbildningar.${utbildningIndex}.csnUtbildning.kurserUtbildning.${ospecificeradeKurser.length}.kurs`)
		}, 0)
	}

	const handleDeleteOspecificeradKurs = (index: number) => {
		remove(index)
	}

	useEffect(() => {
		if (ospecificeradeKurser.length === 0) {
			handleAddOspecificeradKurs()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ospecificeradeKurser])

	const { t } = useTranslation('translation', { keyPrefix: 'adHocYttrande.form.utbildningar.form.csn' })

	const showDeleteButton = !isReadOnly && ospecificeradeKurser?.length > 1

	return (
		<Stack spacing={3}>
			{ospecificeradeKurser.map((kurs, index) => (
				<Stack key={kurs.id} direction="row" alignItems="center" spacing={2}>
					<TextInputField
						wrapperSx={{ width: '100%' }}
						name={`utbildningar[${utbildningIndex}].csnUtbildning.kurserUtbildning[${index}].kurs`}
						label={t('ospecifieradeKurserInputLabel')}
						disabled={isReadOnly}
					/>
					{showDeleteButton && (
						<IconButton onClick={() => handleDeleteOspecificeradKurs(index)}>
							<Delete />
						</IconButton>
					)}
				</Stack>
			))}
			{!isReadOnly && (
				<Button data-testid="add-ospecificerad-kurs-button" variant="outlined" size="small" onClick={handleAddOspecificeradKurs}>
					{t('ospecifieradeKurserAddKursButton')}
				</Button>
			)}
		</Stack>
	)
}

export default OspecificeradeKurser
