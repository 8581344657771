import { useGetCsnArendeDetail } from '@local/src/Hooks/API'

const useGetCurrentBegaranKompletterandeBidrag = (idBegaran: string, grundansokanId: string, isEnabled = false) => {
	const { csnArendeApiModel, isLoading, invalidateQuery } = useGetCsnArendeDetail(grundansokanId, isEnabled)
	const csnkompletterandeBidrag = csnArendeApiModel?.csnKompletterandeBidrag?.find(
		(k) => k.begaranKompletterandeBidrag.idBegaran === idBegaran
	)

	return { csnkompletterandeBidrag, isLoading, invalidateQuery }
}

export default useGetCurrentBegaranKompletterandeBidrag
