import { yupResolver } from '@hookform/resolvers/yup'
import { useScrollToError } from '@local/src/Hooks'
import { begaranYttrandeSvarSchema } from '@local/src/Pages/BegaranYttrande/Edit/Components/BegaranYttrandeSvarForm/Schemas/BegaranYttrandeSvar.schema'
import { mapToAvstarYttrandeFormModel } from '@local/src/Pages/BegaranYttrande/Edit/Components/BegaranYttrandeSvarForm/Utils/svarYttrandeHelper'
import { KundTillhorTrr, StarksFramtidaStallning } from '@local/src/Services/SwaggerApi/data-contracts'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { BegaranYttrandeSvarFormData } from '../Models/BegaranYttrandeSvarForm.types'

import useGetAnsokanKomplettDatum from './useGetAnsokanKomplettDatum'

const useBegaranYttrandeSvarForm = (defaultFormData: BegaranYttrandeSvarFormData, isReadOnly: boolean) => {
	const formMethods = useForm<BegaranYttrandeSvarFormData>({
		values: defaultFormData,
		resolver: yupResolver(begaranYttrandeSvarSchema),
	})
	const {
		watch,
		setValue,
		formState: { touchedFields, errors, isSubmitted },
	} = formMethods

	const { kundTillhorTrr, yttrande } = watch()

	const formCheck = {
		notSentToCsn: !isReadOnly,
		isAvstar: yttrande?.starksFramtidaStallning === StarksFramtidaStallning.Avstar,
		isNotAvstar: yttrande?.starksFramtidaStallning !== StarksFramtidaStallning.Avstar,
		defaultIsAvstar: defaultFormData.yttrande?.starksFramtidaStallning === StarksFramtidaStallning.Avstar,
		clientIsTrrUser: kundTillhorTrr === KundTillhorTrr.KundTillhorTrr,
		clientIsNotTrrUser: kundTillhorTrr !== KundTillhorTrr.KundTillhorTrr,
	}

	const { ansokanKomplettDatum, hasValidAnsokanKomplettDatum, hasInvalidAnsokanKomplettDatum } = useGetAnsokanKomplettDatum(
		formCheck.notSentToCsn,
		defaultFormData.ansokanId
	)

	useScrollToError(errors, isSubmitted, 200)

	useEffect(() => {
		// Handles the setting of Inkom till TRR datum (ansokanKomplettDatum).
		// If the form is in read only state then use the value that is from the sent begart yttrande (that is saved in cosmos).
		// And if the date is not a valid date then don't set the value. A highlight box will be shown with error message.

		if (formCheck.clientIsTrrUser && formCheck.notSentToCsn && hasValidAnsokanKomplettDatum) {
			setValue('yttrande.datumAnsokanOo', ansokanKomplettDatum)
		}
	}, [formCheck.clientIsTrrUser, formCheck.notSentToCsn, setValue, ansokanKomplettDatum, hasValidAnsokanKomplettDatum, yttrande])

	useEffect(() => {
		// Handle starksFramtidaStallning and the properties dependent on it
		// First time we render, don't do anything
		// Or if kunTillhorOo is not set, don't do anything, the values are removed in the useEffect below
		if (!touchedFields?.yttrande?.starksFramtidaStallning || formCheck.clientIsNotTrrUser) return
		// If starksFramtidaStallning is set to it's default value, and its not avstar, set the properties bellow to there default values
		if (defaultFormData.yttrande?.starksFramtidaStallning === yttrande?.starksFramtidaStallning && !formCheck.defaultIsAvstar) {
			setValue('yttrande.orsakStarksFramtidaStallning', defaultFormData?.yttrande?.orsakStarksFramtidaStallning)
			setValue('yttrande.orsakMotivering', defaultFormData?.yttrande?.orsakMotivering)
			return
		}

		// If starksFramtidaStallning changes we need to reset the values
		setValue('yttrande.orsakStarksFramtidaStallning', null, { shouldDirty: true })
		setValue('yttrande.orsakMotivering', null)
	}, [
		touchedFields?.yttrande?.starksFramtidaStallning,
		yttrande?.starksFramtidaStallning,
		setValue,
		formCheck.clientIsNotTrrUser,
		formCheck.defaultIsAvstar,
		defaultFormData?.yttrande?.starksFramtidaStallning,
		defaultFormData?.yttrande?.orsakStarksFramtidaStallning,
		defaultFormData?.yttrande?.orsakMotivering,
	])

	useEffect(() => {
		if (formCheck.clientIsNotTrrUser) return

		if (yttrande?.orsakStarksFramtidaStallning !== 4) {
			setValue('yttrande.orsakMotivering', null)
		}
	}, [setValue, yttrande?.orsakStarksFramtidaStallning, formCheck.clientIsNotTrrUser])

	useEffect(() => {
		// Handle kundTillhorTrr
		// Always set yttrande and ansokanId if kundTillhorTrr changes,
		// This hook is dependent on values from getDefaultFormDataFromCsnYttrandeApiModel to create a valid list of utbildningar in yttrande if kundTillhorTrr is KundTillhorTrr.KundTillhorTrr

		setValue('yttrande', formCheck.clientIsTrrUser ? defaultFormData.yttrande : undefined)
		setValue('ansokanId', formCheck.clientIsTrrUser ? defaultFormData.ansokanId : undefined)
	}, [formCheck.clientIsTrrUser, setValue, defaultFormData])

	useEffect(() => {
		// Handles Avstar case
		// If kundTillhorTrr not KundTillhorTrr.KundTillhorTrr or isAvstar is false, don't do anything, yttrande should be undefined
		if (formCheck.clientIsNotTrrUser || formCheck.isNotAvstar) return

		// If avstar, set the properties to there predefined values
		const avstarYttrande = mapToAvstarYttrandeFormModel(yttrande)
		setValue('yttrande.datumAnsokanOo', avstarYttrande.datumAnsokanOo)
		setValue('yttrande.utbildningar', avstarYttrande.utbildningar)
		setValue('yttrande.kompletterandeBidrag', avstarYttrande.kompletterandeBidrag)
	}, [formCheck.isNotAvstar, formCheck.clientIsNotTrrUser, setValue, yttrande, defaultFormData])

	return { ...formMethods, hasInvalidAnsokanKomplettDatum }
}

export default useBegaranYttrandeSvarForm
