import { FacetChip } from '@local/src/Components'
import useSearchYttrande from '@local/src/Pages/CsnPortal/Yttranden/Hooks/useSearchYttrande'
import { SearchYttrandenRequestApiModel, SearchYttrandenType } from '@local/src/Services/SwaggerApi/data-contracts'
import { Grid, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	searchFilter: SearchYttrandenRequestApiModel
	setSearchFilterWithPage: (searchFilter: SearchYttrandenRequestApiModel) => void
}

const YttrandeFacetType = ({ searchFilter, setSearchFilterWithPage }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'csnPortal.yttranden.yttrandeFilterCard.yttrandeFacetType' })

	const { searchResult } = useSearchYttrande({ ...searchFilter, searchYttrandenType: undefined, pageSize: 1, page: 1 })

	const typeFacets = searchResult?.facets?.find((f) => f.name === 'Type')?.facet || []
	const adHocYttrandeCount = typeFacets.find((y) => y.name === '1')?.count ?? 0
	const begaranYttrandeCount = typeFacets.find((y) => y.name === '2')?.count ?? 0

	const handleTypeChipClick = (type: SearchYttrandenType) => {
		const toggledTyped = searchFilter.searchYttrandenType === type ? undefined : type
		setSearchFilterWithPage({ ...searchFilter, searchYttrandenType: toggledTyped })
	}

	return (
		<Grid item container direction="column" spacing={1}>
			<Grid item>
				<Typography component="div">{t('title')}</Typography>
			</Grid>
			<Grid item container xs={3} spacing={1}>
				<Grid item>
					<FacetChip
						dataTestId={'ad-hoc-yttrande-filter-chip'}
						label={t('adHocYttrandeFilterChipLabel', { count: adHocYttrandeCount })}
						facet={SearchYttrandenType.AdHocYttranden}
						selectedFacet={searchFilter.searchYttrandenType}
						onFacetClicked={handleTypeChipClick}
					/>
				</Grid>
				<Grid item>
					<FacetChip
						dataTestId={'begaran-yttrande-filter-chip'}
						label={t('begaranYttrandeFilterChipLabel', { count: begaranYttrandeCount })}
						facet={SearchYttrandenType.BegaranYttranden}
						selectedFacet={searchFilter.searchYttrandenType}
						onFacetClicked={handleTypeChipClick}
					/>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default YttrandeFacetType
