import { GoBackButton } from '@local/src/Components'
import { PageWrapper } from '@local/src/Components/PageWrapper'
import { Separator } from '@local/src/Components/Separator'
import useGetBegaranYttrande from '@local/src/Hooks/API/useGetBegaranYttrande'
import YttrandeInfo from '@local/src/Pages/BegaranYttrande/Components/YttrandeInfo/YttrandeInfo'
import { BegaranYttrandeStatus } from '@local/src/Services/SwaggerApi/data-contracts'
import { getBegaranYttrandeSvarFormData } from '@local/src/Utils/getBegaranYttrandeSvarFormData'
import { Grid2 as Grid } from '@mui/material'
import { isNotNil } from 'ramda'
import React, { Fragment } from 'react'
import { useRouteMatch } from 'react-router-dom'

import { BegaranYttrandeSvarForm, BesvaradWithAdHoc, KopplaBegaranYttrande } from './Components'

interface BegaranYttrandeDetailStateParam {
	arendeId?: string
	begaranId?: string
}

const BegaranYttrandeEditPage = () => {
	const pathEdit = `*/csn/begaran-yttrande/redigera/:begaranId/arende/:arendeId`
	const routeMatch = useRouteMatch<BegaranYttrandeDetailStateParam>(pathEdit)
	const { csnBegaranYttrande, isLoading, isError } = useGetBegaranYttrande(routeMatch?.params?.begaranId)

	const hasStudieansokanKoppling = isNotNil(csnBegaranYttrande?.ansokanId)

	const isBesvaradWithAdHoc = csnBegaranYttrande?.status === BegaranYttrandeStatus.BesvaradWithAdhocYttrande

	return (
		<PageWrapper isLoading={isLoading} errorLoadingData={isError} errorText={'Fel vid inläsning av yttrande'}>
			<Grid container spacing={4} maxWidth="md">
				<Grid size={{ xs: 12 }}>
					<GoBackButton />
				</Grid>
				<Grid size={{ xs: 12 }}>
					<YttrandeInfo begaranYttrande={csnBegaranYttrande} />
				</Grid>
				<Grid size={{ xs: 12 }}>
					<Separator />
				</Grid>
				{isBesvaradWithAdHoc ? (
					<Grid size={{ xs: 12 }}>
						<BesvaradWithAdHoc adHocYttrandeId={csnBegaranYttrande?.adHocYttrandeId} />
					</Grid>
				) : (
					<Fragment>
						<Grid size={{ xs: 12 }}>
							<KopplaBegaranYttrande
								grundansokanId={csnBegaranYttrande?.begaranYttrande.grundansokanId}
								begaranId={csnBegaranYttrande?.begaranYttrande.idBegaran}
								kopplatMedAnsokanId={csnBegaranYttrande?.ansokanId}
								isReadOnly={csnBegaranYttrande?.shouldSendToCsn}
								adHocYttrandeId={csnBegaranYttrande?.adHocYttrandeId}
							/>
						</Grid>
						<Grid size={{ xs: 12 }}>
							{hasStudieansokanKoppling && (
								<BegaranYttrandeSvarForm
									defaultFormData={getBegaranYttrandeSvarFormData(csnBegaranYttrande)}
									yttrande={csnBegaranYttrande?.begaranYttrande}
									isReadOnly={csnBegaranYttrande?.shouldSendToCsn}
								/>
							)}
						</Grid>
					</Fragment>
				)}

				<Grid size={{ xs: 12 }}>
					<GoBackButton />
				</Grid>
			</Grid>
		</PageWrapper>
	)
}

export default BegaranYttrandeEditPage
