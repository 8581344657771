import { AdHocYttrandeApiModel } from '@local/Services/SwaggerApi/data-contracts'
import { ApiResponseModel } from '@local/src/Services/ApiResponseModel'
import { csnAdHocYttrandeApi } from '@local/src/Services/apiEndpointHelper'
import { apiRequestTryCatchWrapper } from '@local/src/Services/apiRequestHelper'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { getApiResponseError } from '@local/src/Services/apiResponseHelper'

import { CreateCsnYttrandeFormModel } from '../Models/AdHocYttrande.types'

interface PostCreateYttrandeToCsn {
	formData: CreateCsnYttrandeFormModel
}

const usePostCreateAdHocYttrande = () => {
	const queryClient = useQueryClient()
	const [apiResponse, setApiResponse] = useState<ApiResponseModel<void>>(undefined)

	const mapFormToAdHocYttrandeApiModel = (data: CreateCsnYttrandeFormModel): AdHocYttrandeApiModel => ({
		datumAnsokanOo: data.datumAnsokanOo,
		kompletterandeBidrag: data.kompletterandeBidrag,
		malMedStudier: data.malMedStudier,
		orsakMotivering: data.orsakMotivering,
		orsakStarksFramtidaStallning: data.orsakStarksFramtidaStallning,
		personnummer: data.personnummer,
		starksFramtidaStallning: data.starksFramtidaStallning,
		utbildningar: data.utbildningar,
		begaranId: data.begaranId,
	})

	const postCreateYttrandeToCsnMutation = useMutation({
		mutationFn: (param: PostCreateYttrandeToCsn) => {
			setApiResponse(undefined)
			const apiRequestModel = mapFormToAdHocYttrandeApiModel(param.formData)
			const queryData = { shouldSendToCsn: param.formData.shouldSendToCsnFormBool }
			const bodyData = { ansokanId: param.formData.ansokanId, yttrande: apiRequestModel }

			return apiRequestTryCatchWrapper('usePostCreateAdHocYttrande', csnAdHocYttrandeApi.csnAdHocYttrandeCreate, queryData, bodyData)
		},
		onSuccess: async (response) => {
			setApiResponse(response)
			if (response.isSuccess) {
				await queryClient.invalidateQueries()
			}
		},
		onError: (response: { message?: string }) => {
			setApiResponse(getApiResponseError(response?.message))
		},
	})

	return { postCreateYttrandeToCsnMutation, apiResponse }
}

export default usePostCreateAdHocYttrande
