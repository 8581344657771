import AdHocYttrandeCreatePage from '@local/src/Pages/AdHocYttrande/AdHocYttrandeCreatePage'
import AdHocYttrandeEditPage from '@local/src/Pages/AdHocYttrande/AdHocYttrandeEditPage'
import BegaranYttrandeEditPage from '@local/src/Pages/BegaranYttrande/Edit/BegaranYttrandeEditPage'
import BegaranYttrandeEditTillhorPage from '@local/src/Pages/BegaranYttrande/EditTillhor/BegaranYttrandeEditTillhorPage'
import RedirectPage from '@local/src/Routing/RedirectPage/RedirectPage'
import { Container } from '@mui/material'
import React from 'react'

import { CsnBeslutTab, KompletterandeBidragFranCsnTab, UtbildningarFromCsnTab, YttrandenTab } from '../../Pages'
import { AppShellRouter, KeyRoute } from '../AppShellRouter'

interface Props {
	currentKey: string
}

const CsnRoutes = ({ currentKey }: Props) => (
	<Container data-testid="csn-fe" id="csn-fe" maxWidth="lg">
		<AppShellRouter currentKey={currentKey}>
			<KeyRoute urlKey="yttranden">
				<YttrandenTab />
			</KeyRoute>

			<KeyRoute urlKey="beslut">
				<CsnBeslutTab />
			</KeyRoute>

			<KeyRoute urlKey="kompletterande-bidrag-fran-csn">
				<KompletterandeBidragFranCsnTab />
			</KeyRoute>

			<KeyRoute urlKey="csnutbildningar">
				<UtbildningarFromCsnTab />
			</KeyRoute>

			<KeyRoute urlKey="ad-hoc-yttrande-skapa">
				<AdHocYttrandeCreatePage />
			</KeyRoute>

			<KeyRoute urlKey="ad-hoc-yttrande-redigera">
				<AdHocYttrandeEditPage />
			</KeyRoute>

			<KeyRoute urlKey="begaran-yttrande-redigera">
				<BegaranYttrandeEditPage />
			</KeyRoute>

			<KeyRoute urlKey="begaran-yttrande-redigera-tillhor">
				<BegaranYttrandeEditTillhorPage />
			</KeyRoute>

			<KeyRoute urlKey="redirect-page">
				<RedirectPage />
			</KeyRoute>
		</AppShellRouter>
	</Container>
)

export default CsnRoutes
