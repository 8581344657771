import { OspecificeradKursApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { List, ListItem, ListItemText } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	kurser?: OspecificeradKursApiModel[]
	idUtbildning: string
}
const UtbildningKurser = ({ kurser, idUtbildning }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'components.yttranden.labels' })

	return (
		<List>
			{kurser?.map((kurs) => (
				<ListItem key={`${idUtbildning}-${kurs.kurs}`} data-testid={`${idUtbildning}-${kurs.kurs}`} divider={false} disablePadding>
					<ListItemText primary={t('kursNamn')} secondary={kurs.kurs} />
				</ListItem>
			))}
		</List>
	)
}

export default UtbildningKurser
