import {
	LastDateToSvaraSearchRange,
	SearchYttrandenResponseApiModel,
	SearchYttrandenStatus,
	SearchYttrandenType,
} from '@local/src/Services/SwaggerApi/data-contracts'

export type FacetEnumMap = {
	Type: typeof SearchYttrandenType
	Status: typeof SearchYttrandenStatus
	LastDateToSvara: typeof LastDateToSvaraSearchRange
}

/**
 * Processes and maps facet data from a search result into a structured format, 
 * counting occurrences of specific facet values based on an enumeration type.
 *
 * @template K - The key of the facet being processed, corresponding to one of the keys in `FacetEnumMap`.
 * 
 * @param {SearchYttrandenResponseApiModel} searchResult - The search result object containing facet data, including a list of facets with names and associated counts.
 * @param {K} facetName - The name of the facet to extract counts for. Must match a key in `FacetEnumMap` (e.g., `Type`, `Status`, `LastDateToSvara`).
 * @param {FacetEnumMap[K]} enumObj - The enumeration object associated with the facet, mapping descriptive keys to numeric values.
 * 
 * @returns {Record<Exclude<keyof FacetEnumMap[K], 'Undefined'>, number>} 
 * An object where each key represents an enumeration value (excluding `Undefined`), and the value is the count of occurrences for that facet in the search result.
 *
 * @example
 * const searchResult = {
 *   facets: [
 *     {
 *       name: "Status",
 *       facet: [
 *         { name: "1", count: 10 },
 *         { name: "2", count: 5 },
 *       ],
 *     },
 *   ],
 * } as SearchYttrandenResponseApiModel;
 * 
 * const facetCounts = getFacetCounts(searchResult, "Status", SearchYttrandenStatus);
 * // Result:
 * // {
 * //   Active: 10,
 * //   Completed: 5
 * // }
 */

export const getFacetCounts = <K extends keyof FacetEnumMap>(
	searchResult: SearchYttrandenResponseApiModel,
	facetName: K,
	enumObj: FacetEnumMap[K]
): Record<Exclude<keyof FacetEnumMap[K], 'Undefined'>, number> => {
	const facets = searchResult?.facets?.find((f) => f.name === facetName)?.facet || []

	return Object.keys(enumObj)
		.filter((key) => isNaN(Number(key)))
		.reduce(
			(acc, key) => {
				const enumValue = enumObj[key as keyof FacetEnumMap[K]]
				if (enumValue === 0) return acc

				const facetKey = String(enumValue)
				acc[key as Exclude<keyof FacetEnumMap[K], 'Undefined'>] = facets.find((facet) => facet.name === facetKey)?.count ?? 0
				return acc
			},
			{} as Record<Exclude<keyof FacetEnumMap[K], 'Undefined'>, number>
		)
}
