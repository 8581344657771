interface IConfiguration {
	API_URL: string
	PUBLIC_WEB_API_URL: string
	MEDIA_URL: string
	AUTHORITY_URL: string
}

// This configuration will be used for local development
const compileTimeConfiguration = {
	API_URL: process.env.API_URL,
	PUBLIC_WEB_API_URL: process.env.PUBLIC_WEB_API_URL,
	MEDIA_URL: process.env.MEDIA_URL,
	AUTHORITY_URL: process.env.AUTHORITY_URL,
}

// This is the object that will be replaced in the release pipeline, do not change this
const APP_CONFIGURATION = {
	REPLACEABLE: 'CONFIG',
}

const getConfig = (): IConfiguration => ({
	...compileTimeConfiguration,
	...APP_CONFIGURATION,
})

export default getConfig
