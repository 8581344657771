import { RadioButtonGroup } from '@local/src/Components'
import { TillhandahallasAvArbetsgivare } from '@local/src/Services/SwaggerApi/data-contracts'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	header: string
	name: string
	testId?: string
	disabled?: boolean
}

const JaNejArbetslosRadioButtonGroup = ({ header, name, testId, disabled }: Props): JSX.Element => {
	const { t } = useTranslation('translation', { keyPrefix: 'common.glossary' })
	return (
		<RadioButtonGroup
			label={header}
			name={name}
			valueType="number"
			testId={testId}
			radios={[
				{
					label: t('ja'),
					value: TillhandahallasAvArbetsgivare.Ja,
					disabled: disabled,
				},
				{
					label: t('nej'),
					value: TillhandahallasAvArbetsgivare.Nej,
					disabled: disabled,
				},
				{
					label: t('arbetslos'),
					value: TillhandahallasAvArbetsgivare.Arbetslos,
					disabled: disabled,
				},
			]}
		/>
	)
}
export default JaNejArbetslosRadioButtonGroup
