/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
	KompletterandeBidragWithUserProfileMetaApiModel,
	SvarKompletterandeBidragApiModel,
	TrrProblemDetails,
	TrrValidationProblemDetails,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class CsnArendeKompletterandeBidrag<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
	/**
	 * No description
	 *
	 * @tags CsnArendeKompletterandeBidrag
	 * @name CsnArendeKompletterandeBidragList
	 * @request GET:/studiestod/csn-arende/kompletterande-bidrag
	 * @secure
	 * @response `200` `(KompletterandeBidragWithUserProfileMetaApiModel)[]` OK
	 * @response `500` `TrrProblemDetails` Internal Server Error
	 */
	csnArendeKompletterandeBidragList = (params: RequestParams = {}) =>
		this.request<KompletterandeBidragWithUserProfileMetaApiModel[], TrrProblemDetails>({
			path: `/studiestod/csn-arende/kompletterande-bidrag`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		})
	/**
	 * No description
	 *
	 * @tags CsnArendeKompletterandeBidrag
	 * @name CsnArendeKompletterandeBidragSvarCreate
	 * @request POST:/studiestod/csn-arende/{grundansokanId}/kompletterande-bidrag/{begaranId}/svar
	 * @secure
	 * @response `202` `void` Accepted
	 * @response `400` `TrrValidationProblemDetails` Bad Request
	 * @response `500` `TrrProblemDetails` Internal Server Error
	 */
	csnArendeKompletterandeBidragSvarCreate = (
		grundansokanId: string,
		begaranId: string,
		data: SvarKompletterandeBidragApiModel,
		params: RequestParams = {}
	) =>
		this.request<void, TrrValidationProblemDetails | TrrProblemDetails>({
			path: `/studiestod/csn-arende/${grundansokanId}/kompletterande-bidrag/${begaranId}/svar`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			...params,
		})
}
