import { TillhandahallasAvArbetsgivare } from '@local/Services/SwaggerApi/data-contracts'
import { requiredRadioButtonValidationText, requiredValidationText } from '@local/src/Utils/YupHelper'
import { SchemaOf, boolean as yupBoolean, mixed as yupMixed, object as yupObject } from 'yup'

import { UtbildningarFormModel } from '../Models/AdHocYttrande.types'

import { CsnUtbildningSchema } from './CsnUtbildningSchema'
import { EjCsnUtbildningSchema } from './EjCsnUtbildningSchema'
import { KoptUtbildningYttrandeApiModelSchema } from './KoptUtbildningYttrandeApiModelSchema'

const UtbildningSchema: SchemaOf<UtbildningarFormModel> = yupObject().shape({
	specifikArbetsgivare: yupBoolean().nullable().required(requiredValidationText),
	tillhandahallasAvArbetsgivare: yupMixed().nullable().required(requiredValidationText).oneOf<TillhandahallasAvArbetsgivare>([1, 2, 3]),
	isCsnUtbildning: yupBoolean().nullable().required(requiredValidationText),
	slutfordTidigare: yupBoolean().nullable().required(requiredValidationText),
	vasentligSkillnad: yupBoolean()
		.nullable()
		.when('slutfordTidigare', {
			is: true,
			then: (currentPropSchema) => currentPropSchema.required(requiredRadioButtonValidationText),
			otherwise: yupBoolean().undefinedOrNullSchema(),
		}),
	csnUtbildning: CsnUtbildningSchema.when('isCsnUtbildning', {
		is: true,
		then: CsnUtbildningSchema,
		otherwise: yupObject().undefinedOrNullSchema(),
	}),
	ejCsnUtbildning: EjCsnUtbildningSchema.when('isCsnUtbildning', {
		is: false,
		then: EjCsnUtbildningSchema,
		otherwise: yupObject().undefinedOrNullSchema(),
	}),
	koptUtbildning: KoptUtbildningYttrandeApiModelSchema.when(['isCsnUtbildning', 'csnUtbildning.uppdragsutbildning'], {
		is: (isCsnUtbildning: boolean, uppdragsutbildning: boolean) => !isCsnUtbildning || uppdragsutbildning,
		then: KoptUtbildningYttrandeApiModelSchema,
		otherwise: yupObject().undefinedOrNullSchema(),
	}),
})

export default UtbildningSchema
