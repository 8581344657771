import { EmptyResult } from '@local/src/Components'
import { PageWrapper } from '@local/src/Components/PageWrapper'
import { Card, CardContent, Chip, Grid2 as Grid, Stack, TextField, Typography, styled } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import KompletterandeBidragItem from './Components/KompletterandeBidragItem/KompletterandeBidragItem'
import { useGetKompletterandeBidrag } from './Hooks/useGetKompletterandeBidrag'
import useKompletterandeBidragFilter from './Hooks/useKompletterandeBidragFilter'
import SvarKompletterandeBidrag from './TrrSvarKompletterandeBidragToCsn/TrrSvarKompletterandeBidragToCsn'

const StyledUl = styled('ul')({
	padding: 0,
	mt: 2,
})

const StyledLi = styled('li')(({ theme }) => ({
	listStyle: 'none',
	marginBottom: theme.spacing(2),
	padding: theme.spacing(4),
	borderRadius: theme.shape.borderRadius,
	border: `1px solid ${theme.palette.grey[300]}`,
}))
const KompletterandeBidragFranCsnTab = () => {
	const { csnKompletterandeBidragFromCsn, isFetched, isError } = useGetKompletterandeBidrag()
	const { t } = useTranslation('translation', { keyPrefix: 'csnPortal.kompletterandeBidrag' })
	const { filters, filteredData, filterCounter, ssnFilter, setSsnFilter, handleToggleNewBegaran, handleToggleSentToCsn, hasFilterData } =
		useKompletterandeBidragFilter(csnKompletterandeBidragFromCsn)

	const handleSsnFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => setSsnFilter(e.target.value)

	return (
		<PageWrapper errorLoadingData={isError} isLoading={!isFetched} errorText={t('errors.hamtningsfel')} headerText={t('title')}>
			<Card>
				<CardContent>
					<Typography variant="h4" gutterBottom>
						{t('filterHeader')}
					</Typography>

					<Stack direction="row" spacing={1} mb={2}>
						<Chip
							data-testid={'filter-chip-new'}
							onClick={handleToggleNewBegaran}
							variant={filters.newBegaran ? 'filled' : 'outlined'}
							label={`Nya begäran (${filterCounter.newBegaran} st)`}
							color="primary"
						/>

						<Chip
							data-testid={'filter-chip-sent'}
							onClick={handleToggleSentToCsn}
							variant={filters.sentToCsn ? 'filled' : 'outlined'}
							label={`Skickade till CSN (${filterCounter.sentToCsn} st)`}
							color="primary"
						/>
					</Stack>

					<Grid container>
						<Grid size={{ xs: 12, md: 4 }}>
							<TextField
								value={ssnFilter}
								onChange={handleSsnFilterChange}
								label="Sök på personnummer"
								inputProps={{
									'data-testid': 'yttrande-sok-personnummer',
								}}
								fullWidth
							/>
						</Grid>
					</Grid>
				</CardContent>
			</Card>

			{hasFilterData ? (
				<StyledUl>
					{filteredData?.map((kompletterandeBidrag, index) => (
						<StyledLi key={kompletterandeBidrag.begaranKompletterandeBidrag.idBegaran} data-testid={`kompletterande-bidrag-item-${index}`}>
							<KompletterandeBidragItem kompletterandeBidrag={kompletterandeBidrag} />
							<hr />
							<SvarKompletterandeBidrag kompletterandeBidrag={kompletterandeBidrag} index={index} />
						</StyledLi>
					))}
				</StyledUl>
			) : (
				<EmptyResult isSmall heading={t('errors.noResultHeader')} description={t('errors.noResultFilterDescription')} />
			)}
		</PageWrapper>
	)
}

export default KompletterandeBidragFranCsnTab
