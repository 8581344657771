import { TextArea } from '@local/src/Components'
import { useAdHocYttrandeFormContext } from '@local/src/Pages/AdHocYttrande/Hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const MalMedStudierna = () => {
	const { isReadOnly } = useAdHocYttrandeFormContext()
	const { t } = useTranslation('translation', { keyPrefix: 'adHocYttrande.form.labels' })

	return <TextArea name="malMedStudier" label={t('malMedStudier')} rows={4} disabled={isReadOnly} />
}
