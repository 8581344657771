import { SvarYttrandeEjCsnUtbildningApiModel } from '@local/Services/SwaggerApi/data-contracts'
import { maxLengthValidationText, minLengthValidationText, requiredValidationText } from '@local/Utils/YupHelper'
import { number as yupNumber, object as yupObject, SchemaOf, string as yupString } from 'yup'

const EjCsnUtbildningSchema: SchemaOf<SvarYttrandeEjCsnUtbildningApiModel> = yupObject().shape({
	antalVeckor: yupString()
		.required(requiredValidationText)
		.test('antalVeckor', 'Värdet måste vara mindre än 1000', (value) => Number(value) <= 999)
		.test('antalVeckor', 'Värdet måste vara större än 0', (value) => Number(value) > 0),

	omfattning: yupNumber().required(requiredValidationText).min(1, minLengthValidationText).max(100, maxLengthValidationText),

	organisationsnamn: yupString().required(requiredValidationText).max(100, maxLengthValidationText),

	organisationsnummer: yupString()
		.required(requiredValidationText)
		.length(10, 'Organisationsnummer måste vara 10 siffror')
		.matches(/^[0-9]+$/, 'Måste bara bestå av siffror'),

	kontaktperson: yupString().required(requiredValidationText).max(40, maxLengthValidationText),

	epostadress: yupString()
		.required(requiredValidationText)
		.matches(/^[^@\s]+@[^@\s]+\.[^@\s]+$/, 'Måste vara en giltig e-postadress')
		.max(254, maxLengthValidationText),

	telefonnummer: yupString()
		.required(requiredValidationText)
		.max(18, maxLengthValidationText)
		.matches(/^[0-9-+]+$/, 'Måste vara ett giltigt telefonnummer'),
})

export const ejCsnUtbildningRequired = (isFranCsn: boolean, ooFinansierarKoptUtb: boolean) => !isFranCsn && ooFinansierarKoptUtb

export default EjCsnUtbildningSchema
