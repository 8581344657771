import { EmptyResult, Loading } from '@local/src/Components'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	children: React.ReactNode | React.ReactNode[]
	isRefetching: boolean
	showNoResult: boolean
}

const SearchResultWrapper = ({ children, isRefetching, showNoResult }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'csnPortal.utbildningarFromCsn.searchResultWrapper' })

	return (
		<Loading isLoading={isRefetching}>
			{showNoResult ? <EmptyResult isSmall heading={t('noResultHeading')} description={t('noResultDescription')} /> : children}
		</Loading>
	)
}
export default SearchResultWrapper
