import { CsnContent } from '../Types/App.types'

export const fallBackContent: CsnContent = {
	beslut: {
		ansokanBaseLink: 'epi:ansokanBaseLink',
	},
	redirectPage: {
		redirectTo: 'epi_redirectTo',
	},
	adHocYttrande: {
		backToStudieansokan: {
			link: 'epi:link',
		},
	},
}
