import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material'
import { isNil } from 'ramda'
import React, { ReactNode } from 'react'

interface Props {
	heading?: ReactNode | undefined
	subHeading?: ReactNode | undefined
	children?: ReactNode | undefined
	onToggle?: (expanded: boolean) => void
	expanded?: boolean
	expansionId: string
	hasError?: boolean
}
const CustomAccordionItem = ({ heading, children, expanded, onToggle, expansionId, subHeading, hasError }: Props) => {
	const renderHeading = (heading: ReactNode | string, variant: 'h5' | 'subtitle2') => {
		if (isNil(heading)) return null
		return typeof heading === 'string' ? (
			<Typography variant={variant} component="div">
				{heading}
			</Typography>
		) : (
			heading
		)
	}

	return (
		<Accordion
			sx={(theme) => ({
				border: `1px solid ${hasError ? theme.palette.error.main : theme.palette.grey[600]}`,
				boxShadow: theme.shadows[0],
				borderRadius: 1,
				mb: 2,
			})}
			expanded={expanded}
			onChange={(_, expanded) => onToggle(expanded)}
		>
			<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${expansionId}-content`} id={`${expansionId}-header`}>
				<Stack>
					{renderHeading(heading, 'h5')}
					{renderHeading(subHeading, 'subtitle2')}
				</Stack>
			</AccordionSummary>
			<AccordionDetails>{children}</AccordionDetails>
		</Accordion>
	)
}

export default CustomAccordionItem
