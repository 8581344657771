import {
	KompletterandeBidragWithUserProfileMetaApiModel,
	SvarKompletterandeBidragApiModel,
} from '@local/Services/SwaggerApi/data-contracts'
import SubmitResponse from '@local/src/Components/SubmitResponse/SubmitResponse'
import { Button } from '@mui/material'
import React, { Fragment } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { usePostSvaraKompletterandeBidrag } from '../../Hooks/usePostSvaraKompletterandeBidrag'
import { PeriodKompletterandeBidragRow } from '../Components'
import useSvarKompletterandeBidragForm from '../Hooks/useSvarKompletterandeBidragForm'

interface Props {
	kompletterandeBidrag: KompletterandeBidragWithUserProfileMetaApiModel
	svarKompletterandeBidrag: SvarKompletterandeBidragApiModel
}

const SvarKompletterandeBidragForm = ({ kompletterandeBidrag, svarKompletterandeBidrag }: Props) => {
	const { begaranKompletterandeBidrag, shouldSendToCsn } = kompletterandeBidrag
	const { postSvaraKompletterandeBidragMutation, apiResponse } = usePostSvaraKompletterandeBidrag()
	const formMethods = useSvarKompletterandeBidragForm(kompletterandeBidrag, svarKompletterandeBidrag)
	const { t } = useTranslation('translation', { keyPrefix: 'csnPortal.kompletterandeBidrag.svar' })

	const isReadOnly = shouldSendToCsn

	const {
		handleSubmit,
		formState: { isSubmitting },
	} = formMethods

	const onSubmit = async (formData: SvarKompletterandeBidragApiModel) => {
		await postSvaraKompletterandeBidragMutation.mutateAsync({
			grundansokanId: begaranKompletterandeBidrag.grundansokanId,
			begaranId: begaranKompletterandeBidrag.idBegaran,
			data: formData,
		})
	}

	return (
		<Fragment>
			<FormProvider {...formMethods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					{begaranKompletterandeBidrag.beslutsperioder.map((beslutsPeriod, index) => (
						<PeriodKompletterandeBidragRow
							key={`${beslutsPeriod.fromVecka}-${beslutsPeriod.tomVecka}`}
							beslutsPeriod={beslutsPeriod}
							index={index}
							isReadOnly={isReadOnly}
						/>
					))}
					{!isReadOnly && (
						<Button type="submit" disabled={isSubmitting} data-testid={'submit-kompletterande-bidrag'} sx={{ mt: 2 }}>
							{t('skickaInKnapp')}
						</Button>
					)}
				</form>
			</FormProvider>
			<SubmitResponse apiResponse={apiResponse} />
		</Fragment>
	)
}

export default SvarKompletterandeBidragForm
