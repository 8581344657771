import { CsnYttrandeApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { Box } from '@mui/material'
import React from 'react'

import { Arbetsgivare, Utbildningar, YttrandeFranCsnInfo } from './Components'

interface Props {
	begaranYttrande: CsnYttrandeApiModel
}

const YttrandeInfo = ({ begaranYttrande }: Props) => (
	<Box>
		<YttrandeFranCsnInfo yttrande={begaranYttrande} />
		<Arbetsgivare arbetsgivare={begaranYttrande?.begaranYttrande.arbetsgivare} />
		<Utbildningar utbildningar={begaranYttrande?.begaranYttrande.utbildningar} />
	</Box>
)

export default YttrandeInfo
