import { ApiResponseModel } from '@local/src/Services/ApiResponseModel'
import { Alert } from '@mui/material'
import React from 'react'

interface Props {
	apiResponse: ApiResponseModel<unknown>
	onClose: React.Dispatch<React.SetStateAction<boolean>>
}

const SubmitResponseAlert = ({ apiResponse, onClose }: Props) => (
	<Alert data-testid="api-error-response" severity="error" onClose={() => onClose(false)}>
		{apiResponse?.error?.errorMessage}
	</Alert>
)

export default SubmitResponseAlert
