import { CsnBeslutsperiodBeslutApiModel } from '@local/Services/SwaggerApi/data-contracts'
import { CustomAccordionItem } from '@local/src/Components/Accordion'
import LabelValueField from '@local/src/Components/LabelValueField/LabelValueField'
import SystemFeedback from '@local/src/Components/SystemFeedback/SystemFeedback'
import { booleanTojaNejValue } from '@local/src/Utils/BooleanHelper'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import RegisterPeriodFenixCheckbox from '../RegisterPeriodFenixCheckbox/RegisterPeriodFenixCheckbox'

interface Props {
	beslutsperioder: CsnBeslutsperiodBeslutApiModel
	grundansokanId: string
}

const BeslutsperioderAccordionItem = ({ beslutsperioder, grundansokanId }: Props) => {
	const [isPeriodToggled, setPeriodToggleAccordion] = useState(true)
	const { t } = useTranslation('translation', { keyPrefix: 'csnPortal.beslut.tabs' })

	const shouldShowSystemFeedback = beslutsperioder.hasBeenUpdatedAfterRegistrationInFenix
	return (
		<CustomAccordionItem
			expanded={isPeriodToggled}
			heading={` ${t('beslut.labels.perioderHeader')} (${beslutsperioder.fromVecka} - ${beslutsperioder.tomVecka})`}
			onToggle={() => setPeriodToggleAccordion(!isPeriodToggled)}
			expansionId={`custom-accordion-item-period-${beslutsperioder.fromVecka}-${beslutsperioder.tomVecka}`}
		>
			{shouldShowSystemFeedback && (
				<SystemFeedback type="warning" content={t('beslut.labels.periodHasChangedAfterRegister')} testSelector={'period-info-banner'} />
			)}

			<LabelValueField.Row>
				<LabelValueField.Column label={t('labels.fromVecka')} value={beslutsperioder.fromVecka} />
				<RegisterPeriodFenixCheckbox
					isRegisteredInFenix={beslutsperioder.isRegisteredInFenix}
					periodId={beslutsperioder.periodId}
					grundansokanId={grundansokanId}
				/>
			</LabelValueField.Row>
			<LabelValueField.Column label={t('labels.tomVecka')} value={beslutsperioder.tomVecka} />
			<LabelValueField.Row>
				<LabelValueField.Column label={t('labels.omfattningBidrag')} value={beslutsperioder.omfattningBidrag} />
				<LabelValueField.Column label={t('labels.bidragsBelopp')} value={beslutsperioder.bidragsBelopp} />
				<LabelValueField.Column
					testSelector="godkand-studieforsakran"
					label={t('labels.godkandStudieforsakran')}
					value={booleanTojaNejValue(beslutsperioder.godkandStudieforsakran)}
				/>
			</LabelValueField.Row>
		</CustomAccordionItem>
	)
}

export default BeslutsperioderAccordionItem
