import { csnAdHocYttrandeApi } from '@local/src/Services/apiEndpointHelper'
import { apiGetTryCatchWrapper } from '@local/src/Services/apiRequestHelper'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { isNotNil } from 'ramda'

const useGetAdHocYttrandeDetail = (adHocYttrandeId: string, enabled = true) => {
	const queryClient = useQueryClient()

	const queryKey = ['getAdHocYttrandeDetail', adHocYttrandeId]

	const { data: adhocYttrandeData, ...query } = useQuery({
		queryKey,
		queryFn: () => apiGetTryCatchWrapper('useGetAdHocYttrandeDetail', csnAdHocYttrandeApi.csnAdHocYttrandeDetail, adHocYttrandeId),
		enabled: enabled && isNotNil(adHocYttrandeId),
	})

	const invalidateAdHocYttrandeDetailQuery = async () => await queryClient.invalidateQueries(queryKey)

	return { adhocYttrandeData, ...query, invalidateAdHocYttrandeDetailQuery }
}

export default useGetAdHocYttrandeDetail
