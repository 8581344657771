import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorIcon from '@mui/icons-material/Error'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import InfoIcon from '@mui/icons-material/Info'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import React from 'react'

interface IconProps {
	size?: 'inherit' | 'large' | 'medium' | 'small'
	outlined?: boolean
}

const Error = ({ size = 'medium', outlined = false }: IconProps) =>
	outlined ? <ErrorOutlineIcon color="error" fontSize={size} /> : <ErrorIcon color="error" fontSize={size} />

const Warning = ({ size = 'medium', outlined = false }: IconProps) =>
	outlined ? <WarningAmberRoundedIcon color="error" fontSize={size} /> : <WarningRoundedIcon color="warning" fontSize={size} />

const Info = ({ size = 'medium', outlined = false }: IconProps) =>
	outlined ? <InfoOutlinedIcon color="error" fontSize={size} /> : <InfoIcon color="info" fontSize={size} />

const Success = ({ size = 'medium', outlined = false }: IconProps) =>
	outlined ? <CheckCircleOutlineIcon color="success" fontSize={size} /> : <CheckCircleIcon color="success" fontSize={size} />

export default {
	Error,
	Warning,
	Info,
	Success,
}
