import { SearchYttrandenRequestApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { useCurrentUser } from '@local/src/Hooks'

import { getDecryptedSearchFilter as decrypt } from '../Helpers/getDecryptedSearchFilter'
import { encryptAndStoreSearchFilterToLocalStorage } from '../Helpers/encryptSearchFilter'

export const useEncryptYttrandeSearchFilter = () => {
	const { getUserMdId } = useCurrentUser()
	const encryptionKey = getUserMdId()

	if (!encryptionKey) {
		throw new Error('Missing encryption key')
	}

	const storageKey = 'CsnPortalYttrandeEncryptedFilter'

	const encryptAndStoreSearchFilter = (searchFilter: SearchYttrandenRequestApiModel) =>
		encryptAndStoreSearchFilterToLocalStorage(searchFilter, storageKey, encryptionKey)

	const getDecryptedSearchFilter = () => decrypt(storageKey, encryptionKey)

	return { encryptAndStoreSearchFilter, getDecryptedSearchFilter }
}
