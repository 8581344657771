import { decryptSearchFilter } from '@local/src/Pages/CsnPortal/Yttranden/Helpers/decryptSearchFilter'
import { SearchYttrandenRequestApiModel } from '@local/src/Services/SwaggerApi/data-contracts'

export const getDecryptedSearchFilter = (storageKey: string, encryptionKey: string) => {
	const defaultSearchFilter: SearchYttrandenRequestApiModel = {
		page: 1,
		pageSize: 5,
		orderBy: 'CreatedAt desc',
		filters: { lastDateToSvaraSearchRanges: [] },
	}

	const encryptedData = localStorage.getItem(storageKey)

	if (!encryptedData) {
		return defaultSearchFilter
	}

	const decryptedSearchFilter = decryptSearchFilter(encryptionKey, encryptedData)

	if (!decryptedSearchFilter) {
		return defaultSearchFilter
	}

	return {
		...decryptedSearchFilter,
		filters: {
			...decryptedSearchFilter.filters,
			lastDateToSvaraSearchRanges: decryptedSearchFilter?.filters?.lastDateToSvaraSearchRanges ?? [],
		},
	} as SearchYttrandenRequestApiModel
}
