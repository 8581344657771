import { FilterChip } from '@local/src/Components'
import getFacets from '@local/src/Pages/CsnPortal/Yttranden/Hooks/useSearchYttrande'
import { SearchYttrandenRequestApiModel, SearchYttrandenType } from '@local/src/Services/SwaggerApi/data-contracts'
import { Grid2 as Grid, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { getFacetCounts } from '../Helpers/getFacetCounts'

interface Props {
	searchFilter: SearchYttrandenRequestApiModel
	setSearchFilterWithPage: (searchFilter: SearchYttrandenRequestApiModel) => void
}

const YttrandeFilterType = ({ searchFilter, setSearchFilterWithPage }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'csnPortal.yttranden.yttrandeFilterCard.yttrandeFilterType' })

	const facetSearchQuery: SearchYttrandenRequestApiModel = {
		...searchFilter,
		pageSize: 1,
		page: 1,
		filters: { ...searchFilter?.filters, type: undefined },
	}

	const { searchResult } = getFacets(facetSearchQuery)

	const counts = getFacetCounts(searchResult, 'Type', SearchYttrandenType)

	const handleTypeChipClick = (type: SearchYttrandenType) => {
		const toggledType = searchFilter?.filters?.type === type ? undefined : type
		setSearchFilterWithPage({
			...searchFilter,
			filters: { ...searchFilter?.filters, type: toggledType },
		})
	}

	const selectedFilter = searchFilter?.filters?.type

	return (
		<Grid container direction="column" spacing={1} data-testid={'yttrande-filter-type'}>
			<Grid>
				<Typography component="div">{t('title')}</Typography>
			</Grid>
			<Grid container spacing={1}>
				<Grid>
					<FilterChip
						dataTestId={'ad-hoc-yttrande-filter-chip'}
						label={t('adHocYttrandeFilterChipLabel', { count: counts.AdHocYttranden })}
						filter={SearchYttrandenType.AdHocYttranden}
						selectedFilter={selectedFilter}
						onFilterClicked={handleTypeChipClick}
					/>
				</Grid>
				<Grid>
					<FilterChip
						dataTestId={'begaran-yttrande-filter-chip'}
						label={t('begaranYttrandeFilterChipLabel', { count: counts.BegaranYttranden })}
						filter={SearchYttrandenType.BegaranYttranden}
						selectedFilter={selectedFilter}
						onFilterClicked={handleTypeChipClick}
					/>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default YttrandeFilterType
