import {
	BegaranYttrandeSvarFormData,
	UtbildningSvarFormModel,
	getDefaultFormDataFromCsnYttrandeApiModel,
} from '@local/src/Pages/BegaranYttrande/Edit/Components/BegaranYttrandeSvarForm/Models/BegaranYttrandeSvarForm.types'
import { isNotNil } from 'ramda'

import { CsnYttrandeApiModel } from '../Services/SwaggerApi/data-contracts'

export const getBegaranYttrandeSvarFormData = (currentBegaranYttrande: CsnYttrandeApiModel): BegaranYttrandeSvarFormData => {
	if (!currentBegaranYttrande) {
		return {} as BegaranYttrandeSvarFormData
	}

	const defaultFormData = getDefaultFormDataFromCsnYttrandeApiModel({
		...currentBegaranYttrande?.begaranYttrande,
		ansokanId: currentBegaranYttrande?.ansokanId,
	})

	const hasSvarBegaran = isNotNil(currentBegaranYttrande.svarBegaranYttrande)

	const yttrandeBase = hasSvarBegaran
		? { ...defaultFormData.yttrande, ...currentBegaranYttrande.svarBegaranYttrande.yttrande }
		: defaultFormData.yttrande

	const yttrandeUtbildningar: UtbildningSvarFormModel[] = []

	for (const utbildning of defaultFormData.yttrande.utbildningar) {
		let utb = utbildning
		if (hasSvarBegaran && currentBegaranYttrande?.svarBegaranYttrande?.yttrande) {
			const svarUtb = currentBegaranYttrande.svarBegaranYttrande.yttrande.utbildningar?.find(
				(u) => u.idUtbildning === utbildning.idUtbildning
			)
			utb = { ...utbildning, ...svarUtb }
		}
		yttrandeUtbildningar.push(utb)
	}
	yttrandeBase.utbildningar = yttrandeUtbildningar

	return {
		ansokanId: currentBegaranYttrande.ansokanId ?? undefined,
		grundansokanId: currentBegaranYttrande.begaranYttrande?.grundansokanId,
		kundTillhorTrr: currentBegaranYttrande.svarBegaranYttrande?.kundTillhorTrr ?? (currentBegaranYttrande?.ansokanId ? 1 : undefined),
		idBegaran: defaultFormData.idBegaran,
		yttrande: yttrandeBase,
		shouldSendToCsnFormBool: false,
	}
}
