import { csnArendeApi } from '@local/src/Services/apiEndpointHelper'
import { apiGetTryCatchWrapper } from '@local/src/Services/apiRequestHelper'
import { SearchYttrandenRequestApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { useQuery } from '@tanstack/react-query'

const useSearchYttrande = (searchQuery: SearchYttrandenRequestApiModel) => {
	const { data: searchResult, ...query } = useQuery({
		queryKey: [
			'searchYttrande',
			searchQuery?.personnummer,
			searchQuery?.searchYttrandenStatus,
			searchQuery?.searchYttrandenType,
			searchQuery?.page,
			searchQuery?.pageSize,
			searchQuery?.orderBy,
		],
		queryFn: () => apiGetTryCatchWrapper('useSearchYttrande', csnArendeApi.csnArendeYttrandenSearchCreate, searchQuery),
		placeholderData: {
			totalPages: 0,
			totalCount: 0,
			pageNumber: 0,
			values: [],
		},
		keepPreviousData: true,
	})

	return { searchResult, ...query }
}

export default useSearchYttrande
