import { apiConfig, getAuthHeaders } from '@local/src/Services/apiEndpointHelper'
import { ArendeApiModel, ArendeTyp } from '@local/src/Types/Arende.types'
import { useQuery } from '@tanstack/react-query'

const useGetArende = (arendeId: string, enabled = false) => {
	const getArendeById = async () => {
		try {
			const response: Response = await fetch(`${apiConfig.baseUrl}/v2.0/arenden/${arendeId}`, {
				headers: getAuthHeaders(),
				method: 'GET',
			})
			if (response.ok) {
				return (await response.json()) as ArendeApiModel
			}
		} catch (error) {
			console.log(error)
		}

		return undefined
	}

	const { data: arende, ...query } = useQuery({
		queryKey: ['getArendeById', arendeId],
		queryFn: getArendeById,
		enabled: Boolean(arendeId) && enabled,
	})

	const ansokanKomplettDatum =
		(arende?.arendeTyp as ArendeTyp) === ArendeTyp.Omstallning
			? arende?.omstallningsArende?.ansokanKomplettDatum
			: arende?.anstallningsArende?.ansokanKomplettDatum
	return { arende, ansokanKomplettDatum, ...query }
}
export default useGetArende
