import { PageWrapper } from '@local/src/Components/PageWrapper'
import { useGetCurrentStudiestodansokan } from '@local/src/Hooks/API'
import AdHocYttrandePage from '@local/src/Pages/AdHocYttrande/AdHocYttrandePage'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router-dom'

interface YttrandeArendeAnsokanParam {
	arendeId?: string
	ansokanId?: string
}

const AdHocYttrandeCreatePage = () => {
	const pathCreate = `*/csn/ad-hoc-yttrande/skapa/arende/:arendeId/ansokan/:ansokanId`
	const matchArendeAnsokan = useRouteMatch<YttrandeArendeAnsokanParam>(pathCreate)
	const { studiestodAnsokan, isLoading, isError } = useGetCurrentStudiestodansokan(
		matchArendeAnsokan?.params?.arendeId,
		matchArendeAnsokan?.params?.ansokanId
	)
	const { t } = useTranslation('translation', { keyPrefix: 'adHocYttrande.errors' })
	return (
		<PageWrapper isLoading={isLoading} errorLoadingData={isError} errorText={t('fetchFailedStudiestod')}>
			<AdHocYttrandePage studiestodAnsokan={studiestodAnsokan} isNewAdHoc={true} />
		</PageWrapper>
	)
}

export default AdHocYttrandeCreatePage
