import { useAdHocYttrandeFormContext } from '@local/src/Pages/AdHocYttrande/Hooks'
import { JaNejRadioButtonGroup } from '@local/src/Pages/Common/Fragments'
import JaNejArbetslosRadioButtonGroup from '@local/src/Pages/Common/Fragments/JaNejArbetslosRadioButtonGroup'
import { Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	index: number
}

const YttrandePerUtbildningForm = ({ index }: Props) => {
	const { watch, isReadOnly } = useAdHocYttrandeFormContext()

	const { slutfordTidigare } = watch(`utbildningar.${index}`)

	const { t } = useTranslation('translation', { keyPrefix: 'adHocYttrande.form.utbildningar.form.yttrande' })

	return (
		<Stack spacing={2}>
			<Typography fontWeight="bold">{t('title')}</Typography>

			<JaNejRadioButtonGroup
				valueType="boolean"
				header={t('labels.specifikArbetsgivare')}
				name={`utbildningar[${index}].specifikArbetsgivare`}
				disabled={isReadOnly}
			/>

			<JaNejArbetslosRadioButtonGroup
				header={t('labels.tillhandahallasAvArbetsgivare')}
				name={`utbildningar[${index}].tillhandahallasAvArbetsgivare`}
				disabled={isReadOnly}
			/>

			<JaNejRadioButtonGroup
				valueType="boolean"
				header={t('labels.slutfordTidigare')}
				name={`utbildningar[${index}].slutfordTidigare`}
				disabled={isReadOnly}
			/>

			{slutfordTidigare && (
				<JaNejRadioButtonGroup
					valueType="boolean"
					header={t('labels.vasentligSkillnad')}
					name={`utbildningar[${index}].vasentligSkillnad`}
					disabled={isReadOnly}
				/>
			)}
		</Stack>
	)
}

export default YttrandePerUtbildningForm
