import { Box, Stack, Typography } from '@mui/material'
import React from 'react'

const LabelValueRow = ({ children }: React.PropsWithChildren) => (
	<Box
		display="flex"
		flexDirection="row"
		sx={(theme) => ({
			[theme.breakpoints.down('md')]: {
				flexDirection: 'column',
			},
		})}
	>
		{children}
	</Box>
)

export const LabelValueSpacer = () => <Box flex={1} mb={2} />

interface LabelValueFieldProps {
	label: string
	value: string | number | JSX.Element
	testSelector?: string
	isFlex?: boolean
}

const LabelValueColumn = ({ label, value, testSelector, isFlex = true }: LabelValueFieldProps) => {
	const getTestSelector = () => {
		if (testSelector) return testSelector
		if (typeof value === 'object') return null
		return `labelValue-${value?.toString()}`
	}
	return (
		<Box flex={1} mb={2} display={isFlex ? 'flex' : 'block'} data-testid={getTestSelector()}>
			<Stack>
				<Typography id={label} variant="subtitle1" gutterBottom={false}>
					{label}
				</Typography>
				<Typography aria-labelledby={label} variant="subtitle1" gutterBottom={false}>
					{value}
				</Typography>
			</Stack>
		</Box>
	)
}

export default {
	Row: LabelValueRow,
	Column: LabelValueColumn,
	Spacer: LabelValueSpacer,
}
