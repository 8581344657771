import { requiredValidationText } from '@local/src/Utils/YupHelper'
import { string as yupString } from 'yup'

const csnPersonNummerFormatRegex = /^(19|20){1}[0-9]{2}((0[1-9])|([1][0-2]))(([0-2][1-9])|([1-2]0)|(3[0-1]))[0-9]{4}$/
const csnSamordningsnummerRegex = /^(19|20){1}[0-9]{2}((0[1-9])|([1][0-2]))((([7-8][0-9])|(6[1-9])|(9[0-1])))[0-9]{4}$/
export const PersonnummerSchema = yupString()
	.required(requiredValidationText)
	.matches(/^[0-9]{12}$/, 'Personnummer måste vara 12 siffror utan bindestreck.')
	.test(
		'csn-regex',
		'Personnummer har inte korrekt format.',
		(value: string) => csnPersonNummerFormatRegex.test(value) || csnSamordningsnummerRegex.test(value)
	)
	.test('luhn', 'Personnummer har inte korrekt kontrollsiffra', (value) => {
		if (!value) return
		if (value.length < 12) return
		const ssn = value.substring(2)
		let sum = 0
		let alternate = false
		for (let i = ssn.length - 1; i >= 0; i--) {
			let n = parseInt(ssn[i])
			if (alternate) {
				n *= 2
				if (n > 9) {
					n = (n % 10) + 1
				}
			}
			sum += n
			alternate = !alternate
		}

		return sum % 10 === 0
	})
