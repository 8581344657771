import { SchemaOf, string as yupString } from 'yup'

const OrsakMotiveringSchema: SchemaOf<string> = yupString()
	.nullable()
	.trim()
	.required('Du behöver skriva in en motivering')
	.max(300, 'Du kan ange max 300 antal tecken')

export const orsakMotiveringRequired = (orsakStarksFramtidaStallning?: number): boolean => orsakStarksFramtidaStallning === 4

export default OrsakMotiveringSchema
