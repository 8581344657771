import {
	CsnUtbildningYttrandeApiModel,
	StarksFramtidaStallning as StarksFramtidaStallningEnum,
} from '@local/Services/SwaggerApi/data-contracts'
import { DatePicker } from '@local/src/Components'
import SystemFeedback from '@local/src/Components/SystemFeedback/SystemFeedback'
import {
	KompletterandeBidrag,
	OrsakStarksFramtidaStallning,
	OrsakTillMotivering,
	StarksFramtidaStallning,
} from '@local/src/Components/Yttranden'
import { useCommonTranslation } from '@local/src/Hooks'
import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { BegaranYttrandeSvarFormData } from '../../Models/BegaranYttrandeSvarForm.types'

import { SvarUtbildning } from './Components/SvarUtbildning'

interface Props {
	utbildningarFromCsn: CsnUtbildningYttrandeApiModel[]
	isReadOnly?: boolean
}

const Yttrande = ({ utbildningarFromCsn, isReadOnly }: Props) => {
	const { watch } = useFormContext<BegaranYttrandeSvarFormData>()

	const { yttrande } = watch()

	const { t } = useTranslation('translation', { keyPrefix: 'begaranYttrande.svar' })
	const { tCommon } = useCommonTranslation()

	const starksFramtidaStallningIsAvstar = yttrande?.starksFramtidaStallning === StarksFramtidaStallningEnum.Avstar

	const showBetalarTrrUtbildningen = starksFramtidaStallningIsAvstar && utbildningarFromCsn.some((u) => u.ansoktKoptUtbildning)
	return (
		<Stack spacing={3}>
			<StarksFramtidaStallning name="yttrande.starksFramtidaStallning" isReadOnly={isReadOnly} />
			{starksFramtidaStallningIsAvstar && (
				<SystemFeedback
					hideCloseButton
					type="warning"
					content={<Typography>{t('info.whenAvstarNoUtbildningQuestions')}</Typography>}
					testSelector={'when-avstar-information-box'}
				/>
			)}

			<OrsakStarksFramtidaStallning
				name="yttrande.orsakStarksFramtidaStallning"
				isReadOnly={isReadOnly}
				testId={`orsakStarksFramtidaStallning-dropdown`}
				starksFramtidaStallning={yttrande?.starksFramtidaStallning}
			/>
			<OrsakTillMotivering
				name="yttrande.orsakMotivering"
				orsakStarksFramtidaStallning={yttrande?.orsakStarksFramtidaStallning}
				isReadOnly={isReadOnly}
				testId={'orsakTillMotivering-textarea'}
			/>

			{!starksFramtidaStallningIsAvstar && <DatePicker disabled={true} name="yttrande.datumAnsokanOo" label={t('info.datumAnsokan')} />}

			<KompletterandeBidrag name="yttrande.kompletterandeBidrag" isReadOnly={isReadOnly || starksFramtidaStallningIsAvstar} />

			{showBetalarTrrUtbildningen && (
				<SystemFeedback
					hideCloseButton
					type="info"
					content={<Typography>{t('info.whenAvstarTrrBetalarInteUtbildningsavgift')}</Typography>}
					testSelector={'trr-does-not-pay-for-utbildning-system-feedback'}
				/>
			)}

			{!starksFramtidaStallningIsAvstar && (
				<Box data-testid={'svar-utbildning-list'}>
					<Typography variant="h4">{tCommon('glossary.utbildning_other')}</Typography>
					{utbildningarFromCsn.map((utbildningBegaran, index) => (
						<SvarUtbildning
							isReadOnly={isReadOnly}
							key={`SvarUtbildning-${utbildningBegaran.idUtbildning}`}
							utbildningBegaran={utbildningBegaran}
							index={index}
						/>
					))}
				</Box>
			)}
		</Stack>
	)
}

export default Yttrande
