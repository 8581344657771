import { yupResolver } from '@hookform/resolvers/yup'
import useScrollToError from '@local/src/Hooks/useScrollToError'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { CreateCsnYttrandeFormModel } from '../Models/AdHocYttrande.types'
import { AdHocYttrandeSchema } from '../Schemas/AdHocYttrande.schema'

const useAdHocYttrandeForm = (defaultFormData: CreateCsnYttrandeFormModel) => {
	const formMethods = useForm<CreateCsnYttrandeFormModel>({
		values: defaultFormData,
		resolver: yupResolver(AdHocYttrandeSchema),
		reValidateMode: 'onChange',
	})

	const {
		watch,
		formState: { errors, isSubmitted, touchedFields },
		setValue,
	} = formMethods

	const { starksFramtidaStallning, orsakStarksFramtidaStallning } = watch()

	useEffect(() => {
		if (!touchedFields?.starksFramtidaStallning) return
		// If starksFramtidaStallning is set to it's default value set the properties bellow to there default values
		if (defaultFormData?.starksFramtidaStallning === starksFramtidaStallning) {
			setValue('orsakStarksFramtidaStallning', defaultFormData?.orsakStarksFramtidaStallning)
			setValue('orsakMotivering', defaultFormData?.orsakMotivering)
			return
		}

		// If starksFramtidaStallning changes we need to reset the values
		setValue('orsakStarksFramtidaStallning', null, { shouldDirty: true })
		setValue('orsakMotivering', null)
	}, [
		starksFramtidaStallning,
		setValue,
		touchedFields?.starksFramtidaStallning,
		defaultFormData?.starksFramtidaStallning,
		defaultFormData?.orsakStarksFramtidaStallning,
		defaultFormData?.orsakMotivering,
	])

	useEffect(() => {
		if (orsakStarksFramtidaStallning !== 4) {
			setValue('orsakMotivering', null)
		}
	}, [setValue, orsakStarksFramtidaStallning])

	useScrollToError(errors, isSubmitted, 200)

	return formMethods
}

export default useAdHocYttrandeForm
