import { useDebouncedEffect } from '@local/src/Hooks'
import { SearchBeslutRequestApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { TextField } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	personnummerQuery: string
	setSearchQuery: React.Dispatch<React.SetStateAction<SearchBeslutRequestApiModel>>
}

const PersonnummerFilter = ({ personnummerQuery, setSearchQuery }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'csnPortal.beslut.searchFilter' })

	const [personnummer, setPersonnummer] = useState(personnummerQuery ?? '')

	useDebouncedEffect(
		() => {
			setSearchQuery((prevState) => ({ ...prevState, personnummer: personnummer.replace('-', '') }))
		},
		300,
		[personnummer]
	)

	const handleOnPersonnummerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPersonnummer(event.target.value)
	}

	return (
		<TextField
			label={t('labels.sokPersonnummer')}
			fullWidth
			inputProps={{
				'data-testid': 'yttrande-sok-personnummer',
			}}
			value={personnummer}
			onChange={handleOnPersonnummerChange}
		/>
	)
}

export default PersonnummerFilter
