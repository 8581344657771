import { CustomAccordionItem } from '@local/src/Components/Accordion'
import LabelValueField from '@local/src/Components/LabelValueField/LabelValueField'
import { useCommonTranslation } from '@local/src/Hooks'
import { CsnUtbildningBeslutApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { booleanTojaNejValue } from '@local/src/Utils/BooleanHelper'
import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	utbildning: CsnUtbildningBeslutApiModel
}

const CsnUtbildningAccordionItem = ({ utbildning }: Props) => {
	const [isUtbildningarToggled, setUtbildningarToggleAccordion] = useState(false)
	const { t } = useTranslation('translation', { keyPrefix: 'csnPortal.beslut.tabs.labels' })
	const { tCommon } = useCommonTranslation()

	return (
		<CustomAccordionItem
			expanded={isUtbildningarToggled}
			heading={utbildning.utbildningsnamn}
			onToggle={() => setUtbildningarToggleAccordion(!isUtbildningarToggled)}
			expansionId={`custom-accordion-item-utbildning-${utbildning.utbildningsnamn}`}
		>
			<LabelValueField.Row>
				<LabelValueField.Column
					testSelector="kopt-utbildning"
					label={t('koptUtbildning')}
					value={booleanTojaNejValue(utbildning.koptUtbildning)}
				/>
				<LabelValueField.Column label={tCommon('glossary.ort')} value={utbildning.ort} />
				<LabelValueField.Column label={t('utbildningsanordnare')} value={utbildning.utbildningsanordnare} />
			</LabelValueField.Row>

			<LabelValueField.Row>
				<LabelValueField.Column label={t('utbildningsnamn')} value={utbildning?.utbildningsnamn} />
				{utbildning.csnUtbildning && (
					<Fragment>
						<LabelValueField.Column label={t('skolkod')} value={utbildning.csnUtbildning?.skolkod} />
						<LabelValueField.Column label={t('utbildningskod')} value={utbildning.csnUtbildning?.utbildningskod} />
					</Fragment>
				)}
			</LabelValueField.Row>
		</CustomAccordionItem>
	)
}

export default CsnUtbildningAccordionItem
