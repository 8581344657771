import React from 'react'
import { Box, FormControl, SxProps, TextField, Theme } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { NumericFormat } from 'react-number-format'

interface Props {
	name: string
	label: string
	disabled?: boolean
	testId?: string
	wrapperSx?: SxProps<Theme>
	fullWidth?: boolean
}
const NumberInputField = ({ name, label, disabled, testId, wrapperSx = {}, fullWidth = true }: Props) => {
	const { getFieldState, control } = useFormContext()
	const { error } = getFieldState(name)

	return (
		<Box sx={wrapperSx}>
			<FormControl fullWidth={fullWidth}>
				<Controller
					name={name}
					control={control}
					rules={{ required: true }}
					render={({ field }) => (
						<NumericFormat
							data-testid={testId || name}
							inputRef={field.ref}
							disabled={disabled}
							value={field.value}
							onValueChange={(values) => field.onChange(values.floatValue)}
							customInput={TextField}
							label={label}
							error={Boolean(error?.message)}
							helperText={error?.message}
							thousandSeparator=" "
							allowNegative={false}
							allowedDecimalSeparators={[',']}
							InputProps={{
								readOnly: disabled,
							}}
							inputProps={{
								inputMode: 'numeric',
								autocomplete: 'off',
							}}
						/>
					)}
				/>
			</FormControl>
		</Box>
	)
}

export default NumberInputField
