import { CsnUtbildningYttrandeApiModel } from '@local/Services/SwaggerApi/data-contracts'
import { CustomAccordionItem } from '@local/src/Components/Accordion'
import LabelValueField from '@local/src/Components/LabelValueField/LabelValueField'
import { UtbildningKurser } from '@local/src/Components/Yttranden'
import { useCommonTranslation } from '@local/src/Hooks'
import { booleanTojaNejValue } from '@local/src/Utils/BooleanHelper'
import { Box } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	utbildning: CsnUtbildningYttrandeApiModel
}

const Utbildning = ({ utbildning }: Props) => {
	const [isToggled, setIsToggled] = useState<boolean>(false)
	const { t } = useTranslation('translation', { keyPrefix: 'begaranYttrande.info.utbildningar.labels' })
	const { tCommon } = useCommonTranslation()

	const handleToggleClick = () => setIsToggled(!isToggled)

	return (
		<CustomAccordionItem
			expanded={isToggled}
			heading={utbildning.utbildningsanordnare}
			subHeading={utbildning.utbildningsnamn}
			onToggle={handleToggleClick}
			expansionId={`custom-accordion-item-utb-${utbildning.idUtbildning}`}
		>
			<Box mb={2}>
				<LabelValueField.Row>
					<LabelValueField.Column label={t('utbildningsort')} value={utbildning.ort} />
					<LabelValueField.Column label={t('skolkod')} value={utbildning.skolkod} />
					<LabelValueField.Column label={tCommon('glossary.utbildningsanordnare')} value={utbildning.utbildningsanordnare} />
				</LabelValueField.Row>

				<LabelValueField.Row>
					<LabelValueField.Column label={tCommon('glossary.utbildningskod')} value={utbildning.utbildningskod} />
					<LabelValueField.Column label={tCommon('glossary.utbildningsnamn')} value={utbildning.utbildningsnamn} />
					<LabelValueField.Column label={t('franCsnUr')} value={booleanTojaNejValue(utbildning.franCsnUr)} />
				</LabelValueField.Row>

				{utbildning.ansoktStarthalvar && (
					<LabelValueField.Row>
						<LabelValueField.Column label={t('ansoktKoptUtbildning')} value={booleanTojaNejValue(utbildning.ansoktKoptUtbildning)} />
						<LabelValueField.Column label={t('ansoktStarthalvar')} value={utbildning.ansoktStarthalvar} />
						<LabelValueField.Spacer />
					</LabelValueField.Row>
				)}

				<LabelValueField.Row>
					{Boolean(utbildning.ansoktAntalKoptaVeckor) && (
						<LabelValueField.Column label={t('ansoktAntalKoptaVeckor')} value={utbildning.ansoktAntalKoptaVeckor} />
					)}

					{Boolean(utbildning.ansoktOmfattningKoptaVeckor) && (
						<LabelValueField.Column label={t('ansoktOmfattningKoptaVeckor')} value={utbildning.ansoktOmfattningKoptaVeckor} />
					)}
					{Boolean(utbildning.ansoktOmfattningsenhetKoptaVeckor) && Boolean(utbildning.ansoktOmfattningKoptaVeckor) && (
						<LabelValueField.Column
							label={t('ansoktOmfattningsenhetKoptaVeckor')}
							value={t('omfattningEnhet', { context: utbildning.ansoktOmfattningsenhetKoptaVeckor })}
						/>
					)}
				</LabelValueField.Row>
				<UtbildningKurser kurser={utbildning?.kurserUtbildning} idUtbildning={utbildning.idUtbildning} />
			</Box>
		</CustomAccordionItem>
	)
}

export default Utbildning
