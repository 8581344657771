import {
	CsnBeslutsperiodBeslutApiModel,
	CsnUtbildningBeslutApiModel,
	CsnYttrandeBeslutApiModel,
} from '@local/src/Services/SwaggerApi/data-contracts'
import { formatDate } from '@local/src/Utils/dateFormatHelper'
import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { LabelValue, TechnicalDetailPopover, SystemFeedback } from '@local/src/Components'

import CommonKlientInformation from '../Common/CommonKlientInformation'

import BeslutsperioderAccordionItem from './Components/BeslutsperioderAccordionItem/BeslutsperioderAccordionItem'
import CsnUtbildningAccordionItem from './Components/CsnUtbildningAccordionItem/CsnUtbildningAccordionItem'

interface Props {
	beslut: CsnYttrandeBeslutApiModel
}

const BeslutTab = ({ beslut }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'csnPortal.beslut.tabs' })

	const shouldShowSystemFeedback = beslut.registeredPeriodInFenixIsDeleted

	return (
		<Stack spacing={3}>
			<TechnicalDetailPopover data={beslut} />

			{shouldShowSystemFeedback && (
				<SystemFeedback type="warning" content={t('labels.beslutHasStudieperioderInFenix')} testSelector={'root-info-banner'} />
			)}

			<CommonKlientInformation beslut={beslut} />

			<Stack direction={{ xs: 'column', sm: 'row' }}>
				<Box flex={1}>
					<LabelValue label={t('labels.beslutstyp')} value={t('beslut.propertyValueMap.beslutstyp', { context: beslut.beslutstyp })} />
				</Box>

				<Box flex={1}>
					<LabelValue label={t('labels.arligInkomst')} value={beslut.arligInkomst} />
				</Box>

				<Box flex={1}>
					<LabelValue label={t('labels.tidpunktBeslutEllerUtbetalning')} value={formatDate(beslut.tidpunktBeslutEllerUtbetalning)} />
				</Box>
			</Stack>

			<Stack direction={{ xs: 'column', sm: 'row' }}>
				<Box flex={1}>
					<LabelValue
						label={t('labels.beslutsutfall')}
						value={t('beslut.propertyValueMap.beslutsutfall', { context: beslut.beslutsutfall })}
					/>
				</Box>
				<Box flex={1}>
					<LabelValue label={t('labels.arligInkomstMotsvarandeHeltidsarbete')} value={beslut.arligInkomstMotsvarandeHeltidsarbete} />
				</Box>
				<Box flex={1} />
			</Stack>

			<Typography variant="h5">
				{t('beslut.labels.utbildningsHeader')} ({beslut.utbildningar.length} st)
			</Typography>

			{beslut.utbildningar.map((utbildning: CsnUtbildningBeslutApiModel) => (
				<CsnUtbildningAccordionItem
					utbildning={utbildning}
					key={`${beslut.id}-${utbildning.utbildningsnamn}-${utbildning.utbildningsanordnare}`}
				/>
			))}

			<Typography variant="h5">
				{t('beslut.labels.perioderHeader')} ({beslut.beslutsperioder.length} st)
			</Typography>
			{beslut.beslutsperioder.map((beslutsperioder: CsnBeslutsperiodBeslutApiModel) => (
				<BeslutsperioderAccordionItem
					beslutsperioder={beslutsperioder}
					key={`${beslutsperioder.periodId}-${beslut.grundansokanId}`}
					grundansokanId={beslut.grundansokanId}
				/>
			))}
		</Stack>
	)
}

export default BeslutTab
