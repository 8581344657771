/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
	CreateKommentarApiModel,
	KommentarApiModel,
	TrrProblemDetails,
	TrrValidationProblemDetails,
	UpdateKommentarApiModel,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Kommentar<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
	/**
	 * No description
	 *
	 * @tags Kommentar
	 * @name ReferenceKommentarCreate
	 * @request POST:/studiestod/reference/{referenceId}/kommentar
	 * @secure
	 * @response `204` `void` No Content
	 * @response `400` `TrrValidationProblemDetails` Bad Request
	 * @response `500` `TrrProblemDetails` Internal Server Error
	 */
	referenceKommentarCreate = (referenceId: string, data: CreateKommentarApiModel, params: RequestParams = {}) =>
		this.request<void, TrrValidationProblemDetails | TrrProblemDetails>({
			path: `/studiestod/reference/${referenceId}/kommentar`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			...params,
		})
	/**
	 * No description
	 *
	 * @tags Kommentar
	 * @name ReferenceKommentarDetail
	 * @request GET:/studiestod/reference/{referenceId}/kommentar
	 * @secure
	 * @response `200` `(KommentarApiModel)[]` OK
	 * @response `400` `TrrValidationProblemDetails` Bad Request
	 * @response `500` `TrrProblemDetails` Internal Server Error
	 */
	referenceKommentarDetail = (referenceId: string, params: RequestParams = {}) =>
		this.request<KommentarApiModel[], TrrValidationProblemDetails | TrrProblemDetails>({
			path: `/studiestod/reference/${referenceId}/kommentar`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		})
	/**
	 * No description
	 *
	 * @tags Kommentar
	 * @name ReferenceKommentarUpdate
	 * @request PUT:/studiestod/reference/{referenceId}/kommentar/{kommentarId}
	 * @secure
	 * @response `204` `void` No Content
	 * @response `400` `TrrValidationProblemDetails` Bad Request
	 * @response `500` `TrrProblemDetails` Internal Server Error
	 */
	referenceKommentarUpdate = (kommentarId: string, referenceId: string, data: UpdateKommentarApiModel, params: RequestParams = {}) =>
		this.request<void, TrrValidationProblemDetails | TrrProblemDetails>({
			path: `/studiestod/reference/${referenceId}/kommentar/${kommentarId}`,
			method: 'PUT',
			body: data,
			secure: true,
			type: ContentType.Json,
			...params,
		})
	/**
	 * No description
	 *
	 * @tags Kommentar
	 * @name ReferenceKommentarDelete
	 * @request DELETE:/studiestod/reference/{referenceId}/kommentar/{kommentarId}
	 * @secure
	 * @response `204` `void` No Content
	 * @response `400` `TrrValidationProblemDetails` Bad Request
	 * @response `500` `TrrProblemDetails` Internal Server Error
	 */
	referenceKommentarDelete = (kommentarId: string, referenceId: string, params: RequestParams = {}) =>
		this.request<void, TrrValidationProblemDetails | TrrProblemDetails>({
			path: `/studiestod/reference/${referenceId}/kommentar/${kommentarId}`,
			method: 'DELETE',
			secure: true,
			...params,
		})
}
