import Icons from '@local/src/Components/Icons'
import { Box } from '@mui/material'
import React from 'react'
import { ToastContainer, toast } from 'react-toastify'

export const ToasterContainer = () => <ToastContainer hideProgressBar newestOnTop autoClose={1000} closeOnClick={false} />

interface ToastContentProps {
	title?: string
	body?: string
	errorMsg?: string
	testSelector?: string
	isSuccess?: boolean
}
const ToasterContent = ({ title, body, errorMsg, testSelector, isSuccess }: ToastContentProps) => (
	<Box
		display="flex"
		alignItems="center"
		sx={(theme) => ({
			color: theme.palette.text.primary,
			backgroundColor: isSuccess ? theme.palette.success.light : theme.palette.error.light,
		})}
		data-testid={testSelector}
	>
		{isSuccess ? <Icons.Success /> : <Icons.Error />}
		<Box sx={{ flex: 1 }} ml={2}>
			{title && <h2>{title}</h2>}
			{body && <p>{body}</p>}
			{errorMsg && <p>{errorMsg}</p>}
		</Box>
	</Box>
)

interface PushFeedbackProps extends ToastContentProps {
	customAnimationTimerOffset?: number
	onClose?: () => void
}
export const pushFeedback = ({
	title,
	body,
	errorMsg,
	customAnimationTimerOffset = 0,
	onClose,
	testSelector,
	isSuccess,
}: PushFeedbackProps) => {
	// 60ms per character
	// at least give timeout 4 seconds if text is too short
	let calculatedLength = (title ? title.length : 0) + (body ? body.length : 0) * 60 + customAnimationTimerOffset
	calculatedLength = calculatedLength < 5000 ? 5000 : calculatedLength

	const id = Math.floor(Math.random() * 10000)

	toast(<ToasterContent body={body} title={title} errorMsg={errorMsg} testSelector={testSelector} isSuccess={isSuccess} />, {
		toastId: id,
		autoClose: calculatedLength,
		onClose,
		style: {
			backgroundColor: isSuccess ? '#BDF4F0' : '#FBD0D9',
		},
	})
}
