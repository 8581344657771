import { csnBeslutApi } from '@local/src/Services/apiEndpointHelper'
import { apiGetTryCatchWrapper } from '@local/src/Services/apiRequestHelper'
import { useQuery } from '@tanstack/react-query'

export const useCsnArendeBeslutHistorikDetail = (grundansokanId: string, enabled: boolean) => {
	const query = useQuery({
		queryKey: ['getCsnArendeBeslutHistorikDetail', grundansokanId],
		queryFn: () => apiGetTryCatchWrapper('useCsnArendeBeslutHistorikDetail', csnBeslutApi.csnArendeBeslutHistorikDetail, grundansokanId),
		placeholderData: [],
		enabled: enabled,
	})
	return query
}
