import { AdHocYttrandeKoptUtbildningApiModel } from '@local/Services/SwaggerApi/data-contracts'
import { maxLengthValidationText, minLengthValidationText, requiredValidationText } from '@local/Utils/YupHelper'
import { number as yupNumber, object as yupObject, SchemaOf, string as yupString } from 'yup'

export const KoptUtbildningYttrandeApiModelSchema: SchemaOf<AdHocYttrandeKoptUtbildningApiModel> = yupObject({
	antalKoptaVeckor: yupNumber().required(requiredValidationText).min(1, minLengthValidationText).max(999, maxLengthValidationText),
	omfattningKoptaVeckor: yupNumber()
		.required(requiredValidationText)
		.min(1, 'Omfattningen kan vara minst 1%')
		.max(100, 'Omfattningen kan max vara 100%'),
	starthalvar: yupString().length(6).required(requiredValidationText),
}).nullable()

export default KoptUtbildningYttrandeApiModelSchema
