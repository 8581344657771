import TabPanel from '@local/src/Components/Tab/TabPanel'
import { CsnYttrandeBeslutApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { Card, CardContent, CardHeader, Tab, Tabs } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { BeslutTab, HistorikTab } from './Tabs'

interface Props {
	beslut: CsnYttrandeBeslutApiModel
}

const BeslutListItem = ({ beslut }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'csnPortal.beslut.tabs' })
	const [selectedTab, setSelectedTab] = React.useState(0)

	const handleChange = (_: React.SyntheticEvent, tab: number) => {
		setSelectedTab(tab)
	}

	const beslutTitle = `Beslut för: ${beslut?.userProfileCsnBeslut?.firstName} ${beslut?.userProfileCsnBeslut?.lastName}`

	return (
		<Card>
			<CardHeader titleTypographyProps={{ variant: 'h6' }} title={beslutTitle} />
			<Tabs
				value={selectedTab}
				onChange={handleChange}
				aria-label="beslut historik tabs"
				sx={{ borderBottom: 1, borderColor: 'divider', m: 1 }}
			>
				<Tab value={0} label={t('beslutTabHeader')} data-testid="beslut-tab-button" />
				<Tab value={1} label={t('historikTabHeader')} data-testid="historik-tab-button" />
			</Tabs>
			<CardContent>
				<TabPanel value={selectedTab} index={0}>
					<BeslutTab beslut={beslut} />
				</TabPanel>
				<TabPanel value={selectedTab} index={1}>
					<HistorikTab beslut={beslut} isSelected={selectedTab === 1} />
				</TabPanel>
			</CardContent>
		</Card>
	)
}

export default BeslutListItem
