import { KompletterandeBidragWithUserProfileMetaApiModel } from '@local/Services/SwaggerApi/data-contracts'
import LabelValueField from '@local/src/Components/LabelValueField/LabelValueField'
import { TechnicalDetailPopover } from '@local/src/Components/TechnicalDetailPopover'
import { useCommonTranslation } from '@local/src/Hooks'
import { formatDateTime } from '@local/src/Utils/dateFormatHelper'
import { Box, Stack, Typography } from '@mui/material'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { KompletterandeBidragStatusChip } from './Components/KompletterandeBidragStatusChip'

interface Props {
	kompletterandeBidrag: KompletterandeBidragWithUserProfileMetaApiModel
}

const KompletterandeBidragItem = ({ kompletterandeBidrag }: Props) => {
	const { idBegaran, grundansokanId, tidpunktBegaran, beslutsperioder } = kompletterandeBidrag.begaranKompletterandeBidrag
	const { t } = useTranslation('translation', { keyPrefix: 'csnPortal.kompletterandeBidrag' })
	const { tCommon } = useCommonTranslation()

	const sentToCsnDateText =
		kompletterandeBidrag.sentToCsnAt === null ? tCommon('status.pending') : formatDateTime(kompletterandeBidrag.sentToCsnAt)
	return (
		<Fragment>
			<Stack flexDirection="row" mb={2}>
				<Box flex={1}>
					<Typography variant="h4" mt={0}>
						{t('item.bidragHeader')}
					</Typography>
				</Box>
				<KompletterandeBidragStatusChip kompletterandeBidrag={kompletterandeBidrag} />
			</Stack>
			<TechnicalDetailPopover data={kompletterandeBidrag} />
			<LabelValueField.Row>
				<LabelValueField.Column label={t('item.idBegaran')} value={idBegaran} />
				<LabelValueField.Column label={t('item.grundAnsokanId')} value={grundansokanId} />
				<LabelValueField.Column label={t('item.tidpunkt')} value={formatDateTime(tidpunktBegaran)} />
			</LabelValueField.Row>
			<Box mb={4}>
				<LabelValueField.Row>
					<LabelValueField.Column
						label={tCommon('glossary.namn')}
						value={
							kompletterandeBidrag.userProfileKompletterandeBidrag
								? `${kompletterandeBidrag.userProfileKompletterandeBidrag?.firstName} ${kompletterandeBidrag?.userProfileKompletterandeBidrag?.lastName}`
								: t('errors.notFound')
						}
					/>
					<LabelValueField.Column
						label={tCommon('glossary.personnummer')}
						value={kompletterandeBidrag?.userProfileKompletterandeBidrag?.personnummer ?? t('errors.notFound')}
					/>
					<LabelValueField.Column
						label={t('item.skickatTillCsn')}
						value={kompletterandeBidrag.shouldSendToCsn ? sentToCsnDateText : 'Ej skickad'}
					/>
				</LabelValueField.Row>
			</Box>

			{beslutsperioder.map((period, index) => (
				<Box key={index} data-testid={`${period.fromVecka}-${period.tomVecka}`} mt={3}>
					<Typography variant="h6" mb={1}>
						{tCommon('glossary.period')} {index + 1}
					</Typography>
					<Typography>{`${t('item.fran')}: ${period.fromVecka} - ${t('item.till')}: ${period.tomVecka}`}</Typography>
				</Box>
			))}
		</Fragment>
	)
}

export default KompletterandeBidragItem
