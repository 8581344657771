import { FilterChip } from '@local/src/Components'
import useSearchCsnBeslut from '@local/src/Pages/CsnPortal/CsnBeslut/Hooks/useSearchCsnBeslut'
import { CsnBeslutsutfall, SearchBeslutRequestApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	searchQuery: SearchBeslutRequestApiModel
	setSearchQuery: React.Dispatch<React.SetStateAction<SearchBeslutRequestApiModel>>
}

const BeslutsutfallFilter = ({ searchQuery, setSearchQuery }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'csnPortal.beslut.searchFilter' })
	const { beslutSearchResponse } = useSearchCsnBeslut({ ...searchQuery, csnBeslutsutfall: undefined, pageSize: 1, page: 1 })
	const facets = beslutSearchResponse?.facets?.find((f) => f.name === 'Beslutsutfall')?.facet || []
	const bifallCount = facets.find((y) => y.name === 'Bifall')?.count ?? 0
	const avslagCount = facets.find((y) => y.name === 'Avslag')?.count ?? 0
	const avvisadCount = facets.find((y) => y.name === 'Avvisad')?.count ?? 0
	const avskrivenCount = facets.find((y) => y.name === 'Avskriven')?.count ?? 0
	const avslutadCount = facets.find((y) => y.name === 'Avslutad')?.count ?? 0

	const handleToggleBeslutsUtfall = (selectedBeslutsutfall: CsnBeslutsutfall) => {
		setSearchQuery((prevState) => {
			const toggledValue = prevState.csnBeslutsutfall === selectedBeslutsutfall ? undefined : selectedBeslutsutfall
			return { ...prevState, csnBeslutsutfall: toggledValue }
		})
	}

	return (
		<Stack spacing={1}>
			<Typography>{t('labels.beslutsutfall')}</Typography>
			<Stack direction="row" flexWrap="wrap" gap={1}>
				<FilterChip
					dataTestId={`csn-beslut-search-filter-chip-beslutsutfall-Bifall`}
					label={t('filter_bifall', { count: bifallCount })}
					filter={CsnBeslutsutfall.Bifall}
					onFilterClicked={handleToggleBeslutsUtfall}
					selectedFilter={searchQuery.csnBeslutsutfall}
				/>
				<FilterChip
					dataTestId={`csn-beslut-search-filter-chip-beslutsutfall-Avslag`}
					label={t('filter_avslag', { count: avslagCount })}
					filter={CsnBeslutsutfall.Avslag}
					onFilterClicked={handleToggleBeslutsUtfall}
					selectedFilter={searchQuery.csnBeslutsutfall}
				/>
				<FilterChip
					dataTestId={`csn-beslut-search-filter-chip-beslutsutfall-Avvisad`}
					label={t('filter_avvisad', { count: avvisadCount })}
					filter={CsnBeslutsutfall.Avvisad}
					onFilterClicked={handleToggleBeslutsUtfall}
					selectedFilter={searchQuery.csnBeslutsutfall}
				/>
				<FilterChip
					dataTestId={`csn-beslut-search-filter-chip-beslutsutfall-Avskriven`}
					label={t('filter_avskriven', { count: avskrivenCount })}
					filter={CsnBeslutsutfall.Avskriven}
					onFilterClicked={handleToggleBeslutsUtfall}
					selectedFilter={searchQuery.csnBeslutsutfall}
				/>
				<FilterChip
					dataTestId={`csn-beslut-search-filter-chip-beslutsutfall-Avslutad`}
					label={t('filter_avslutad', { count: avslutadCount })}
					filter={CsnBeslutsutfall.Avslutad}
					onFilterClicked={handleToggleBeslutsUtfall}
					selectedFilter={searchQuery.csnBeslutsutfall}
				/>
			</Stack>
		</Stack>
	)
}

export default BeslutsutfallFilter
