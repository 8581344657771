import { ApiResponseModel } from '@local/src/Services/ApiResponseModel'
import { csnAdHocYttrandeApi } from '@local/src/Services/apiEndpointHelper'
import { apiRequestTryCatchWrapper } from '@local/src/Services/apiRequestHelper'
import { getApiResponseError } from '@local/src/Services/apiResponseHelper'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'

interface DeleteAdHocYttrande {
	id: string
}

const useDeleteAdHocYttrande = () => {
	const [apiResponse, setApiResponse] = useState<ApiResponseModel<void>>(undefined)

	const deleteAdhocYttrandenMutation = useMutation({
		mutationFn: (param: DeleteAdHocYttrande) => {
			setApiResponse(undefined)

			return apiRequestTryCatchWrapper('useDeleteAdHocYttrande', csnAdHocYttrandeApi.csnAdHocYttrandeDelete, param.id)
		},
		onSuccess: (response) => {
			setApiResponse(response)
		},
		onError: (response: { message?: string }) => {
			setApiResponse(getApiResponseError(response?.message))
		},
	})

	return { deleteAdhocYttrandenMutation, apiResponse }
}

export default useDeleteAdHocYttrande
