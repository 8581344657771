import { csnBegaranYttrandeApi } from '@local/src/Services/apiEndpointHelper'
import { apiGetTryCatchWrapper } from '@local/src/Services/apiRequestHelper'
import { useQuery } from '@tanstack/react-query'
import { isNotNil } from 'ramda'

const useGetBegaranYttrande = (begaranId: string) => {
	const { data: csnBegaranYttrande, ...query } = useQuery({
		queryKey: ['csnArendeBegaranYttrandeDetail', begaranId],
		queryFn: () => apiGetTryCatchWrapper('useGetBegaranYttrande', csnBegaranYttrandeApi.csnArendeBegaranYttrandeDetail, begaranId),
		enabled: isNotNil(begaranId),
	})

	return { csnBegaranYttrande, ...query }
}

export default useGetBegaranYttrande
