import {
	BeslutsPeriodStudieforsakran,
	CsnBeslutsutfall,
	SearchYttrandenStatus,
	SearchYttrandenType,
	LastDateToSvaraSearchRange,
} from '@local/src/Services/SwaggerApi/data-contracts'
import React from 'react'
import { Chip } from '@mui/material'

type FilterType = SearchYttrandenStatus | SearchYttrandenType | CsnBeslutsutfall | BeslutsPeriodStudieforsakran | LastDateToSvaraSearchRange

interface Props {
	dataTestId?: string
	label: string
	filter: FilterType
	selectedFilter: FilterType
	onFilterClicked: (status: FilterType) => void
}

const FilterChip = ({ label, filter, selectedFilter, onFilterClicked, dataTestId }: Props) => {
	const handleOnClick = () => onFilterClicked(filter)
	const isSelected = selectedFilter === filter
	return (
		<Chip
			data-testid={dataTestId}
			label={label}
			color="primary"
			variant={isSelected ? 'filled' : 'outlined'}
			onClick={handleOnClick}
			data-selected={isSelected}
		/>
	)
}

export default FilterChip
