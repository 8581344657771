import { useDebouncedEffect } from '@local/src/Hooks'
import { SearchYttrandenRequestApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { TextField } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	searchFilter: SearchYttrandenRequestApiModel
	setSearchFilterWithPage: (searchFilter: SearchYttrandenRequestApiModel) => void
}

const YttrandeSokPersonnummer = ({ searchFilter, setSearchFilterWithPage }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'csnPortal.yttranden.yttrandeFilterCard' })
	const [ssnFilter, setSsnFilter] = useState(searchFilter.personnummer || '')

	useDebouncedEffect(
		() => {
			setSearchFilterWithPage({ ...searchFilter, personnummer: ssnFilter.replace('-', '') })
		},
		300,
		[ssnFilter]
	)

	const handleSsnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSsnFilter(event.target.value)
	}

	return (
		<TextField
			label={t('ssnFilterInputLabel')}
			fullWidth
			slotProps={{ htmlInput: { 'data-testid': 'yttrande-sok-personnummer' } }}
			value={ssnFilter}
			onChange={handleSsnChange}
		/>
	)
}

export default YttrandeSokPersonnummer
