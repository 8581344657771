import { SearchBeslutRequestApiModel, SearchBeslutResponseApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { Stack, TablePagination, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	beslutSearchResponse: SearchBeslutResponseApiModel
	rowsPerPageQuery: number
	setSearchQuery: React.Dispatch<React.SetStateAction<SearchBeslutRequestApiModel>>
}

const CsnBeslutPagination = ({ beslutSearchResponse, rowsPerPageQuery, setSearchQuery }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'csnPortal.beslut.pagination' })

	const rowsPerPageOptions = [5, 10, 20, 50, 100]

	const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		setSearchQuery((prevState) => ({ ...prevState, page: newPage + 1 }))
	}

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setSearchQuery((prevState) => ({ ...prevState, pageSize: parseInt(event.target.value, 10), page: 1 }))
	}

	const { totalCount, pageNumber, values } = beslutSearchResponse

	const amountOfBeslutText = `${t('amountOfBeslut', { count: values.length })} av ${totalCount}`

	return (
		<Stack direction="row" justifyContent="space-between" alignItems="center">
			<Typography data-testid={'csn-beslut-pagination-amount'} variant="body2">
				{amountOfBeslutText}
			</Typography>

			<TablePagination
				data-testid={'csn-beslut-pagination'}
				component="div"
				count={totalCount}
				onPageChange={handleChangePage}
				page={pageNumber - 1}
				rowsPerPage={rowsPerPageQuery}
				rowsPerPageOptions={rowsPerPageOptions}
				onRowsPerPageChange={handleChangeRowsPerPage}
				labelRowsPerPage={t('labelRowsPerPage')}
				SelectProps={{ inputProps: { id: 'csn-beslut-pagination-rows-per-page' } }}
			/>
		</Stack>
	)
}

export default CsnBeslutPagination
