import { useCommonTranslation } from '@local/src/Hooks'
import {
	getUtbildningsanordnareOrSkolnamn,
	getUtbildningsnamn,
} from '@local/src/Pages/BegaranYttrande/Edit/Components/BesvaradWithAdHoc/Helpers/UtbildningsHelper'
import { AdHocYttrandeStatus, CsnAdHocYttrandeApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { formatDateNull } from '@local/src/Utils/dateFormatHelper'
import { Box, Card, CardActionArea, CardContent, CardHeader, Chip, Divider, List, ListItem, ListItemText, Stack } from '@mui/material'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	adHocYttrande: CsnAdHocYttrandeApiModel
}

const AdHocYttrandeCard = ({ adHocYttrande }: Props) => {
	const goToAdHocYttrande = () => (window.location.href = `/medarbetare/csn/ad-hoc-yttrande/redigera/${adHocYttrande.yttrande.id}`)

	const { t } = useTranslation('translation', { keyPrefix: 'begaranYttrande.besvaradWithAdHoc' })
	const { tCommon } = useCommonTranslation()

	const isSkickad = adHocYttrande?.status === AdHocYttrandeStatus.SkickadTillCsn
	const chipVariant = isSkickad ? 'status-success' : 'status-info'
	const chipText = isSkickad ? tCommon('status.csnFormState_sent') : tCommon('status.csnFormState_edit')
	const chipTestId = `ad-hoc-yttrande-status-chip-${isSkickad ? 'sent' : 'edit'}`

	return (
		<Card data-testid="besvarad-with-ad-hoc-card">
			<CardActionArea onClick={goToAdHocYttrande}>
				<CardHeader
					title={t('adHocYttrandeCard.title')}
					action={<Chip variant={chipVariant} label={chipText} data-testid={chipTestId} />}
					subheader={
						<Stack direction="row" spacing={1}>
							<Box>{`${t('adHocYttrandeCard.created')} ${formatDateNull(adHocYttrande.createdBy.lastUpdatedAt)}`}</Box>
							<Divider orientation="vertical" variant="middle" flexItem />
							<Box>{`${t('adHocYttrandeCard.lastUpdated')} ${formatDateNull(adHocYttrande.lastUpdatedBy.lastUpdatedAt)}`}</Box>
							{adHocYttrande?.sentToCsnBy && (
								<Fragment>
									<Divider orientation="vertical" variant="middle" flexItem />
									<Box>{`${t('adHocYttrandeCard.sent')} ${formatDateNull(adHocYttrande.sentToCsnBy.lastUpdatedAt)}`}</Box>
								</Fragment>
							)}
						</Stack>
					}
				/>
				<CardContent>
					<List>
						{adHocYttrande.yttrande.utbildningar?.map((utbildning, index, utbildningar) => (
							<ListItem
								key={`AdHocYttrandeCard-Utbildning-${String(utbildning)}`}
								disablePadding
								divider={index !== utbildningar.length - 1}
							>
								<ListItemText
									title="Skola/Anordnare"
									primary={getUtbildningsnamn(utbildning, t('utbildningsHelper.noUtbildningContentText'))}
									secondary={getUtbildningsanordnareOrSkolnamn(utbildning, t('utbildningsHelper.noSkolnamnContentText'))}
								/>
							</ListItem>
						))}
					</List>
				</CardContent>
			</CardActionArea>
		</Card>
	)
}

export default AdHocYttrandeCard
