import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { IconButton, Typography } from '@mui/material'
import React from 'react'
import { useHistory } from 'react-router-dom'

const GoBackButton = () => {
	const history = useHistory()
	const handleGoBack = () => history.go(-1)
	return (
		<IconButton sx={{ p: 0 }} disableRipple data-testid={'go-back-button'} onClick={handleGoBack}>
			<ArrowBackIosIcon fontSize="small" />
			<Typography> Tillbaka till föregående sida</Typography>
		</IconButton>
	)
}

export default GoBackButton
