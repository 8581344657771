import { AdHocYttrandeCsnUtbildningApiModel, OspecificeradKursApiModel } from '@local/Services/SwaggerApi/data-contracts'
import {
	ShouldBeUndefinedNullOrEmptyArraySchema,
	maxLengthValidationText,
	minLengthValidationText,
	requiredValidationText,
} from '@local/Utils/YupHelper'
import { SchemaOf, array as yupArray, boolean as yupBoolean, object as yupObject, string as yupString } from 'yup'

const KurserUtbildningSchema: SchemaOf<OspecificeradKursApiModel> = yupObject({
	kurs: yupString().required(requiredValidationText).trim().min(1, minLengthValidationText).max(200, maxLengthValidationText),
})

export const CsnUtbildningSchema: SchemaOf<AdHocYttrandeCsnUtbildningApiModel> = yupObject()
	.shape({
		utbildningskod: yupString()
			.required(requiredValidationText)
			.min(1, minLengthValidationText)
			.max(8, maxLengthValidationText)
			.matches(/^[0-9]+$/, 'Måste bara bestå av siffror'),
		uppdragsutbildning: yupBoolean().nullable().required(requiredValidationText),
		ospecificeradUtbildning: yupBoolean(),
		kurserUtbildning: yupArray().when('ospecificeradUtbildning', {
			is: (ospecificeradUtbildning: boolean) => ospecificeradUtbildning,
			then: yupArray().required().min(1).of(KurserUtbildningSchema),
			otherwise: ShouldBeUndefinedNullOrEmptyArraySchema,
		}),
	})
	.nullable()

export default CsnUtbildningSchema
