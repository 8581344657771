import YttrandePerUtbildningForm from '@local/src/Pages/AdHocYttrande/Components/AdHocYttrandeForm/Components/Utbildningar/Components/YttrandePerUtbildningForm/YttrandePerUtbildningForm'
import { useAdHocYttrandeFormContext } from '@local/src/Pages/AdHocYttrande/Hooks'
import { CreateCsnYttrandeFormModel, UtbildningarFormModel } from '@local/src/Pages/AdHocYttrande/Models/AdHocYttrande.types'
import Delete from '@mui/icons-material/Delete'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Divider, IconButton, Stack, Typography } from '@mui/material'
import { isNotNil } from 'ramda'
import React, { useEffect, useState } from 'react'
import { FieldPath, UseFieldArrayRemove } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { CsnUtbildningCheckbox } from '../CsnUtbildningCheckbox'
import { CsnUtbildningForm } from '../CsnUtbildningForm'
import { EjCsnUtbildningForm } from '../EjCsnUtbildningForm'
import { KoptUtbildningForm } from '../KoptUtbildningForm'

interface Props {
	index: number
	remove: UseFieldArrayRemove
}

const UtbildningBaseForm = ({ index, remove }: Props) => {
	const {
		setValue,
		watch,
		formState: { errors },
		isReadOnly,
	} = useAdHocYttrandeFormContext()

	const currentErrors = errors.utbildningar?.[index]
	const utbildningHasErroredFields = isNotNil(currentErrors)

	const utbildningar = watch(`utbildningar`)
	const isLastIndexInUtbildningArray = index + 1 === utbildningar.length

	const { slutfordTidigare, isCsnUtbildning, csnUtbildning, ejCsnUtbildning } = watch(`utbildningar.${index}`)

	const showKoptUtbildningForm = !isCsnUtbildning || csnUtbildning?.uppdragsutbildning

	useEffect(() => {
		const getFieldValuePath = (field: FieldPath<UtbildningarFormModel>): FieldPath<CreateCsnYttrandeFormModel> =>
			`utbildningar.${index}.${field}`

		if (!showKoptUtbildningForm) {
			setValue(getFieldValuePath('koptUtbildning'), undefined)
		}

		setValue(isCsnUtbildning ? getFieldValuePath('ejCsnUtbildning') : getFieldValuePath('csnUtbildning'), undefined)

		if (!slutfordTidigare) {
			setValue(getFieldValuePath('vasentligSkillnad'), undefined)
		}
	}, [isCsnUtbildning, showKoptUtbildningForm, slutfordTidigare, setValue, index])

	const utbildningsNamnPlaceholder = `Utbildning ${index + 1}`
	const utbildningsNamn = isCsnUtbildning ? csnUtbildning?.utbildningsnamn : ejCsnUtbildning?.utbildningsnamn
	const accordionHeader = utbildningsNamn ? utbildningsNamn : utbildningsNamnPlaceholder

	const { t } = useTranslation('translation', { keyPrefix: 'adHocYttrande.form.utbildningar.form.base' })

	const shouldBeExpanded = !isReadOnly && isLastIndexInUtbildningArray
	const [expanded, setExpanded] = useState(shouldBeExpanded)

	useEffect(() => {
		if (!shouldBeExpanded) {
			setExpanded(false)
		}
	}, [shouldBeExpanded])

	return (
		<Accordion
			expanded={expanded}
			onChange={() => setExpanded(!expanded)}
			sx={(theme) => ({
				border: utbildningHasErroredFields ? `1px solid ${theme.palette.error.main}` : 'initial',
			})}
			data-testid={`utbildningar[${index}].utbildning-accordion`}
		>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Stack spacing={1} direction="row" alignItems="center">
					<Typography variant="h6" sx={{ wordBreak: 'break-word' }}>
						{accordionHeader}
					</Typography>
					{!isReadOnly && (
						<IconButton onClick={() => remove(index)}>
							<Delete />
						</IconButton>
					)}
				</Stack>
			</AccordionSummary>
			<AccordionDetails>
				<Stack spacing={5}>
					<Stack spacing={2}>
						<Stack spacing={1}>
							<Typography fontWeight="bold">{t('title')}</Typography>
							<Typography>{t('description')}</Typography>
						</Stack>
						<CsnUtbildningCheckbox index={index} />
					</Stack>

					<Stack spacing={5}>
						{isCsnUtbildning ? <CsnUtbildningForm index={index} /> : <EjCsnUtbildningForm index={index} />}

						{showKoptUtbildningForm && <KoptUtbildningForm index={index} />}
					</Stack>

					<Divider />

					<YttrandePerUtbildningForm index={index} />
				</Stack>
			</AccordionDetails>
		</Accordion>
	)
}

export default UtbildningBaseForm
