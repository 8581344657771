import { CsnUtbildningarListParams } from '@local/src/Services/SwaggerApi/data-contracts'
import { csnUtbildningarApi } from '@local/src/Services/apiEndpointHelper'
import { apiGetTryCatchWrapper } from '@local/src/Services/apiRequestHelper'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { minutesToMilliseconds } from 'date-fns'

const useGetUtbildningarFromCsn = (searchQuery: CsnUtbildningarListParams, shouldSearch = true) => {
	const queryClient = useQueryClient()
	const { searchTerm } = searchQuery

	const getUtbildningarFromCsn = (query: CsnUtbildningarListParams) =>
		apiGetTryCatchWrapper('useGetUtbildningarFromCsn', csnUtbildningarApi.csnUtbildningarList, query)

	const queryKey = ['getUtbildningarFromCsn', searchTerm]
	const cacheTime = minutesToMilliseconds(60)

	const searchTermIsLongEnough = Boolean(searchTerm) && Number(searchTerm?.length) > 1

	const { data: utbildningar, ...query } = useQuery({
		queryKey: queryKey,
		queryFn: () => getUtbildningarFromCsn(searchQuery),
		placeholderData: [],
		enabled: searchTermIsLongEnough && shouldSearch,
		staleTime: cacheTime,
		cacheTime: cacheTime,
	})

	const invalidateQuery = () => queryClient.invalidateQueries([queryKey])

	return { utbildningar, ...query, invalidateQuery }
}

export default useGetUtbildningarFromCsn
