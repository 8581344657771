import { useCommonTranslation, useCurrentUser } from '@local/src/Hooks'
import { useAdHocYttrandeFormContext, usePostCreateAdHocYttrande, useUpdateCsnAdHocYttrande } from '@local/src/Pages/AdHocYttrande/Hooks'
import { CreateCsnYttrandeFormModel } from '@local/src/Pages/AdHocYttrande/Models/AdHocYttrande.types'
import { ApiResponseModel } from '@local/src/Services/ApiResponseModel'
import {
	Button,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormHelperText,
	Grid,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AdHocYttrandeFormDeleteAction from './AdHocYttrandeFormDeleteAction'

interface Props {
	isNewAdHoc: boolean
	setApiResponse: (response: ApiResponseModel<void>) => void
	adhocYttrandeId: string
	isDeletable: boolean
}

const AdHocYttrandeFormActionButtons = ({ isNewAdHoc, setApiResponse, adhocYttrandeId, isDeletable }: Props) => {
	const [showConfirmModal, setShowConfirmModal] = useState(false)

	const { t } = useTranslation('translation', { keyPrefix: 'adHocYttrande.form.buttons' })
	const { tCommon } = useCommonTranslation()
	const { userHasRole } = useCurrentUser()
	const userHasRoleToSendToCsn = userHasRole(['HANDLAGGARE-STUDIER', 'KOORDINATOR-STUDIER', 'RADGIVNINGS-ADMINISTRATOR'])
	const {
		formState: { isSubmitting, errors },
		setValue,
		handleSubmit,
	} = useAdHocYttrandeFormContext()

	const { postCreateYttrandeToCsnMutation, apiResponse: createApiResponse } = usePostCreateAdHocYttrande()
	const { postUpdateCsnAdHocYttrandeMutation, apiResponse: updateApiResponse } = useUpdateCsnAdHocYttrande(adhocYttrandeId)

	const handleHideConfirmModal = () => setShowConfirmModal(false)
	const handleShowConfirmModal = () => setShowConfirmModal(true)

	useEffect(() => {
		setApiResponse(createApiResponse)
	}, [createApiResponse, setApiResponse])
	useEffect(() => {
		setApiResponse(updateApiResponse)
	}, [updateApiResponse, setApiResponse])

	const onSubmit = async (formData: CreateCsnYttrandeFormModel) => {
		formData.datumAnsokanOo = new Date(formData.datumAnsokanOo).toISOString()

		const mutationRequest = {
			formData,
		}
		if (isNewAdHoc) {
			await postCreateYttrandeToCsnMutation.mutateAsync(mutationRequest)
		} else {
			await postUpdateCsnAdHocYttrandeMutation.mutateAsync(mutationRequest)
		}
	}

	const onConfirm = async () => {
		setValue('shouldSendToCsnFormBool', true)
		handleHideConfirmModal()
		await handleSubmit(onSubmit)()
	}

	const onSave = async () => {
		setValue('shouldSendToCsnFormBool', false)
		await handleSubmit(onSubmit)()
	}

	const hasFormErrors = Object.keys(errors).length > 0

	return (
		<Container data-testid="ad-hoc-yttrande-action-buttons">
			<Grid container justifyContent="space-between" sx={{ mt: 4 }}>
				<Grid item>
					<Button
						variant={userHasRoleToSendToCsn ? 'outlined' : 'contained'}
						onClick={onSave}
						type="button"
						disabled={isSubmitting}
						data-testid="save-button"
						sx={{ mr: 2 }}
					>
						{tCommon('buttons.save')}
					</Button>

					{userHasRoleToSendToCsn && (
						<Button data-testid="send-to-csn-button" onClick={handleShowConfirmModal} disabled={isSubmitting}>
							{tCommon('buttons.sendToCsn')}
						</Button>
					)}
				</Grid>

				{!isNewAdHoc && (
					<Grid item>
						<AdHocYttrandeFormDeleteAction adHocYttrandeId={adhocYttrandeId} setApiResponse={setApiResponse} isDeletable={isDeletable} />
					</Grid>
				)}
			</Grid>
			{hasFormErrors && (
				<FormHelperText sx={{ fontSize: 16, mt: 2 }} error data-testid={'action-button-error'}>
					{t('formNotValid')}
				</FormHelperText>
			)}

			<Dialog
				data-testid="send-to-csn-dialog"
				open={showConfirmModal}
				aria-labelledby="alert-confirm-dialog-title"
				aria-describedby="alert-confirm-dialog-description"
			>
				<DialogTitle id="alert-confirm-dialog-title"> {t('confirmModal.title')}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-confirm-dialog-description">{t('confirmModal.description')}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button data-testid="dialog--cancel-button" onClick={handleHideConfirmModal} variant="text">
						{t('confirmModal.cancel')}
					</Button>
					<Button data-testid="dialog--confirm-button" onClick={onConfirm} variant="text">
						{tCommon('buttons.send')}
					</Button>
				</DialogActions>
			</Dialog>
		</Container>
	)
}

export default AdHocYttrandeFormActionButtons
