import Paper, { PaperProps } from '@mui/material/Paper'
import { Breakpoint, styled } from '@mui/material/styles'
import { themeOptions } from '@trr/mui-theme'
import React from 'react'

interface Props extends PaperProps {
	backgroundcolor?: keyof typeof themeOptions.palette.surface
	spacing?: number
	breakpoint?: Breakpoint
	breakpointspacing?: number
}

const HighlightBox = styled((props: Props) => <Paper elevation={0} {...props} />)(
	({ theme, backgroundcolor, spacing = 3, breakpoint = 'lg', breakpointspacing = 4 }) => ({
		padding: theme.spacing(spacing),
		backgroundColor: themeOptions.palette.surface[backgroundcolor] ?? theme.palette.surface?.orange,

		[theme.breakpoints.up(breakpoint)]: {
			padding: theme.spacing(breakpointspacing),
		},
	})
)

export default HighlightBox
