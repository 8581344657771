import { SvarPeriodKompletterandeBidragApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { maxLengthValidationText, requiredValidationText } from '@local/Utils/YupHelper'
import { number, object, SchemaOf, string } from 'yup'

export const PerioderSchema: SchemaOf<SvarPeriodKompletterandeBidragApiModel> = object({
	fromVecka: string().required(requiredValidationText).length(6, 'Måste vara 6 tecken').trim(),

	tomVecka: string().required(requiredValidationText).length(6, 'Måste vara 6 tecken').trim(),

	kompletterandeBidragsbelopp100: number()
		.required(requiredValidationText)
		.min(0, 'Bidragsbelopp 100% måste vara större än 0')
		.max(99999, maxLengthValidationText),

	kompletterandeBidragsbelopp75: number()
		.required(requiredValidationText)
		.min(0, 'Bidragsbelopp 75% måste vara större än 0')
		.max(99999, maxLengthValidationText),

	kompletterandeBidragsbelopp60: number()
		.required(requiredValidationText)
		.min(0, 'Bidragsbelopp 60% måste vara större än 0')
		.max(99999, maxLengthValidationText),

	kompletterandeBidragsbelopp50: number()
		.required(requiredValidationText)
		.min(0, 'Bidragsbelopp 50% måste vara större än 0')
		.max(99999, maxLengthValidationText),

	kompletterandeBidragsbelopp40: number()
		.required(requiredValidationText)
		.min(0, 'Bidragsbelopp 40% måste vara större än 0')
		.max(99999, maxLengthValidationText),

	kompletterandeBidragsbelopp20: number()
		.required(requiredValidationText)
		.min(0, 'Bidragsbelopp 20% måste vara större än 0')
		.max(99999, maxLengthValidationText),
})
