import { ChangeOperation } from '@local/src/Services/SwaggerApi/data-contracts'
import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { StyledTableEntry } from './StyledTableEntry'

interface Props {
	nested?: boolean
	displayName?: string
	groupChange?: ChangeOperation
}

const TablePropGroupHeader = ({ displayName, nested = false, groupChange }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'csnPortal.beslut.tabs.historik.tablePropGroupHeader' })

	const mapGroupChange = (): string => {
		switch (groupChange) {
			case ChangeOperation.Updated:
				return t('uppdaterad')

			case ChangeOperation.Created:
				return t('tillagd')

			case ChangeOperation.Deleted:
				return t('borttagen')

			default:
				break
		}
	}

	return (
		<Stack direction={'row'} justifyContent="space-between">
			<Box width="150px" />
			<StyledTableEntry isNested={nested}>
				<Typography fontWeight={nested ? 'bold' : 'regular'} title={displayName}>
					{displayName}
				</Typography>
			</StyledTableEntry>
			<StyledTableEntry>
				<Typography fontWeight={'bold'}>{mapGroupChange()}</Typography>
			</StyledTableEntry>
			<StyledTableEntry />
		</Stack>
	)
}

export default TablePropGroupHeader
