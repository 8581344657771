import { AGEInformation, Radgivning, TidigareRadgivare } from './OmstallningsArende.types'

export enum ArendeTyp {
	Omstallning = 'OmstallningsArende',
	Anstallning = 'AnstallningsArende',
}

export enum ArendeMarkning {
	Chefsomstallning = 'CHEFSOMSTALLNING',
	Undantagsgrupp = 'VDUNDANTAGSGRUPP',
	ForstarktStod = 'FORSTARKTSTOD',
	StartaForetag = 'STARTAFORETAG',
	Studier = 'STUDIESPECIALIST',
}
export enum OmfattningsbeslutStatus {
	Odefinierad = 'Odefinierad',
	Nytt = 'Nytt',
	Manuellt = 'Manuellt',
	Signerad = 'Signerad',
	Granskad = 'Granskad',
	Attesterad = 'Attesterad',
	EjGodkand = 'Ej godkänd',
	Ersatt = 'Ersatt',
}
export interface ArendeApiModel {
	id: string
	arendeNummer: string
	arendeTyp: ArendeTyp | string
	omfattningsbeslutStatus: OmfattningsbeslutStatus | string
	klient: Klient
	omstallningsArende: OmstallningsArende
	anstallningsArende: AnstallningsArende
	ovrigaArenden: OvrigaArenden[]
	hasStudieansokningar: boolean
	arendeMarkningar: Markning[]
	omfattningspaket: Omfattningspaket[]
	statusDisplayText: string
	infoText: string
	warningTexts: {
		bedomningPagar: string
	}
	inbokadeMoten: Meeting[]
}
export interface Klient {
	id: string
	fornamn: string
	efternamn: string
	alder: number
	kommun: string
	lan: string
	gatuadress: string
	postnummer: string
	ort: string
	linkedInUrl: string
	telefonnummer: string
	samtyckeHalsa: boolean
	epost: string
	personnummer: string
	folkbokforingsStatus: string
	klientBildFilnamn: string
}
export interface AnstallningsArende {
	anstallningsuppgifter: Anstallningsuppgifter
	attesteringsDatum: string
	inkomstuppgifter: Inkomstuppgifter
	ansokanKomplettDatum: string
	arendeStatus: string
	team: {
		id: string
		namn: string
	}
	avslutsInformation: AnstallningsAvslutsInformation
	radgivare: Radgivare
	meddelandeFranHandlaggare: string
}

export interface OmstallningsArende {
	anstallningsuppgifter: Anstallningsuppgifter
	attesteringsDatum: string
	inkomstuppgifter: Inkomstuppgifter
	ansokanKomplettDatum: string

	hasActiveMatchProfile: boolean
	nonActiveMatchProfileReasons: string[]
	age: AGEInformation
	meddelandeFranAgeHandlaggare: string
	orsakAnsokan: string
	orsakAnsokanDisplayText: string
	projekt: Projekt
	radgivare: Radgivare
	radgivning: Radgivning
	senasteKontaktDatum?: string
	tidigareRadgivare: TidigareRadgivare[]
}
interface Radgivare {
	id: string
	teamId?: string
	fornamn: string
	efternamn: string
	radgivarStatistik?: {
		idag: number
		in: number
		inPlusMinus: number
		aktuella: number
	}
}
interface Omfattningspaket {
	id: string
	namn: Omfattningspaket
	produkt: Produkt
	content: string | null
}

interface Produkt {
	id: string
	namn: string
	tjanster: Tjanst[]
}
interface Tjanst {
	id: string
	namn: string
}
interface Meeting {
	id: string
	klientId: string
	startDatumTid: string
	slutDatumTid: string
	radgivareNamn: string
	motesTyp: number
	motesVariant?: string
}

export interface OvrigaArenden {
	arendeId: string
	arendeTyp: ArendeTyp
	avslutsDatum: string
	radgivareId: string
	radgivareFornamn: string
	radgivareEfternamn: string
}
export interface Markning {
	id: ArendeMarkning | string
	displayText: string
	isSet: boolean
	editable: boolean
}

export interface Anstallningsuppgifter {
	avgangsvederlagSlutDatum?: Date
	anstallningsDatum: string
	anstallningsgrad: number
	arbetstimmarPerVecka: number
	avgangsvederlagBelopp: number
	befattning: string
	foretag: {
		id: string
		namn: string
		organisationsnummer: string
	}
	uppsagningsgrad: number
	uppsagningsDatum: string
}
export interface Inkomstuppgifter {
	harUppdateratLon: boolean
	jour: number
	kostLogi: number
	lonerevisionsDatum: string
	manadsLon: number
	provision: number
	sistaDagMedLon: string
	totalLon: number
}

interface Projekt {
	id: string
	namn: string
	nummer: string
}

interface AnstallningsAvslutsInformation {
	avslutadAv: string
	avslutsDatum: string
	avslutskod: string
}
