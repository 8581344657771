import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'
import { useController } from 'react-hook-form'

import { SelectOption } from './Dropdown.types'

interface Props {
	label: string
	name: string
	options: SelectOption[]
	testId?: string
	disabled?: boolean
}

const FormDropdown = ({ label, name, testId, options, disabled = false }: Props) => {
	const {
		field,
		fieldState: { error },
	} = useController({ name })
	return (
		<FormControl fullWidth error={Boolean(error)} disabled={disabled}>
			<InputLabel sx={{ fontWeight: 400 }}>{label}</InputLabel>
			<Select
				{...field}
				value={field.value ?? ''}
				label={label}
				data-testid={testId || name}
				MenuProps={{
					sx: {
						maxHeight: '500px',
					},
				}}
			>
				{options.map((option) => (
					<MenuItem key={`${option.value}`} value={option.value} data-testid={`${name}-${option.value}`}>
						{option.label}
					</MenuItem>
				))}
			</Select>
			<FormHelperText>{error?.message}</FormHelperText>
		</FormControl>
	)
}

export default FormDropdown
