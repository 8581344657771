import { csnBeslutApi } from '@local/src/Services/apiEndpointHelper'
import { apiGetTryCatchWrapper } from '@local/src/Services/apiRequestHelper'
import { SearchBeslutRequestApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { useQuery } from '@tanstack/react-query'

const useSearchCsnBeslut = (searchQuery: SearchBeslutRequestApiModel) => {
	const { data: beslutSearchResponse, ...query } = useQuery({
		queryKey: [
			'searchCsnBeslut',
			searchQuery?.personnummer,
			searchQuery?.page,
			searchQuery?.pageSize,
			searchQuery?.csnBeslutsutfall,
			searchQuery?.studieforsakran,
		],
		queryFn: () => apiGetTryCatchWrapper('useSearchCsnBeslut', csnBeslutApi.csnArendeBeslutSearchCreate, searchQuery),
		placeholderData: {
			totalPages: 0,
			totalCount: 0,
			pageNumber: 1,
			values: [],
			facets: [],
		},
		keepPreviousData: true,
	})

	return { beslutSearchResponse, ...query }
}

export default useSearchCsnBeslut
