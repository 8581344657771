import '@local/src/Utils/YupExtensions'
import CsnRoutes from '@local/src/Routing/CsnRoutes'
import { initTranslations, reactQueryClient } from '@local/src/Utils/appBootstrapper'
import { ThemeOptions, ThemeProvider, createTheme } from '@mui/material'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { AppShellDataProps, AppShellDataProvider } from '@trr/app-shell-data'
import { defaultLocale, themeOptions } from '@trr/mui-theme'
import React from 'react'
import { injectStyle } from 'react-toastify/dist/inject-style'

import { ToasterContainer } from './Components'

injectStyle()
initTranslations()
const customTheme: ThemeOptions = {
	components: {
		MuiAccordionSummary: {
			defaultProps: {
				sx: { userSelect: 'auto' },
			},
		},
	},
}

const theme = createTheme(themeOptions, defaultLocale, customTheme)

const App = ({ appShellData }: { appShellData: AppShellDataProps }) => (
	<QueryClientProvider client={reactQueryClient}>
		<ThemeProvider theme={theme}>
			<AppShellDataProvider value={appShellData}>
				<CsnRoutes currentKey={appShellData.currentKey} />
			</AppShellDataProvider>
			<ReactQueryDevtools initialIsOpen={false} />
			<ToasterContainer />
		</ThemeProvider>
	</QueryClientProvider>
)
export default App
