import { SearchBeslutRequestApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { AES, enc } from 'crypto-js'

export const useEncryptBeslutFilter = (key: string) => {
	if (!key) {
		throw new Error('Missing encryption key')
	}
	const storageKey = 'CsnPortalBeslutEncryptedFilter'
	const encryptFilter = (data: SearchBeslutRequestApiModel) => {
		const aesEncrypted = AES.encrypt(JSON.stringify(data), key).toString()
		localStorage.setItem(storageKey, aesEncrypted)
	}

	const decryptFilter = () => {
		try {
			const encryptedData = localStorage.getItem(storageKey)

			const bytes = AES.decrypt(encryptedData, key)
			const decryptedData = JSON.parse(bytes.toString(enc.Utf8)) as SearchBeslutRequestApiModel
			return decryptedData
		} catch (e) {
			return {
				page: 1,
				pageSize: 5,
			}
		}
	}

	return { encryptFilter, decryptFilter }
}
