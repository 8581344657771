import { AdHocYttrandeApiModel } from '@local/src/Services/SwaggerApi/data-contracts'

import { CreateCsnYttrandeFormModel, UtbildningarFormModel } from '../Models/AdHocYttrande.types'

interface Props {
	ansokanId: string
	personnummer: string
	adhocYttrandeData: AdHocYttrandeApiModel
	isReadOnly?: boolean
	isNewAdHoc?: boolean
}
const useSetDefaultFormDataAdhocYttrande = ({ ansokanId, personnummer, adhocYttrandeData, isReadOnly = false, isNewAdHoc }: Props) => {
	const setUtbildnignar = () =>
		adhocYttrandeData.utbildningar.map(
			(u): UtbildningarFormModel => ({
				specifikArbetsgivare: u.specifikArbetsgivare,
				tillhandahallasAvArbetsgivare: u.tillhandahallasAvArbetsgivare,
				slutfordTidigare: u.slutfordTidigare,
				vasentligSkillnad: u.vasentligSkillnad,
				ejCsnUtbildning: u.ejCsnUtbildning,
				csnUtbildning: u.csnUtbildning,
				isCsnUtbildning: u.csnUtbildning ? true : false,
				koptUtbildning: u.koptUtbildning,
			})
		)

	const isSvarForBegaran = isNewAdHoc ? null : Boolean(adhocYttrandeData?.begaranId?.length)

	return {
		isReadOnly,
		ansokanId,
		personnummer,
		datumAnsokanOo: adhocYttrandeData?.datumAnsokanOo ?? null,
		kompletterandeBidrag: adhocYttrandeData?.kompletterandeBidrag ?? null,
		malMedStudier: adhocYttrandeData?.malMedStudier ?? '',
		starksFramtidaStallning: adhocYttrandeData?.starksFramtidaStallning ?? null,
		orsakStarksFramtidaStallning: adhocYttrandeData?.orsakStarksFramtidaStallning ?? null,
		orsakMotivering: adhocYttrandeData?.orsakMotivering ?? '',
		utbildningar: adhocYttrandeData?.utbildningar ? setUtbildnignar() : [],
		begaranId: adhocYttrandeData?.begaranId,
		isSvarForBegaran,
	} as CreateCsnYttrandeFormModel
}

export default useSetDefaultFormDataAdhocYttrande
