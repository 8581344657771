import { Box, SxProps, TextField, Theme } from '@mui/material'
import React from 'react'
import { useFormContext } from 'react-hook-form'

interface IInputField {
	name: string
	label: string
	disabled?: boolean
	testId?: string
	wrapperSx?: SxProps<Theme>
}

export const TextInputField = ({ name, label, disabled, testId, wrapperSx = {} }: IInputField) => {
	const { register, getFieldState } = useFormContext()
	const { error } = getFieldState(name)

	return (
		<Box sx={wrapperSx}>
			<TextField
				{...register(name, {
					setValueAs: (val: string) => val,
				})}
				inputProps={{
					'data-testid': testId || name,
				}}
				label={label}
				disabled={disabled}
				error={Boolean(error?.message)}
				helperText={error?.message}
				fullWidth
			/>
		</Box>
	)
}
