import { ApiResponseModel } from '@local/src/Services/ApiResponseModel'

export const getApiResponseError = (message: string): ApiResponseModel<void> => ({
	hasError: true,
	isSuccess: false,
	error: {
		status: 500,
		errorMessage: message,
		errorType: 'Generic Error',
		errorDetails: 'Something went wrong',
	},
})
