/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
	BegaranCsnYttrandeListItemApiModel,
	CsnArendeBegaranYttrandeSvarCreateParams,
	CsnArendeKlientBegaranYttrandeDetailParams,
	CsnYttrandeApiModel,
	ProblemDetails,
	SvarBegaranYttrandeApiModel,
	TrrProblemDetails,
	TrrValidationProblemDetails,
	UpdateLastDateToSvaraApiModel,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class CsnArendeBegaran<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
	/**
	 * No description
	 *
	 * @tags CsnArendeBegaran
	 * @name CsnArendeBegaranYttrandeDetail
	 * @request GET:/studiestod/csn-arende/begaran/yttrande/{begaranId}
	 * @secure
	 * @response `200` `CsnYttrandeApiModel` OK
	 * @response `404` `ProblemDetails` Not Found
	 * @response `500` `TrrProblemDetails` Internal Server Error
	 */
	csnArendeBegaranYttrandeDetail = (begaranId: string, params: RequestParams = {}) =>
		this.request<CsnYttrandeApiModel, ProblemDetails | TrrProblemDetails>({
			path: `/studiestod/csn-arende/begaran/yttrande/${begaranId}`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		})
	/**
	 * No description
	 *
	 * @tags CsnArendeBegaran
	 * @name CsnArendeBegaranYttrandeAnsokanKopplaPartialUpdate
	 * @request PATCH:/studiestod/csn-arende/{grundansokanId}/begaran/yttrande/{begaranId}/ansokan/{ansokanId}/koppla
	 * @secure
	 * @response `204` `void` No Content
	 * @response `500` `TrrProblemDetails` Internal Server Error
	 */
	csnArendeBegaranYttrandeAnsokanKopplaPartialUpdate = (
		grundansokanId: string,
		begaranId: string,
		ansokanId: string,
		params: RequestParams = {}
	) =>
		this.request<void, TrrProblemDetails>({
			path: `/studiestod/csn-arende/${grundansokanId}/begaran/yttrande/${begaranId}/ansokan/${ansokanId}/koppla`,
			method: 'PATCH',
			secure: true,
			...params,
		})
	/**
	 * No description
	 *
	 * @tags CsnArendeBegaran
	 * @name CsnArendeBegaranYttrandeSvarCreate
	 * @request POST:/studiestod/csn-arende/{grundansokanId}/begaran/yttrande/{begaranId}/svar
	 * @secure
	 * @response `202` `void` Accepted
	 * @response `400` `TrrValidationProblemDetails` Bad Request
	 * @response `500` `TrrProblemDetails` Internal Server Error
	 */
	csnArendeBegaranYttrandeSvarCreate = (
		{ grundansokanId, begaranId, ...query }: CsnArendeBegaranYttrandeSvarCreateParams,
		data: SvarBegaranYttrandeApiModel,
		params: RequestParams = {}
	) =>
		this.request<void, TrrValidationProblemDetails | TrrProblemDetails>({
			path: `/studiestod/csn-arende/${grundansokanId}/begaran/yttrande/${begaranId}/svar`,
			method: 'POST',
			query: query,
			body: data,
			secure: true,
			type: ContentType.Json,
			...params,
		})
	/**
	 * No description
	 *
	 * @tags CsnArendeBegaran
	 * @name CsnArendeBegaranYttrandeLastDateToSvaraCreate
	 * @request POST:/studiestod/csn-arende/{grundansokanId}/begaran/yttrande/{begaranId}/last-date-to-svara
	 * @secure
	 * @response `202` `void` Accepted
	 * @response `400` `TrrValidationProblemDetails` Bad Request
	 * @response `500` `TrrProblemDetails` Internal Server Error
	 */
	csnArendeBegaranYttrandeLastDateToSvaraCreate = (
		grundansokanId: string,
		begaranId: string,
		data: UpdateLastDateToSvaraApiModel,
		params: RequestParams = {}
	) =>
		this.request<void, TrrValidationProblemDetails | TrrProblemDetails>({
			path: `/studiestod/csn-arende/${grundansokanId}/begaran/yttrande/${begaranId}/last-date-to-svara`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			...params,
		})
	/**
	 * No description
	 *
	 * @tags CsnArendeBegaran
	 * @name CsnArendeKlientBegaranYttrandeDetail
	 * @request GET:/studiestod/csn/arende/klient/{personnummer}/begaran/yttrande
	 * @secure
	 * @response `200` `(BegaranCsnYttrandeListItemApiModel)[]` OK
	 * @response `500` `TrrProblemDetails` Internal Server Error
	 */
	csnArendeKlientBegaranYttrandeDetail = (
		{ personnummer, ...query }: CsnArendeKlientBegaranYttrandeDetailParams,
		params: RequestParams = {}
	) =>
		this.request<BegaranCsnYttrandeListItemApiModel[], TrrProblemDetails>({
			path: `/studiestod/csn/arende/klient/${personnummer}/begaran/yttrande`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		})
}
