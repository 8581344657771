import { FacetChip } from '@local/src/Components'
import useSearchCsnBeslut from '@local/src/Pages/CsnPortal/CsnBeslut/Hooks/useSearchCsnBeslut'
import { BeslutsPeriodStudieforsakran, SearchBeslutRequestApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	searchQuery: SearchBeslutRequestApiModel
	setSearchQuery: React.Dispatch<React.SetStateAction<SearchBeslutRequestApiModel>>
}

const StudieforsakranFilter = ({ searchQuery, setSearchQuery }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'csnPortal.beslut.searchFilter' })
	const { beslutSearchResponse } = useSearchCsnBeslut({ ...searchQuery, studieforsakran: undefined, pageSize: 1, page: 1 })
	const facets = beslutSearchResponse?.facets?.find((f) => f.name === 'Studieforsakran')?.facet || []
	const godkandCount = facets.find((y) => y.name === 'Godkand')?.count ?? 0
	const ejGodkandCount = facets.find((y) => y.name === 'EjGodkand')?.count ?? 0
	const handleToggleStudieforsakran = (selectedStudieforsakran: BeslutsPeriodStudieforsakran) => {
		setSearchQuery((prevState) => {
			const toggledValue = prevState.studieforsakran === selectedStudieforsakran ? undefined : selectedStudieforsakran
			return { ...prevState, studieforsakran: toggledValue }
		})
	}

	return (
		<Stack spacing={1}>
			<Typography>{t('labels.studieforsakran')}</Typography>
			<Stack direction="row" flexWrap="wrap" gap={1}>
				<FacetChip
					dataTestId={`csn-beslut-search-filter-chip-studieforsakran-Godkand`}
					label={t('filter_godkand', { count: godkandCount })}
					facet={BeslutsPeriodStudieforsakran.Godkand}
					onFacetClicked={handleToggleStudieforsakran}
					selectedFacet={searchQuery.studieforsakran}
				/>

				<FacetChip
					dataTestId={`csn-beslut-search-filter-chip-studieforsakran-EjGodkand`}
					label={t('filter_ejgodkand', { count: ejGodkandCount })}
					facet={BeslutsPeriodStudieforsakran.EjGodkand}
					onFacetClicked={handleToggleStudieforsakran}
					selectedFacet={searchQuery.studieforsakran}
				/>
			</Stack>
		</Stack>
	)
}

export default StudieforsakranFilter
