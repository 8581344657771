import { Box, TextField } from '@mui/material'
import React from 'react'
import { useFormContext } from 'react-hook-form'

interface ITextArea {
	name: string
	label: string
	disabled?: boolean
	rows?: number
	testId?: string
}

export const TextArea = ({ name, label, disabled, rows = 3, testId }: ITextArea) => {
	const { register, getFieldState } = useFormContext()

	const { error } = getFieldState(name)
	return (
		<Box position="relative" mb={3}>
			<TextField
				{...register(name)}
				inputProps={{
					'data-testid': testId || name,
				}}
				type={'text'}
				label={label}
				disabled={disabled}
				error={Boolean(error?.message)}
				helperText={error?.message}
				rows={rows}
				multiline
				fullWidth
			/>
		</Box>
	)
}
