import { Dropdown } from '@local/src/Components/FormComponents/Dropdown'
import { SelectOption } from '@local/src/Components/FormComponents/Dropdown/Dropdown.types'
import { CsnRegisterUtbildningApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { Box, Button, Typography } from '@mui/material'
import { Table } from '@tanstack/react-table'
import React from 'react'
import { useTranslation } from 'react-i18next'

const pageSizeOptions: SelectOption[] = [
	{ label: '10', value: 10 },
	{ label: '20', value: 20 },
	{ label: '50', value: 50 },
]

interface Props {
	table: Table<CsnRegisterUtbildningApiModel>
	totalCount: number
}

const Pagination = ({ table, totalCount }: Props) => {
	const { setPageSize, getState, previousPage, nextPage, getCanNextPage, getCanPreviousPage } = table
	const { t } = useTranslation('translation', { keyPrefix: 'csnPortal.utbildningarFromCsn.pagination' })

	const getPaginationState = () => getState().pagination

	const getDropdownOption = (pageSizeOptions: SelectOption[]) =>
		pageSizeOptions.filter((option) => getPaginationState().pageSize === option.value)[0].value

	const paginationIntervallStart = getPaginationState().pageIndex * getPaginationState().pageSize
	const paginationIntervallEnd =
		(getPaginationState().pageIndex + 1) * getPaginationState().pageSize > totalCount
			? totalCount
			: (getPaginationState().pageIndex + 1) * getPaginationState().pageSize

	return (
		<Box display="flex" alignContent="center" alignItems="center" gap={1} mt={6}>
			<Box display="flex" flexDirection="row" alignItems="center" gap={1}>
				<Button variant="outlined" onClick={previousPage} disabled={!getCanPreviousPage()} sx={{ mr: 1 }}>
					{t('previousButton')}
				</Button>
				<Button variant="outlined" onClick={nextPage} disabled={!getCanNextPage()} sx={{ mr: 1 }}>
					{t('nextButton')}
				</Button>
				<Typography>
					{`${t('showingHit')} ${paginationIntervallStart} - ${paginationIntervallEnd} av ${totalCount} ${t('totalHits')}`}
				</Typography>
			</Box>
			<Box
				marginLeft="auto"
				sx={{
					'> div': {
						minWidth: '150px',
					},
				}}
			>
				<Dropdown
					selectOptions={pageSizeOptions}
					label={t('hitsPerPage')}
					value={getDropdownOption(pageSizeOptions)}
					onChange={(value) => setPageSize(Number(value))}
				/>
			</Box>
		</Box>
	)
}
export default Pagination
