import { CsnYttrandeKoppladFilter } from '@local/src/Services/SwaggerApi/data-contracts'
import { csnBegaranYttrandeApi } from '@local/src/Services/apiEndpointHelper'
import { apiGetTryCatchWrapper } from '@local/src/Services/apiRequestHelper'
import { useQuery } from '@tanstack/react-query'

export const useGetBegaranYttranden = (personnummer: string) => {
	const { data: begaranYttranden, ...query } = useQuery({
		queryKey: ['useGetBegaranYttranden', personnummer],
		queryFn: () =>
			apiGetTryCatchWrapper('useGetBegaranYttranden', csnBegaranYttrandeApi.csnArendeKlientBegaranYttrandeDetail, {
				personnummer,
				ansokankopplad: CsnYttrandeKoppladFilter.Alla,
			}),
		placeholderData: [],
		enabled: Boolean(personnummer),
	})

	return { begaranYttranden, ...query }
}
