import { ApiResponseModel } from '@local/Services/ApiResponseModel'
import { TrrValidationErrorField } from '@local/Services/SwaggerApi/data-contracts'
import { useCommonTranslation } from '@local/src/Hooks'
import { styled } from '@mui/material'
import React, { Fragment, useEffect } from 'react'

import { pushFeedback } from '../Toaster/Toaster'

const ErrorMessage = styled('p')(({ theme }) => ({
	color: theme.palette.error.main,
}))

const ErrorListHeader = styled('li')(({ theme }) => ({
	color: theme.palette.error.main,
}))

interface Props {
	apiResponse: ApiResponseModel<unknown>
	showResponseSuccessfulJson?: boolean
	customSuccessLabel?: string
	customFailureLabel?: string
}

const SubmitResponse = ({
	apiResponse,
	showResponseSuccessfulJson,
	customSuccessLabel = 'Anropet lyckades',
	customFailureLabel = 'Anropet misslyckades',
}: Props) => {
	const { tCommon } = useCommonTranslation()

	useEffect(() => {
		if (apiResponse !== undefined) {
			const message = apiResponse.isSuccess ? customSuccessLabel : customFailureLabel
			pushFeedback({
				body: message,
				testSelector: apiResponse.isSuccess ? 'toaster-confirmation' : 'toaster-error',
				isSuccess: apiResponse.isSuccess,
			})
		}
	}, [apiResponse, customFailureLabel, customSuccessLabel])

	if (!apiResponse) return <></>
	return (
		<Fragment>
			{apiResponse.isSuccess && showResponseSuccessfulJson && (
				<pre>
					<code>{JSON.stringify(apiResponse.payload, null, 2)}</code>
				</pre>
			)}
			{apiResponse.error && (
				<pre>
					<ErrorMessage>{`${apiResponse.error.errorType} - ${
						apiResponse?.error?.errorDetails ?? tCommon('errors.generic_error')
					}`}</ErrorMessage>
					{apiResponse.error.validationErrors?.map((err: TrrValidationErrorField) => (
						<ErrorListHeader key={err.propertyName}>
							- {err.propertyName} <ErrorMessage>{err.errorMessage}</ErrorMessage>
						</ErrorListHeader>
					))}
				</pre>
			)}
		</Fragment>
	)
}

export default SubmitResponse
