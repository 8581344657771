import { YttrandeFormModel } from '@local/src/Pages/BegaranYttrande/Edit/Components/BegaranYttrandeSvarForm/Models/BegaranYttrandeSvarForm.types'

export const mapToAvstarYttrandeFormModel = (yttrande: YttrandeFormModel): YttrandeFormModel => ({
	orsakMotivering: undefined,
	orsakStarksFramtidaStallning: undefined,
	starksFramtidaStallning: 3,
	datumAnsokanOo: undefined,
	kompletterandeBidrag: false,
	utbildningar: yttrande?.utbildningar?.map((u) => ({
		...u,
		slutfordTidigare: undefined,
		specifikArbetsgivare: undefined,
		tillhandahallasAvArbetsgivare: undefined,
		ooFinansierarKoptUtb: u.isAnsoktKoptUtbildning ? false : undefined,
		vasentligSkillnad: undefined,
	})),
})
