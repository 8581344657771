import Loader from '@local/src/Components/Loader/Loader'
import React from 'react'

interface Props {
	children: React.ReactNode
	isLoading: boolean
	fallback?: React.ReactNode
}
/**
 * Wrapper for loading component that conditionally renders a fallback UI if `isLoading` is true,
 * or the given children if `isLoading` is false.
 *
 * @component
 * @param {React.ReactNode} props.children - The content to render when `isLoading` is `false`.
 * @param {boolean} props.isLoading - Determines whether to show the loading state or the content.
 * @param {React.ReactNode} [props.fallback] - (Optional default is Loader.tsx component) The fallback content to render when `isLoading` is `true`
 */
const Loading = ({ children, isLoading, fallback }: Props) => {
	if (isLoading) {
		return fallback ? <>{fallback}</> : <Loader />
	}
	return <>{children}</>
}

export default Loading
