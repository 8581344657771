import React from 'react'
import { Box} from '@mui/material'

interface Props {
	children?: React.ReactNode;
	dir?: string;	
	index: number;
	value: number;
  }
  
const TabPanel = ({children, value, index, ...other}: Props) => (
    <div
    role="tabpanel"
    hidden={value !== index}
    id={`full-width-tabpanel-${index}`}
    aria-labelledby={`full-width-tab-${index}`}
    {...other}
    >
    {value === index && (
        <Box>
        {children}
        </Box>
    )}
    </div>
)

export default TabPanel