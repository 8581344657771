import { PageWrapper } from '@local/src/Components/PageWrapper'
import { useCommonTranslation } from '@local/src/Hooks'
import { CsnYttrandeBeslutApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { formatDateTime } from '@local/src/Utils/dateFormatHelper'
import { Box, Stack, Typography, styled } from '@mui/material'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { v5 as uuid } from 'uuid'

import CommonKlientInformation from '../Common/CommonKlientInformation'

import { useCsnArendeBeslutHistorikDetail } from './Hooks/useCsnArendeBeslutHistorikDetail'
import { TableEntry, TableHeader, TablePropGroupHeader } from './Components'

interface Props {
	beslut: CsnYttrandeBeslutApiModel
	isSelected: boolean
}

const StyledUl = styled('ul')({
	listStyleType: 'none',
	padding: 0,
	margin: 0,
})

const StyledLi = styled('li')(({ theme }) => ({
	marginLeft: theme.spacing(1),
	listStyle: 'none',
}))

const HistorikTab = ({ beslut, isSelected }: Props) => {
	const { data, isError, isSuccess, isFetched } = useCsnArendeBeslutHistorikDetail(beslut.grundansokanId, isSelected)
	const { t } = useTranslation('translation', { keyPrefix: 'csnPortal.beslut.tabs.historik.labels' })
	const { tCommon } = useCommonTranslation()

	return (
		<PageWrapper errorLoadingData={isError} isLoading={!isFetched} errorText={tCommon('errors.generic_error')}>
			{isSuccess && (
				<Stack spacing={3}>
					<CommonKlientInformation beslut={beslut} />

					<Typography data-testid="beslut-forandringar-no-result" variant="h5">
						{t('beslutForandringar.title')}
					</Typography>

					{data.length === 0 ? (
						<Typography> {t('beslutForandringar.noResult')}</Typography>
					) : (
						<Fragment>
							<TableHeader />
							<Box data-testid="beslut-change-log-wrapper">
								{data.map((andringsTillfalle) => (
									<Box key={andringsTillfalle.occuredAt} mt={3}>
										<StyledLi>
											<Typography fontWeight="bold">{formatDateTime(andringsTillfalle.occuredAt)}</Typography>

											<StyledUl>
												{andringsTillfalle.baseProperties.map((change, index) => (
													<li
														data-testid={`beslut-change-log-base-properties-${index}`}
														key={`base-${change.occuredAt}-${change.propertyName}`}
													>
														<TableEntry change={change} />
													</li>
												))}

												{andringsTillfalle.utbildningChangeLogList.length > 0 && (
													<Fragment>
														<TablePropGroupHeader displayName={t('beslutForandringar.utbildningar')} />
														<StyledUl>
															{andringsTillfalle.utbildningChangeLogList.map(({ changes, displayName, groupChange }, index) => (
																<Box
																	data-testid={`beslut-change-log-utbildningar-changes-${index}`}
																	key={`${uuid(`gr-utb-${displayName}`, beslut.id)}`}
																>
																	<TablePropGroupHeader displayName={displayName} nested groupChange={groupChange} />
																	{changes.map((change) => (
																		<StyledLi key={`utb-${change.occuredAt}-${change.propertyName}`}>
																			<TableEntry change={change} nestedEntry />
																		</StyledLi>
																	))}
																</Box>
															))}
														</StyledUl>
													</Fragment>
												)}

												{andringsTillfalle.beslutPeriodChangeLogList.length > 0 && (
													<Fragment>
														<TablePropGroupHeader displayName={t('beslutForandringar.perioder')} />
														<StyledUl data-testid="beslut-change-log-perioder">
															{andringsTillfalle.beslutPeriodChangeLogList.map(({ changes, displayName, groupChange }, index) => (
																<div
																	data-testid={`beslut-change-log-perioder-changes-${index}`}
																	key={`${uuid(`gr-beslut-${displayName}`, beslut.id)}`}
																>
																	<TablePropGroupHeader
																		displayName={`${t('beslutForandringar.studiePeriod')} (${displayName})`}
																		nested
																		groupChange={groupChange}
																	/>
																	{changes.map((change) => (
																		<StyledLi key={`beslut-${change.occuredAt}-${change.propertyName}`}>
																			<TableEntry change={change} nestedEntry />
																		</StyledLi>
																	))}
																</div>
															))}
														</StyledUl>
													</Fragment>
												)}
											</StyledUl>
										</StyledLi>
									</Box>
								))}
							</Box>
						</Fragment>
					)}
				</Stack>
			)}
		</PageWrapper>
	)
}

export default HistorikTab
