import { ApiResponseModel } from '@local/src/Services/ApiResponseModel'
import { csnBegaranYttrandeApi } from '@local/src/Services/apiEndpointHelper'
import { apiRequestTryCatchWrapper } from '@local/src/Services/apiRequestHelper'
import { getApiResponseError } from '@local/src/Services/apiResponseHelper'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'

interface KopplaBegaranYttrandeSvar {
	grundansokanId: string
	begaranId: string
	ansokanId: string
}

export const usePostKopplaBegaranYttrande = () => {
	const queryClient = useQueryClient()
	const [apiResponse, setApiResponse] = useState<ApiResponseModel<void>>(undefined)
	const postKopplaBegaranYttrandeMutation = useMutation({
		mutationFn: (param: KopplaBegaranYttrandeSvar) => {
			setApiResponse(undefined)

			return apiRequestTryCatchWrapper(
				'usePostKopplaBegaranYttrande',
				csnBegaranYttrandeApi.csnArendeBegaranYttrandeAnsokanKopplaPartialUpdate,
				param.grundansokanId,
				param.begaranId,
				param.ansokanId
			)
		},
		onSuccess: async (response) => {
			setApiResponse(response)
			if (response.isSuccess) {
				await queryClient.invalidateQueries()
			}
		},
		onError: (response: { message?: string }) => {
			setApiResponse(getApiResponseError(response?.message))
		},
	})

	return { postKopplaBegaranYttrandeMutation, apiResponse }
}
