import { useFormContext } from 'react-hook-form'

import { CreateCsnYttrandeFormModel } from '../Models/AdHocYttrande.types'

const useAdHocYttrandeFormContext = () => {
	const formContext = useFormContext<CreateCsnYttrandeFormModel>()
	const isReadOnly = formContext.watch('isReadOnly')
	return { ...formContext, isReadOnly }
}

export default useAdHocYttrandeFormContext
