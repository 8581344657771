export const jaNejValueToString = (value: string) => {
	if (value === 'J') {
		return 'Ja'
	} else if (value === 'N') {
		return 'Nej'
	}
	return value
}

export const booleanTojaNejValue = (value?: boolean) => {
	if (value === true) return 'Ja'
	if (value === false) return 'Nej'
	return ''
}
export const isTrue = (value?: boolean | string) => value === 'true' || value === true || value === 'J'
export const isFalse = (value?: boolean | string) => value === 'false' || value === false || value === 'N'
