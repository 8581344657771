import { CsnUtbildningYttrandeApiModel } from '@local/Services/SwaggerApi/data-contracts'
import { CustomAccordionItem } from '@local/src/Components/Accordion'
import { UtbildningKurser } from '@local/src/Components/Yttranden'
import { JaNejRadioButtonGroup } from '@local/src/Pages/Common/Fragments'
import JaNejArbetslosRadioButtonGroup from '@local/src/Pages/Common/Fragments/JaNejArbetslosRadioButtonGroup'
import { Stack } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import useUtbildningFormContext from '../../Hooks/useUtbildningFormContext'
import { SvarEjCsnUtbildning } from '../SvarEjCsnUtbildning'

interface Props {
	utbildningBegaran: CsnUtbildningYttrandeApiModel
	index: number
	isReadOnly?: boolean
}
const SvarUtbildning = ({ utbildningBegaran, index, isReadOnly }: Props) => {
	const { utbildningarHasErrors, getUtbildningPath, showEjCsnUtbildning, showVasentligSkillnad } = useUtbildningFormContext(
		index,
		utbildningBegaran.franCsnUr
	)
	const { t } = useTranslation('translation', { keyPrefix: 'begaranYttrande.svar.utbildning' })

	const [isToggled, setToggleAccordion] = useState<boolean>(true)

	return (
		<CustomAccordionItem
			expanded={isToggled}
			expansionId={`svar-utbildning-accordion-${utbildningBegaran.idUtbildning}`}
			heading={utbildningBegaran?.utbildningsanordnare}
			subHeading={utbildningBegaran?.utbildningsnamn}
			onToggle={() => setToggleAccordion(!isToggled)}
			hasError={utbildningarHasErrors}
		>
			<Stack spacing={3}>
				<UtbildningKurser kurser={utbildningBegaran?.kurserUtbildning} idUtbildning={utbildningBegaran.idUtbildning} />

				<Stack spacing={3} data-testid={'svarutbildning-testid'}>
					{utbildningBegaran.ansoktKoptUtbildning && (
						<JaNejRadioButtonGroup
							valueType="boolean"
							header={t('finansierarKoptUtb')}
							name={getUtbildningPath('ooFinansierarKoptUtb')}
							disabled={isReadOnly}
						/>
					)}

					<JaNejRadioButtonGroup
						valueType="boolean"
						header={t('specifikArbetsgivare')}
						name={getUtbildningPath('specifikArbetsgivare')}
						disabled={isReadOnly}
					/>
					<JaNejArbetslosRadioButtonGroup
						header={t('tillhandahallasAvArbetsgivare')}
						name={getUtbildningPath('tillhandahallasAvArbetsgivare')}
						disabled={isReadOnly}
					/>

					<JaNejRadioButtonGroup
						valueType="boolean"
						header={t('slutfordTidigare')}
						name={getUtbildningPath('slutfordTidigare')}
						disabled={isReadOnly}
					/>

					{showVasentligSkillnad && (
						<JaNejRadioButtonGroup
							valueType="boolean"
							header={t('vasentligSkillnad')}
							name={getUtbildningPath('vasentligSkillnad')}
							disabled={isReadOnly}
						/>
					)}

					{showEjCsnUtbildning && <SvarEjCsnUtbildning isReadOnly={isReadOnly} index={index} />}
				</Stack>
			</Stack>
		</CustomAccordionItem>
	)
}

export default SvarUtbildning
