import { UserTimestampApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { formatDateTime } from '@local/src/Utils/dateFormatHelper'
import { Box,Typography } from '@mui/material'
import { isNil } from 'ramda'
import React,{ Fragment } from 'react'

interface Props {
	actionType: 'skapad' | 'sparad' | 'skickad'
	actionDone: boolean
	userTimeStamp?: UserTimestampApiModel
	headerText: string
}
const UserTimeStampInfo = ({ actionType, actionDone, userTimeStamp, headerText }: Props) => {
	const nameNotFound = isNil(userTimeStamp?.firstName) && isNil(userTimeStamp?.lastName)
	return (
		<Box>
			<Typography variant="subtitle1" data-testid={`ad-hoc-header-timestamp-${actionType}`}>
				{headerText}
			</Typography>
			{actionDone ? (
				<Fragment>
					<Typography variant="subtitle2">{formatDateTime(userTimeStamp?.lastUpdatedAt)}</Typography>
					<Typography variant="subtitle2">
						{nameNotFound ? 'Informationen hittades inte' : `${userTimeStamp?.firstName} ${userTimeStamp?.lastName}`}
					</Typography>
				</Fragment>
			) : (
				<Typography variant="subtitle2">Ej {actionType}</Typography>
			)}
		</Box>
	)
}

export default UserTimeStampInfo
