import { useCommonTranslation } from '@local/src/Hooks'
import { useDeleteAdHocYttrande } from '@local/src/Hooks/API'
import { ApiResponseModel } from '@local/src/Services/ApiResponseModel'
import DeleteIcon from '@mui/icons-material/Delete'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	adHocYttrandeId: string
	isDeletable: boolean
	setApiResponse: (response: ApiResponseModel<void>) => void
}
const AdHocYttrandeFormDeleteAction = ({ adHocYttrandeId, isDeletable, setApiResponse: setActionIsSuccess }: Props) => {
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const { t } = useTranslation('translation', { keyPrefix: 'adHocYttrande.form.buttons' })
	const { tCommon } = useCommonTranslation()
	const { deleteAdhocYttrandenMutation, apiResponse: deleteApiResponse } = useDeleteAdHocYttrande()

	useEffect(() => {
		setActionIsSuccess(deleteApiResponse)
	}, [deleteApiResponse, setActionIsSuccess])

	const handleHideDeleteModal = () => setShowDeleteModal(false)
	const handleShowDeleteModal = () => setShowDeleteModal(true)

	const handleDeleteClicked = async () => {
		handleHideDeleteModal()
		await deleteAdhocYttrandenMutation.mutateAsync({ id: adHocYttrandeId })
	}
	const title = t(isDeletable ? 'deleteModal.title' : 'deleteModal.isNotDeletableTitle')
	const description = t(isDeletable ? 'deleteModal.description' : 'deleteModal.isNotDeletableDescription')

	return (
		<Fragment>
			<Button variant="text" onClick={handleShowDeleteModal} startIcon={<DeleteIcon />} data-testid={'delete-ad-hoc-yttrande-button'}>
				{t('raderaYttrande')}
			</Button>

			<Dialog
				data-testid="delete-ad-hoc-yttrande-dialog"
				open={showDeleteModal}
				aria-labelledby="alert-delete-dialog-title"
				aria-describedby="alert-delete-dialog-description"
			>
				<DialogTitle id="alert-delete-dialog-title">{title}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-delete-dialog-description">{description}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button data-testid="dialog--cancel-button" onClick={handleHideDeleteModal} variant="text">
						{tCommon('buttons.cancel')}
					</Button>
					{isDeletable && (
						<Button data-testid="dialog--confirm-button" onClick={handleDeleteClicked} variant="text">
							{t('raderaYttrande')}
						</Button>
					)}
				</DialogActions>
			</Dialog>
		</Fragment>
	)
}

export default AdHocYttrandeFormDeleteAction
