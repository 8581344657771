import { csnBegaranYttrandeApi } from '@local/src/Services/apiEndpointHelper'
import { apiRequestTryCatchWrapper } from '@local/src/Services/apiRequestHelper'
import { getApiResponseError } from '@local/src/Services/apiResponseHelper'
import { ApiResponseModel } from '@local/src/Services/ApiResponseModel'
import { UpdateLastDateToSvaraApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'

interface CsnArendeBegaranYttrandeLastDateToSvaraCreate {
	grundansokanId: string
	idBegaran: string
	data: UpdateLastDateToSvaraApiModel
}

export const usePostLastDateToSvara = (grundansokanId: string) => {
	const queryClient = useQueryClient()
	const [apiResponse, setApiResponse] = useState<ApiResponseModel<void>>(undefined)

	const postLastDateToSvaraMutation = useMutation({
		mutationFn: (param: CsnArendeBegaranYttrandeLastDateToSvaraCreate) => {
			setApiResponse(undefined)

			return apiRequestTryCatchWrapper(
				'usePostLastDateToSvara',
				csnBegaranYttrandeApi.csnArendeBegaranYttrandeLastDateToSvaraCreate,
				grundansokanId,
				param.idBegaran,
				param.data
			)
		},
		onSuccess: async (response, param: CsnArendeBegaranYttrandeLastDateToSvaraCreate) => {
			setApiResponse(response)
			if (response.isSuccess) {
				await queryClient.invalidateQueries(['getYttrandenFromCsn'])
				await queryClient.invalidateQueries(['csnArendeBegaranYttrandeDetail', param.idBegaran])
			}
		},
		onError: (response: { message?: string }) => {
			setApiResponse(getApiResponseError(response?.message))
		},
	})

	return { postLastDateToSvaraMutation, apiResponse }
}
