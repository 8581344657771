import { RadioButtonGroup } from '@local/src/Components'
import { useCommonTranslation } from '@local/src/Hooks'
import { JaNejAvstarRadioButtonGroup } from '@local/src/Pages/Common/Fragments'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StarksFramtidaStallning as StarksFramtidaStallningEnum } from '@local/src/Services/SwaggerApi/data-contracts'

export interface Props {
	isReadOnly?: boolean
	isAdHoc?: boolean
	name: string
}

const StarksFramtidaStallning = ({ name, isReadOnly, isAdHoc = false }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'components.yttranden.labels' })
	const { tCommon } = useCommonTranslation()
	const label = t('starksFramtidaStallning')

	return isAdHoc ? (
		<RadioButtonGroup
			label={label}
			name={name}
			valueType="number"
			isReadOnly={isReadOnly}
			radios={[
				{
					label: tCommon('glossary.ja'),
					value: StarksFramtidaStallningEnum.Ja,
				},
				{
					label: tCommon('glossary.nej'),
					value: StarksFramtidaStallningEnum.Nej,
				},
			]}
		/>
	) : (
		<JaNejAvstarRadioButtonGroup name={name} header={label} disabled={isReadOnly} />
	)
}

export default StarksFramtidaStallning
