import { studiestodMedarbetareApi } from '@local/src/Services/apiEndpointHelper'
import { apiGetTryCatchWrapper } from '@local/src/Services/apiRequestHelper'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { isNotNil } from 'ramda'

const useGetSimpleStudiestod = (ansokanId?: string) => {
	const queryClient = useQueryClient()
	const queryKey = ['getSimpleStudiestod', ansokanId]
	const { data: simpleStudiestodData, ...query } = useQuery({
		queryKey,
		queryFn: () => apiGetTryCatchWrapper('useGetSimpleStudiestod', studiestodMedarbetareApi.simpleDetail, ansokanId),
		enabled: isNotNil(ansokanId),
	})
	const invalidateGetDataStudiestod = async () => await queryClient.invalidateQueries(queryKey)

	return { simpleStudiestodData, ...query, invalidateGetDataStudiestod }
}

export default useGetSimpleStudiestod
