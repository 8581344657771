import { Loader, Loading } from '@local/src/Components'
import { Box } from '@mui/material'
import { BoxProps } from '@mui/material/Box/Box'
import React from 'react'

interface Props extends BoxProps {
	headerText?: string
	children: React.ReactNode | React.ReactNode[]
	descriptionText?: string
	errorLoadingData?: boolean
	errorText?: string
	isLoading?: boolean
}
export const PageErrorWrapper = ({ errorText }: { errorText: string }) => <p data-testid="page-wrapper-error">{errorText}</p>
const PageWrapper = ({ errorLoadingData = false, descriptionText, isLoading, errorText, headerText, children, ...props }: Props) => {
	if (errorLoadingData) {
		return <PageErrorWrapper errorText={errorText || ''} />
	}

	return (
		<Box data-testid="page-wrapper" {...props}>
			{headerText && <h3>{headerText}</h3>}
			{descriptionText && <p>{descriptionText}</p>}
			<Loading
				isLoading={isLoading}
				fallback={
					<div data-testid="page-loader">
						<Loader />
					</div>
				}
			>
				{children}
			</Loading>
		</Box>
	)
}

export default PageWrapper
