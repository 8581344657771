import { PageWrapper } from '@local/src/Components/PageWrapper'
import { useGetAdHocYttrandeDetail, useGetCurrentStudiestodansokan } from '@local/src/Hooks/API'
import AdHocYttrandePage from '@local/src/Pages/AdHocYttrande/AdHocYttrandePage'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router-dom'

interface YttrandeDetailStateParam {
	adHocYttrandeId?: string
}
const AdHocYttrandeEditPage = () => {
	const pathEdit = `*/csn/ad-hoc-yttrande/redigera/:adHocYttrandeId`
	const routeMatch = useRouteMatch<YttrandeDetailStateParam>(pathEdit)

	const {
		adhocYttrandeData,
		isLoading: loadingAdHocYttrande,
		isError: errorAdHocYttrande,
		isFetched,
	} = useGetAdHocYttrandeDetail(routeMatch?.params?.adHocYttrandeId)

	const {
		studiestodAnsokan,
		isLoading: loadingStudieansokan,
		isError: errorStudieansokan,
	} = useGetCurrentStudiestodansokan(adhocYttrandeData?.studiestodMeta?.arendeId, adhocYttrandeData?.studiestodMeta?.ansokanId, isFetched)

	const isLoading = loadingAdHocYttrande || loadingStudieansokan
	const { t } = useTranslation('translation', { keyPrefix: 'adHocYttrande.errors' })
	return (
		<PageWrapper
			isLoading={isLoading}
			errorLoadingData={errorAdHocYttrande || errorStudieansokan}
			errorText={errorAdHocYttrande ? t('fetchFailedYttrande') : t('fetchFailedStudiestod')}
		>
			<AdHocYttrandePage studiestodAnsokan={studiestodAnsokan} adhocYttrandeData={adhocYttrandeData} isNewAdHoc={false} />
		</PageWrapper>
	)
}

export default AdHocYttrandeEditPage
