import { requiredValidationText } from '@local/Utils/YupHelper'
import { UtbildningSvarFormModel } from '@local/src/Pages/BegaranYttrande/Edit/Components/BegaranYttrandeSvarForm/Models/BegaranYttrandeSvarForm.types'
import { SchemaOf, boolean as yupBoolean, mixed as yupMixed, object as yupObject, string as yupString } from 'yup'
import { TillhandahallasAvArbetsgivare } from '@local/src/Services/SwaggerApi/data-contracts'

import EjCsnUtbildningSchema, { ejCsnUtbildningRequired } from './EjCsnUtbildningSchema'

export const SvarUtbildningSchema: SchemaOf<UtbildningSvarFormModel> = yupObject({
	idUtbildning: yupString().required(requiredValidationText),
	ooFinansierarKoptUtb: yupBoolean()
		.nullable()
		.when('isAnsoktKoptUtbildning', {
			is: true,
			then: (schema) => schema.required(requiredValidationText),
			otherwise: yupBoolean().undefinedOrNullSchema(),
		}),
	slutfordTidigare: yupBoolean().nullable().required(requiredValidationText),
	specifikArbetsgivare: yupBoolean().nullable().required(requiredValidationText),
	tillhandahallasAvArbetsgivare: yupMixed().oneOf<TillhandahallasAvArbetsgivare>([1, 2, 3]).required(requiredValidationText),
	vasentligSkillnad: yupBoolean()
		.nullable()
		.when('slutfordTidigare', {
			is: true,
			then: (schema) => schema.required(requiredValidationText),
			otherwise: yupBoolean().undefinedOrNullSchema(),
		}),
	ejCsnUtbildning: EjCsnUtbildningSchema.when(['isFranCsnUr', 'ooFinansierarKoptUtb'], {
		is: ejCsnUtbildningRequired,
		then: EjCsnUtbildningSchema,
		otherwise: yupObject().undefinedOrNullSchema(),
	}),
	isAnsoktKoptUtbildning: yupBoolean(),
	isFranCsnUr: yupBoolean(),
})

export const SvarUtbildningSchemaAvstar: SchemaOf<UtbildningSvarFormModel> = SvarUtbildningSchema.shape({
	slutfordTidigare: yupBoolean().nullable(),
	specifikArbetsgivare: yupBoolean().nullable(),
	tillhandahallasAvArbetsgivare: yupMixed().nullable().oneOf([null, undefined, 1, 2, 3]),
})
