import TabPanel from '@local/src/Components/Tab/TabPanel'
import { Box, Card, CardContent, CardHeader, Skeleton, Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

const LabelValueSkeleton = ({ label, value }: { label: string; value: string }) => (
	<Stack>
		<Skeleton>
			<Typography variant="caption" color="text.secondary">
				{label}
			</Typography>
		</Skeleton>
		<Skeleton>
			<Typography component="span">{value}</Typography>
		</Skeleton>
	</Stack>
)

const BeslutListItemSkeleton = () => {
	const { t } = useTranslation('translation', { keyPrefix: 'csnPortal.beslut.tabs' })

	return (
		<Card>
			<CardHeader
				title={
					<Skeleton>
						<Typography variant="h3">Beslut för: xxxxx xxxxx</Typography>
					</Skeleton>
				}
			/>

			<CardContent>
				<Stack direction="row" spacing={1} mb={4}>
					<Skeleton variant="rectangular" width={90} height={30} />
					<Skeleton variant="rectangular" width={90} height={30} />
				</Stack>

				<TabPanel value={0} index={0}>
					<Stack spacing={3}>
						<Stack direction={{ xs: 'column', sm: 'row' }}>
							<Box flex={1}>
								<LabelValueSkeleton label={t('labels.beslutstyp')} value={t('beslut.propertyValueMap.beslutstyp')} />
							</Box>

							<Box flex={1}>
								<LabelValueSkeleton label={t('labels.arligInkomst')} value={'xxxxxxxx'} />
							</Box>

							<Box flex={1}>
								<LabelValueSkeleton label={t('labels.tidpunktBeslutEllerUtbetalning')} value={'xxxx-xx-xx'} />
							</Box>
						</Stack>

						<Stack direction={{ xs: 'column', sm: 'row' }}>
							<Box flex={1}>
								<LabelValueSkeleton label={t('labels.beslutsutfall')} value={t('beslut.propertyValueMap.beslutsutfall')} />
							</Box>
							<Box flex={1}>
								<LabelValueSkeleton label={t('labels.arligInkomstMotsvarandeHeltidsarbete')} value={'xxxxxxxxxxxxx'} />
							</Box>
							<Box flex={1} />
						</Stack>
					</Stack>
					<Skeleton>
						<Typography mt={4} variant="h3">
							{t('beslut.labels.utbildningsHeader')} (xx st)
						</Typography>
					</Skeleton>
					<Skeleton>
						<Typography variant="h3">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</Typography>
					</Skeleton>

					<Skeleton>
						<Typography mt={4} variant="h3">
							{t('beslut.labels.utbildningsHeader')} (xx st)
						</Typography>
					</Skeleton>
					<Skeleton>
						<Typography variant="h3">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</Typography>
					</Skeleton>
				</TabPanel>
			</CardContent>
		</Card>
	)
}
export default BeslutListItemSkeleton
