import { KompletterandeBidragWithUserProfileMetaApiModel } from '@local/Services/SwaggerApi/data-contracts'
import { Loading } from '@local/src/Components'
import useGetCurrentBegaranKompletterandeBidrag from '@local/src/Hooks/API/useGetCurrentBegaranKompletterandeBidrag'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import React, { useState } from 'react'

import SvarKompletterandeBidragForm from './SvarKompletterandeBidragForm/SvarKompletterandeBidragForm'

interface Props {
	kompletterandeBidrag: KompletterandeBidragWithUserProfileMetaApiModel
	index: number
}

const SvarKompletterandeBidrag = ({ kompletterandeBidrag, index }: Props) => {
	const [isToggled, setToggleAccordion] = useState<boolean>(false)
	const { csnkompletterandeBidrag, isLoading } = useGetCurrentBegaranKompletterandeBidrag(
		kompletterandeBidrag.begaranKompletterandeBidrag.idBegaran,
		kompletterandeBidrag.begaranKompletterandeBidrag.grundansokanId,
		isToggled
	)

	const expansionId = `${kompletterandeBidrag?.begaranKompletterandeBidrag?.idBegaran}-svar-form`

	return (
		<Accordion
			sx={{
				border: 'none',
				boxShadow: 0,
				mb: 2,
				position: 'static',
			}}
			expanded={isToggled}
			onChange={(_, expanded) => setToggleAccordion(expanded)}
			data-testid={`svar-kompletterande-bidrag-${index}`}
		>
			<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${expansionId}-content`} id={`${expansionId}-header`}>
				<Typography variant="h6">Svara kompletterande bidrag till Csn</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Loading isLoading={isLoading}>
					<SvarKompletterandeBidragForm
						kompletterandeBidrag={kompletterandeBidrag}
						svarKompletterandeBidrag={csnkompletterandeBidrag?.svarKompletterandeBidrag}
					/>
				</Loading>
			</AccordionDetails>
		</Accordion>
	)
}

export default SvarKompletterandeBidrag
