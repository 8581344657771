import { SearchYttrandenRequestApiModel } from "@local/src/Services/SwaggerApi/data-contracts"
import { AES, enc } from 'crypto-js'

export const decryptSearchFilter = (encryptionKey: string, encryptedData: string): SearchYttrandenRequestApiModel | null => {
  try {
    const bytes = AES.decrypt(encryptedData, encryptionKey)
    const jsonString = bytes.toString(enc.Utf8)

    if (!jsonString) {
      throw new Error('Decrypted data is empty')
    }

    return JSON.parse(jsonString) as SearchYttrandenRequestApiModel
  } catch (error) {
    console.error(error)
    return null
  }
}