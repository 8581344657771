import { TextArea } from '@local/src/Components'
import { isNil } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

export interface Props {
	testId?: string
	isReadOnly?: boolean
	name: string
	orsakStarksFramtidaStallning: number
}

const OrsakTillMotivering = ({ testId, isReadOnly, name, orsakStarksFramtidaStallning }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'components.yttranden.labels' })

	if (isNil(orsakStarksFramtidaStallning)) {
		return null
	}

	return orsakStarksFramtidaStallning === 4 ? (
		<TextArea testId={testId} name={name} label={t('orsakTillMotivering')} disabled={isReadOnly} />
	) : null
}
export default OrsakTillMotivering
