import { useAdHocYttrandeFormContext } from '@local/src/Pages/AdHocYttrande/Hooks'
import { Checkbox, FormControlLabel } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface Props {
	index: number
}

const CsnUtbildningCheckbox = ({ index }: Props) => {
	const { isReadOnly, control, watch } = useAdHocYttrandeFormContext()
	const { isCsnUtbildning } = watch(`utbildningar.${index}`)

	const { t } = useTranslation('translation', { keyPrefix: 'adHocYttrande.form.utbildningar.csnCheckbox' })

	const id = `utbildningar.isCsnUtbildning-checkbox-${index}`

	return (
		<FormControlLabel
			label={t('label')}
			control={
				<Controller
					name={`utbildningar.${index}.isCsnUtbildning`}
					control={control}
					render={({ field: { onChange, ...props } }) => (
						<Checkbox
							{...props}
							onChange={(e) => onChange(!e.target.checked)}
							checked={!isCsnUtbildning}
							disabled={isReadOnly}
							id={id}
							data-testid={id}
						/>
					)}
				/>
			}
		/>
	)
}

export default CsnUtbildningCheckbox
