import { CsnContent } from '@local/src/Types/App.types'
import { useEpiContent } from '@trr/app-shell-data'
import { mergeDeepLeft } from 'ramda'

import { fallBackContent } from '../Utils/ContentHelper'

export const useEpiContentWithFallback = () => {
	const content = useEpiContent<CsnContent>()

	return mergeDeepLeft(content, fallBackContent)
}
export default useEpiContentWithFallback
