export const formatCurrencySEK = (amount: number) =>
	new Intl.NumberFormat('sv-se', {
		style: 'currency',
		currency: 'SEK',
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	})
		.format(amount)
		//eslint-disable-next-line
		.replace(/\,00([^\d])/g, '$1') // Edge bug where minimumFractionDigits is ignored
