import { useCommonTranslation } from '@local/src/Hooks'
import { BegaranYttrandeSvarFormData } from '@local/src/Pages/BegaranYttrande/Edit/Components/BegaranYttrandeSvarForm/Models/BegaranYttrandeSvarForm.types'
import { begaranYttrandeSvarSchema } from '@local/src/Pages/BegaranYttrande/Edit/Components/BegaranYttrandeSvarForm/Schemas/BegaranYttrandeSvar.schema'
import { mapToAvstarYttrandeFormModel } from '@local/src/Pages/BegaranYttrande/Edit/Components/BegaranYttrandeSvarForm/Utils/svarYttrandeHelper'
import { usePostSvarBegaranYttrande } from '@local/src/Pages/BegaranYttrande/Hooks/usePostSvarBegaranYttrande'
import { ApiResponseModel } from '@local/src/Services/ApiResponseModel'
import { KundTillhorTrr, YttrandeApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormHelperText, Stack } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	defaultFormData: BegaranYttrandeSvarFormData
	setApiResponse: React.Dispatch<React.SetStateAction<ApiResponseModel<void>>>
}

const SvaraAvstarAction = ({ defaultFormData, setApiResponse }: Props) => {
	const { tCommon } = useCommonTranslation()
	const { t } = useTranslation('translation', { keyPrefix: 'begaranYttrande.tillhor' })

	const [openDialog, setOpenDialog] = useState(false)
	const { postSvarBegaranYttrandeMutation, apiResponse } = usePostSvarBegaranYttrande()
	const formData: BegaranYttrandeSvarFormData = {
		...defaultFormData,
		shouldSendToCsnFormBool: true,
		kundTillhorTrr: KundTillhorTrr.KundTillhorTrr,
		yttrande: mapToAvstarYttrandeFormModel(defaultFormData.yttrande),
	}

	const formDataIsValid = begaranYttrandeSvarSchema.isValidSync(formData)

	const handleOnOpenDialog = () => setOpenDialog(true)

	const handleOnCloseDialog = () => setOpenDialog(false)

	const onSubmit = async () => {
		setOpenDialog(false)

		await postSvarBegaranYttrandeMutation.mutateAsync({
			grundansokanId: formData.grundansokanId,
			begaranId: formData.idBegaran,
			shouldSendToCsn: formData.shouldSendToCsnFormBool,
			data: {
				ansokanId: formData.ansokanId,
				idBegaran: formData.idBegaran,
				grundansokanId: formData.grundansokanId,
				kundTillhorTrr: formData.kundTillhorTrr,
				yttrande: formData.yttrande as YttrandeApiModel,
			},
		})
	}

	useEffect(() => {
		if (apiResponse) setApiResponse(apiResponse)
	}, [apiResponse, setApiResponse])

	return (
		<Fragment>
			<Button
				variant="contained"
				onClick={handleOnOpenDialog}
				data-testid="open-svara-avstar-dialog-button"
				disabled={postSvarBegaranYttrandeMutation.isLoading}
			>
				{t('buttons.avstar')}
			</Button>
			<Dialog fullWidth open={openDialog} data-testid="begaran-yttrande-svara-avstar-dialog" onClose={handleOnCloseDialog}>
				<DialogTitle>{t('avstarDialog.title')}</DialogTitle>
				<DialogContent>
					<Stack spacing={2}>
						<DialogContentText>{t('avstarDialog.description')}</DialogContentText>
						{!formDataIsValid && (
							<FormHelperText data-testid="form-invalid-helper-text" error>
								{t('invalidFormDataText')}
							</FormHelperText>
						)}
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={handleOnCloseDialog} data-testid="dialog--cancel-button">
						{tCommon('buttons.cancel')}
					</Button>
					<Button disabled={!formDataIsValid} variant="contained" onClick={onSubmit} data-testid="dialog--confirm-button">
						{tCommon('buttons.confirm')}
					</Button>
				</DialogActions>
			</Dialog>
		</Fragment>
	)
}

export default SvaraAvstarAction
