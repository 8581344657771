import {
	BegaranCsnYttrandeListItemApiModel,
	SvarBegaranYttrandeApiModel,
	UtbildningSvarYttrandeApiModel,
	YttrandeApiModel,
} from '@local/Services/SwaggerApi/data-contracts'

export interface BegaranYttrandeSvarFormData extends Omit<SvarBegaranYttrandeApiModel, 'yttrande'> {
	yttrande?: YttrandeFormModel
	shouldSendToCsnFormBool: boolean
}

export interface YttrandeFormModel extends Partial<YttrandeApiModel> {
	utbildningar?: UtbildningSvarFormModel[]
}

export interface UtbildningSvarFormModel extends UtbildningSvarYttrandeApiModel {
	isAnsoktKoptUtbildning?: boolean
	isFranCsnUr?: boolean
}

export const getDefaultFormDataFromCsnYttrandeApiModel = (
	begaranYttrande: BegaranCsnYttrandeListItemApiModel
): BegaranYttrandeSvarFormData => ({
	kundTillhorTrr: begaranYttrande?.ansokanId ? 1 : null,
	idBegaran: begaranYttrande.idBegaran,
	grundansokanId: begaranYttrande.grundansokanId,
	ansokanId: begaranYttrande?.ansokanId,
	shouldSendToCsnFormBool: false,
	yttrande: {
		datumAnsokanOo: undefined,
		kompletterandeBidrag: undefined,
		orsakMotivering: undefined,
		orsakStarksFramtidaStallning: null,
		starksFramtidaStallning: null,
		utbildningar: begaranYttrande.utbildningar.map(
			(u) =>
				({
					idUtbildning: u.idUtbildning,
					isAnsoktKoptUtbildning: u.ansoktKoptUtbildning,
					isFranCsnUr: u.franCsnUr,
					koptUtbildning: u.ansoktKoptUtbildning,
					specifikArbetsgivare: undefined,
					tillhandahallasAvArbetsgivare: undefined,
					slutfordTidigare: undefined,
					vasentligSkillnad: undefined,
					ejCsnUtbildning: undefined,
					ooFinansierarKoptUtb: undefined,
				}) as UtbildningSvarFormModel
		),
	},
})
