import { useGetUtbildningarFromCsn } from '@local/src/Hooks/API'
import OspecificeradeKurser from '@local/src/Pages/AdHocYttrande/Components/AdHocYttrandeForm/Components/Utbildningar/Components/OspecificeradeKurser/OspecificeradeKurser'
import { useAdHocYttrandeFormContext } from '@local/src/Pages/AdHocYttrande/Hooks'
import JaNejRadioButtonGroup from '@local/src/Pages/Common/Fragments/JaNejRadioButtonGroup'
import { AdHocYttrandeCsnUtbildningApiModel, CsnRegisterUtbildningApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { Autocomplete, CircularProgress, FormControl, Stack, TextField } from '@mui/material'
import { isNil, isNotNil } from 'ramda'
import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	index: number
}

const CsnUtbildningForm = ({ index }: Props) => {
	const {
		formState: { errors },
		setValue,
		trigger,
		watch,
		isReadOnly,
	} = useAdHocYttrandeFormContext()

	const [shouldSearch, setShouldSearch] = useState(false)
	const [inputValue, setInputValue] = useState('')
	const { utbildningar, isFetching } = useGetUtbildningarFromCsn({ searchTerm: inputValue }, shouldSearch)

	const { csnUtbildning } = watch(`utbildningar.${index}`)
	const currentErrors = errors?.utbildningar?.[index]?.csnUtbildning

	const [options, setOptions] = useState<readonly CsnRegisterUtbildningApiModel[]>([])
	const [selectedOption, setSelectedOption] = useState<CsnRegisterUtbildningApiModel | null>(
		csnUtbildning
			? {
					ort: csnUtbildning?.utbildningsort,
					utbildningsort: csnUtbildning?.utbildningsort,
					utbildningskod: csnUtbildning?.utbildningskod,
					skolkod: csnUtbildning?.skolkod,
					skolnamn: csnUtbildning?.skolnamn,
					utbildningsnamn: csnUtbildning?.utbildningsnamn,
					ospecificeradUtbildning: false,
				}
			: null
	)

	useEffect(() => {
		setOptions(utbildningar)
	}, [utbildningar])

	const getSelectedOptionLabel = (option: CsnRegisterUtbildningApiModel) =>
		`${option?.utbildningskod} - ${option?.utbildningsnamn} - ${option?.skolnamn} - ${option?.utbildningsort}`

	const handleOnInputChange = (_: React.SyntheticEvent<Element, Event>, newInputValue: string) => {
		const isOptionAlreadySelected = getSelectedOptionLabel(selectedOption) === newInputValue

		if (!isOptionAlreadySelected && newInputValue.length > 1) {
			setInputValue(newInputValue)
			setShouldSearch(true)
		}
	}

	const handleOnChange = async (_: React.SyntheticEvent<Element, Event>, option: CsnRegisterUtbildningApiModel | null) => {
		setShouldSearch(false)
		setSelectedOption(option)
		const utbildningskod = option?.utbildningskod?.toString()
		const selectedUtbildning = options.find((u) => u?.utbildningskod === utbildningskod)

		const newCsnUtbildning: AdHocYttrandeCsnUtbildningApiModel = isNil(selectedUtbildning)
			? undefined
			: { ...csnUtbildning, ...selectedUtbildning }

		setValue(`utbildningar.${index}.csnUtbildning`, newCsnUtbildning)
		setValue(`utbildningar.${index}.csnUtbildning.kurserUtbildning`, [])
		await trigger(`utbildningar.${index}.csnUtbildning.utbildningskod`)
	}

	const { t } = useTranslation('translation', { keyPrefix: 'adHocYttrande.form.utbildningar.form.csn' })

	return (
		<Stack spacing={3}>
			<FormControl fullWidth>
				<Autocomplete
					onInputChange={handleOnInputChange}
					onChange={handleOnChange}
					filterOptions={(x) => x}
					filterSelectedOptions
					isOptionEqualToValue={(option, value) => option.utbildningskod === value.utbildningskod}
					getOptionLabel={getSelectedOptionLabel}
					options={options}
					value={selectedOption}
					loading={isFetching}
					disabled={isReadOnly}
					slotProps={{
						popper: {
							id: `utbildningar.${index}.csnUtbildningDropdown-select`,
						},
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							label={csnUtbildning?.utbildningskod ? t('label') : ''}
							placeholder={t('placeholder')}
							helperText={currentErrors?.utbildningskod?.message}
							error={isNotNil(currentErrors?.utbildningskod)}
							disabled={isReadOnly}
							inputProps={{
								...params.inputProps,
								'data-testid': `utbildningar[${index}].csnUtbildningDropdown`,
							}}
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<Fragment>
										{isFetching ? <CircularProgress color="inherit" size={20} /> : null}
										{params.InputProps.endAdornment}
									</Fragment>
								),
							}}
						/>
					)}
				/>
			</FormControl>

			{csnUtbildning?.ospecificeradUtbildning && <OspecificeradeKurser utbildningIndex={index} />}

			<JaNejRadioButtonGroup
				valueType="boolean"
				header={t('isUppdragsUtbildningLabel')}
				name={`utbildningar[${index}].csnUtbildning.uppdragsutbildning`}
				disabled={isReadOnly}
			/>
		</Stack>
	)
}

export default CsnUtbildningForm
