import EmptyIllustration from '@local/src/Components/EmptyResult/EmptyIllustration'
import { Container, Stack, Typography } from '@mui/material'
import React from 'react'

interface Props {
	heading: string
	description: string
	isSmall?: boolean
}
const EmptyResult = ({ heading, description, isSmall }: Props) => (
	<Container data-testid="empty-result" maxWidth="xs">
		<Stack spacing={1} textAlign="center" m={2}>
			<EmptyIllustration style={{ maxWidth: isSmall ? 185 : 385 }} />
			<Typography variant={isSmall ? 'h5' : 'h4'}>{heading}</Typography>
			<Typography>{description}</Typography>
		</Stack>
	</Container>
)

export default EmptyResult
