import { useDebouncedEffect } from '@local/src/Hooks'
import YttrandeFacetStatus from '@local/src/Pages/CsnPortal/Yttranden/Components/YttrandeFilterCard/Components/YttrandeFacetStatus'
import YttrandeFacetType from '@local/src/Pages/CsnPortal/Yttranden/Components/YttrandeFilterCard/Components/YttrandeFacetType'
import { SearchYttrandenRequestApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { Card, CardContent, Grid, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	searchFilter: SearchYttrandenRequestApiModel
	setSearchFilterWithPage: (searchFilter: SearchYttrandenRequestApiModel) => void
}

const YttrandeFilterCard = ({ searchFilter, setSearchFilterWithPage }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'csnPortal.yttranden.yttrandeFilterCard' })
	const [ssnFilter, setSsnFilter] = useState(searchFilter.personnummer || '')
	useDebouncedEffect(
		() => {
			setSearchFilterWithPage({ ...searchFilter, personnummer: ssnFilter.replace('-', '') })
		},
		300,
		[ssnFilter]
	)

	const handleSsnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSsnFilter(event.target.value)
	}

	return (
		<Card>
			<CardContent>
				<Grid container spacing={3} direction={{ xs: 'column', sm: 'row' }}>
					<Grid item xs={6}>
						<YttrandeFacetType searchFilter={searchFilter} setSearchFilterWithPage={setSearchFilterWithPage} />
					</Grid>
					<Grid item xs={6}>
						<YttrandeFacetStatus searchFilter={searchFilter} setSearchFilterWithPage={setSearchFilterWithPage} />
					</Grid>
					<Grid item xs={4}>
						<TextField
							label={t('ssnFilterInputLabel')}
							fullWidth
							inputProps={{
								'data-testid': 'filter-personnummer-input',
							}}
							value={ssnFilter}
							onChange={handleSsnChange}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	)
}
export default YttrandeFilterCard
