import { ApiResponseModel } from '@local/src/Services/ApiResponseModel'
import { UpdateAdHocYttrandeApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { csnAdHocYttrandeApi } from '@local/src/Services/apiEndpointHelper'
import { apiRequestTryCatchWrapper } from '@local/src/Services/apiRequestHelper'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { getApiResponseError } from '@local/src/Services/apiResponseHelper'

import { CreateCsnYttrandeFormModel } from '../Models/AdHocYttrande.types'

interface UpdateCsnAdHocYttrande {
	formData: CreateCsnYttrandeFormModel
}

const useUpdateCsnAdHocYttrande = (adHocYttrandeId: string) => {
	const queryClient = useQueryClient()
	const [apiResponse, setApiResponse] = useState<ApiResponseModel<void>>(undefined)

	const mapFormToUpdateAdHocYttrandeApiModel = (data: CreateCsnYttrandeFormModel): UpdateAdHocYttrandeApiModel => ({
		datumAnsokanOo: data.datumAnsokanOo,
		kompletterandeBidrag: data.kompletterandeBidrag,
		malMedStudier: data.malMedStudier,
		orsakMotivering: data.orsakMotivering,
		orsakStarksFramtidaStallning: data.orsakStarksFramtidaStallning,
		starksFramtidaStallning: data.starksFramtidaStallning,
		utbildningar: data.utbildningar,
		begaranId: data.begaranId,
	})

	const postUpdateCsnAdHocYttrandeMutation = useMutation({
		mutationFn: (param: UpdateCsnAdHocYttrande) => {
			setApiResponse(undefined)
			const apiModel = mapFormToUpdateAdHocYttrandeApiModel(param.formData)
			const queryData = { adHocYttrandeId: adHocYttrandeId, shouldSendToCsn: param.formData.shouldSendToCsnFormBool }

			return apiRequestTryCatchWrapper('useUpdateCsnAdHocYttrande', csnAdHocYttrandeApi.csnAdHocYttrandeUpdate, queryData, apiModel)
		},
		onSuccess: async (response) => {
			setApiResponse(response)
			if (response.isSuccess) {
				await queryClient.invalidateQueries()
			}
		},
		onError: (response: { message?: string }) => {
			setApiResponse(getApiResponseError(response?.message))
		},
	})
	return { postUpdateCsnAdHocYttrandeMutation, apiResponse }
}

export default useUpdateCsnAdHocYttrande
