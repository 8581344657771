import { SelectOption } from '@local/src/Components/FormComponents/Dropdown/Dropdown.types'
import { SearchYttrandenRequestApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { Box, MenuItem, Select, Typography } from '@mui/material'
import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	searchFilter: SearchYttrandenRequestApiModel
	setSearchFilter: Dispatch<SetStateAction<SearchYttrandenRequestApiModel>>
}

const YttrandeSortSelect = ({ searchFilter, setSearchFilter }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'csnPortal.yttranden.yttrandenTableSorting' })

	const options: SelectOption[] = t('yttrandeSortSelectOptions', { returnObjects: true })

	return (
		<Box>
			<Select
				variant="standard"
				disableUnderline
				onChange={(e) => setSearchFilter({ ...searchFilter, orderBy: e.target.value })}
				value={searchFilter?.orderBy}
				data-testid={`yttrande-sort-select`}
			>
				{options?.map((option) => (
					<MenuItem key={`${option.value}`} value={option.value}>
						<Typography variant="body2" paddingLeft={1} paddingTop="2px">
							{option.label}
						</Typography>
					</MenuItem>
				))}
			</Select>
		</Box>
	)
}
export default YttrandeSortSelect
