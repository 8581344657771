import { GoBackButton } from '@local/src/Components'
import SystemFeedback from '@local/src/Components/SystemFeedback/SystemFeedback'
import { useGetArende } from '@local/src/Hooks/API'
import { CsnAdHocYttrandeApiModel, StudiestodApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { isInvalidDate } from '@local/src/Utils/dateFormatHelper'
import formatPlanePersonnummer from '@local/src/Utils/formatPlanePersonnummer'
import { Box, Grid, Typography } from '@mui/material'
import { not } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { AdHocYttrandeForm, AdHocYttrandeHeader, AdHocYttrandeInfo } from './Components'

interface Props {
	studiestodAnsokan: StudiestodApiModel
	adhocYttrandeData?: CsnAdHocYttrandeApiModel
	isNewAdHoc: boolean
}

const AdHocYttrandePage = ({ studiestodAnsokan, adhocYttrandeData, isNewAdHoc }: Props) => {
	const isReadOnly = adhocYttrandeData?.shouldSendToCsn
	const notSentToCsn = not(adhocYttrandeData?.shouldSendToCsn)

	const { t } = useTranslation('translation', { keyPrefix: 'adHocYttrande.errors' })

	const { ansokanKomplettDatum, isFetched: arendeFetched } = useGetArende(studiestodAnsokan?.arendeId, notSentToCsn)
	const currentTrrAnsokanDatum = notSentToCsn ? ansokanKomplettDatum : adhocYttrandeData?.yttrande?.datumAnsokanOo
	const hasInvalidAnsokanKomplettDatum = arendeFetched && notSentToCsn && isInvalidDate(ansokanKomplettDatum)

	return (
		<Grid container spacing={3} maxWidth="md">
			<Grid item xs={12}>
				<AdHocYttrandeHeader
					adHocYttrandeState={adhocYttrandeData?.status ?? 0}
					createdBy={adhocYttrandeData?.createdBy}
					savedBy={adhocYttrandeData?.lastUpdatedBy}
					sentToCsnBy={adhocYttrandeData?.sentToCsnBy}
				/>
			</Grid>
			<Grid item xs={12}>
				<AdHocYttrandeInfo
					ansokanId={studiestodAnsokan?.id}
					clientName={`${studiestodAnsokan?.klient?.firstName} ${studiestodAnsokan?.klient?.lastName}`}
					clientSsn={formatPlanePersonnummer(studiestodAnsokan?.klient?.personnummer)}
					trrAnsokanDatum={currentTrrAnsokanDatum}
				/>
			</Grid>
			<Grid item xs={12}>
				{hasInvalidAnsokanKomplettDatum ? (
					<SystemFeedback
						testSelector={'trr-ansokan-datum-error'}
						hideCloseButton
						type="warning"
						content={
							<Box>
								<Typography variant="h6">{t('teknisktFel')}:</Typography>
								<Typography>{t('trrAnsokanDatumTeknisktFel')}</Typography>
							</Box>
						}
					/>
				) : (
					<AdHocYttrandeForm
						ansokanId={studiestodAnsokan?.id}
						personnummer={studiestodAnsokan?.klient?.personnummer}
						arendeId={studiestodAnsokan?.arendeId}
						trrAnsokanDatum={currentTrrAnsokanDatum}
						adhocYttrandeData={adhocYttrandeData?.yttrande}
						isNewAdHoc={isNewAdHoc}
						isReadOnly={isReadOnly}
					/>
				)}
			</Grid>
			<Grid item xs={12}>
				<GoBackButton />
			</Grid>
		</Grid>
	)
}
export default AdHocYttrandePage
