import { removeDuplicateSlashes } from '@local/src/Utils/urlHelper'
import { Authentication, getIdToken } from '@trr/app-shell-data'

import getConfig from '../Utils/getConfig'

import { CsnAdHocYttrande } from './SwaggerApi/CsnAdHocYttrande'
import { CsnArende } from './SwaggerApi/CsnArende'
import { CsnArendeBegaran } from './SwaggerApi/CsnArendeBegaran'
import { CsnArendeBeslut } from './SwaggerApi/CsnArendeBeslut'
import { CsnArendeKompletterandeBidrag } from './SwaggerApi/CsnArendeKompletterandeBidrag'
import { CsnRegisterUtbildningar } from './SwaggerApi/CsnRegisterUtbildningar'
import { ApiConfig } from './SwaggerApi/http-client'
import { Studiestod } from './SwaggerApi/Studiestod'
import { StudiestodMedarbetare } from './SwaggerApi/StudiestodMedarbetare'
import { Kommentar } from './SwaggerApi/Kommentar'

export const parseJwt = () => {
	const id_token = getIdToken()
	try {
		const tokenBody = id_token.split('.')[1]
		const payload = JSON.parse(atob(tokenBody)) as Authentication
		return payload
	} catch (e) {
		console.log(e)
		return null
	}
}
export const getAuthHeaders = (): HeadersInit => {
	const id_token = getIdToken()
	return {
		Authorization: `Bearer ${id_token}`,
		'Accept-Language': 'sv',
	}
}

export const apiConfig: ApiConfig = {
	baseUrl: removeDuplicateSlashes(`${getConfig().API_URL}/api`),
}

export const getBaseRequestParams = () => ({
	headers: getAuthHeaders(),
	format: 'json',
})

export const csnAdHocYttrandeApi = new CsnAdHocYttrande(apiConfig)
export const csnUtbildningarApi = new CsnRegisterUtbildningar(apiConfig)
export const studiestodMedarbetareApi = new StudiestodMedarbetare(apiConfig)
export const studiestodApi = new Studiestod(apiConfig)
export const csnArendeApi = new CsnArende(apiConfig)
export const csnBegaranYttrandeApi = new CsnArendeBegaran(apiConfig)
export const csnBeslutApi = new CsnArendeBeslut(apiConfig)
export const csnKompletterandeBidragApi = new CsnArendeKompletterandeBidrag(apiConfig)
export const kommentarApi = new Kommentar(apiConfig)
