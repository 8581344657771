/* eslint-disable jsx-a11y/click-events-have-key-events */
import { CsnRegisterUtbildningApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { styled } from '@mui/material/styles'
import { flexRender, HeaderGroup, RowModel } from '@tanstack/react-table'
import React from 'react'

const StyledTable = styled('table')({
	width: '100%',
})

const StyledTh = styled('th')(({ theme }) => ({
	textAlign: 'left',
	padding: theme.spacing(1),
	':hover': {
		cursor: 'pointer',
		color: theme.palette.primary.contrastText,
		backgroundColor: theme.palette.primary.main,
		borderRadius: theme.shape.borderRadius,
	},
}))

const StyledTd = styled('td')(({ theme }) => ({
	textAlign: 'left',
	padding: theme.spacing(1),
	':last-child div': {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: 'inline-block',
		whiteSpace: 'nowrap',
		maxWidth: '200px',
	},
}))

export const TableHead = ({ getHeaderGroups }: { getHeaderGroups: () => HeaderGroup<CsnRegisterUtbildningApiModel>[] }) => {
	const getSortDirLabel = (dir: string) => {
		const dirs: Record<string, string> = {
			asc: ' ▲',
			desc: ' ▼',
		}
		return dir ? dirs[dir] : null
	}

	return (
		<thead>
			{getHeaderGroups().map(({ headers, id }) => (
				<tr key={id}>
					{headers.map(({ id, colSpan, column, getContext }) => (
						<StyledTh key={id} colSpan={colSpan}>
							<div tabIndex={0} role="button" onClick={column.getToggleSortingHandler()}>
								{flexRender(column.columnDef.header, getContext())}
								{getSortDirLabel((column.getIsSorted() as string) ?? null)}
							</div>
						</StyledTh>
					))}
				</tr>
			))}
		</thead>
	)
}

const TableBody = ({ getRowModel }: { getRowModel: () => RowModel<CsnRegisterUtbildningApiModel> }) => (
	<tbody>
		{getRowModel().rows.map((row) => (
			<tr key={row.id}>
				{row.getVisibleCells().map((cell) => (
					// eslint-disable-next-line @typescript-eslint/no-base-to-string
					<StyledTd title={typeof cell.renderValue() === 'object' ? '' : cell.renderValue()?.toString()} key={cell.id}>
						{flexRender(cell.column.columnDef.cell, cell.getContext())}
					</StyledTd>
				))}
			</tr>
		))}
	</tbody>
)

export default {
	Container: StyledTable,
	Head: TableHead,
	Body: TableBody,
}
