import { ApiResponseModel } from '@local/src/Services/ApiResponseModel'
import { SvarKompletterandeBidragApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { csnKompletterandeBidragApi } from '@local/src/Services/apiEndpointHelper'
import { apiRequestTryCatchWrapper } from '@local/src/Services/apiRequestHelper'
import { getApiResponseError } from '@local/src/Services/apiResponseHelper'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'

interface PostSvaraKompletterandeBidrag {
	grundansokanId: string
	begaranId: string
	data: SvarKompletterandeBidragApiModel
}

export const usePostSvaraKompletterandeBidrag = () => {
	const queryClient = useQueryClient()
	const [apiResponse, setApiResponse] = useState<ApiResponseModel<void>>(undefined)

	const postSvaraKompletterandeBidragMutation = useMutation({
		mutationFn: (param: PostSvaraKompletterandeBidrag) => {
			setApiResponse(undefined)

			return apiRequestTryCatchWrapper(
				'usePostSvaraKompletterandeBidrag',
				csnKompletterandeBidragApi.csnArendeKompletterandeBidragSvarCreate,
				param.grundansokanId,
				param.begaranId,
				param.data
			)
		},
		onSuccess: async (response) => {
			setApiResponse(response)
			if (response.isSuccess) {
				await queryClient.invalidateQueries()
			}
		},
		onError: (response: { message?: string }) => {
			setApiResponse(getApiResponseError(response?.message))
		},
	})

	return {
		postSvaraKompletterandeBidragMutation,
		apiResponse,
	}
}
