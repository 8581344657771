import { yupResolver } from '@hookform/resolvers/yup'
import { useScrollToError } from '@local/src/Hooks'
import {
	CsnBeslutsperiodKompletterandeBidragApiModel,
	KompletterandeBidragWithUserProfileMetaApiModel,
	SvarKompletterandeBidragApiModel,
	SvarPeriodKompletterandeBidragApiModel,
} from '@local/src/Services/SwaggerApi/data-contracts'
import { useForm } from 'react-hook-form'

import { trrSvarKompletterandeBidragToCsnSchema } from '../Schema/TrrSvarKompletterandeBidragToCsn.schema'

type KompletterandeBidrag = KompletterandeBidragWithUserProfileMetaApiModel
type SvarKompletterandeBidrag = SvarKompletterandeBidragApiModel
type Period = CsnBeslutsperiodKompletterandeBidragApiModel
type PeriodSvar = SvarPeriodKompletterandeBidragApiModel

const kompletterandeBidragsbeloppMap = (period: Period): PeriodSvar => ({
	fromVecka: period?.fromVecka,
	tomVecka: period?.tomVecka,
	kompletterandeBidragsbelopp100: undefined,
	kompletterandeBidragsbelopp75: undefined,
	kompletterandeBidragsbelopp60: undefined,
	kompletterandeBidragsbelopp50: undefined,
	kompletterandeBidragsbelopp40: undefined,
	kompletterandeBidragsbelopp20: undefined,
})

const useSvarKompletterandeBidragForm = (
	kompletterandeBidrag: KompletterandeBidrag,
	svarKompletterandeBidrag: SvarKompletterandeBidrag
) => {
	const hasPerioder = Number(svarKompletterandeBidrag?.perioder?.length) > 0
	const defaultFormData = {
		idBegaran: kompletterandeBidrag.begaranKompletterandeBidrag.idBegaran,
		perioder: hasPerioder
			? svarKompletterandeBidrag?.perioder
			: kompletterandeBidrag.begaranKompletterandeBidrag.beslutsperioder.map((period) => kompletterandeBidragsbeloppMap(period)),
	}
	const formMethods = useForm<SvarKompletterandeBidragApiModel>({
		values: defaultFormData,
		resolver: yupResolver(trrSvarKompletterandeBidragToCsnSchema),
		mode: 'all',
	})
	const {
		formState: { errors, isSubmitted },
	} = formMethods

	useScrollToError(errors, isSubmitted, 200)

	return formMethods
}

export default useSvarKompletterandeBidragForm
