import SubmitResponse from '@local/src/Components/SubmitResponse/SubmitResponse'
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControlLabel,
} from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { UpdateRegisterFenixRequest, usePeriodIsRegisteredInFenix } from './Hooks/usePeriodIsRegisteredInFenix'

interface Props {
	isRegisteredInFenix: boolean
	periodId: string
	grundansokanId: string
}

const RegisterPeriodFenixCheckbox = ({ isRegisteredInFenix, periodId, grundansokanId }: Props) => {
	const [showConfirmModal, setShowConfirmModal] = useState(false)
	const { t } = useTranslation('translation', { keyPrefix: 'csnPortal.beslut.tabs.beslut.registerPeriodFenix' })

	const { postPeriodIsRegisteredInFenixMutation, apiResponse } = usePeriodIsRegisteredInFenix()

	const onConfirm = async () => {
		setShowConfirmModal(false)
		const request: UpdateRegisterFenixRequest = {
			grundansokanId,
			periodId,
			isRegisteredInFenix: !isRegisteredInFenix,
		}
		await postPeriodIsRegisteredInFenixMutation.mutateAsync(request)
	}

	const handleCloseModal = () => setShowConfirmModal(false)
	const handleOpenModal = () => setShowConfirmModal(true)

	return (
		<Box flex={1}>
			<FormControlLabel
				control={
					<Checkbox
						data-testid="register-period-fenix-checkbox"
						id={`${grundansokanId}-${periodId}`}
						checked={isRegisteredInFenix}
						onChange={handleOpenModal}
						color="primary"
					/>
				}
				label={t('checkboxLabel')}
			/>

			<Dialog open={showConfirmModal} data-testid="register-period-fenix-checkbox-confirm-modal" id="test" onClose={handleCloseModal}>
				<DialogTitle>{t('dialog.title')}</DialogTitle>
				<DialogContent>
					<DialogContentText>{t('dialog.description')}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={handleCloseModal} data-testid="dialog--cancel-button">
						{t('dialog.cancelButton')}
					</Button>
					<Button variant="contained" onClick={onConfirm} data-testid="dialog--confirm-button">
						{t('dialog.confirmButton')}
					</Button>
				</DialogActions>
			</Dialog>

			<SubmitResponse apiResponse={apiResponse} />
		</Box>
	)
}

export default RegisterPeriodFenixCheckbox
