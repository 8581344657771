import { Stack, Typography } from '@mui/material'
import React, { ReactNode } from 'react'

interface Props {
	label: string
	value: ReactNode
}

const LabelValue = ({ label, value }: Props) => (
	<Stack data-testid={`labelValue-${typeof value === 'object' ? '' : value?.toString()}`}>
		<Typography variant="caption" color="text.secondary">
			{label}
		</Typography>
		<Typography component="span">{value}</Typography>
	</Stack>
)

export default LabelValue
