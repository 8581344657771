import { StudieansokanLink, LabelValue } from '@local/src/Components'
import { useCommonTranslation } from '@local/src/Hooks'
import { YttrandeStatusChip } from '@local/src/Pages/BegaranYttrande/Components/YttrandeInfo/Components/YttrandeFranCsnInfo/Components'
import { SearchYttrandenStatus, SearchYttrandenType, YttrandeSearchIndexApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { formatDate } from '@local/src/Utils/dateFormatHelper'
import { Box, Card, CardContent, Grid2 as Grid, Link, List, ListItem, ListItemText, Stack, Typography } from '@mui/material'
import { useIsFeatureEnabled } from '@trr/app-shell-data'
import { isNotNil } from 'ramda'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { YttrandeCardKommentar } from './Components'

interface Props {
	yttrande: YttrandeSearchIndexApiModel
}

const YttrandeCard = ({ yttrande }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'csnPortal.yttranden.yttrandeCard' })
	const { tCommon } = useCommonTranslation()

	const utbildningarMaxIndex = yttrande.utbildningar.length - 1
	const isAdhocYttrande = yttrande.type === SearchYttrandenType.AdHocYttranden
	const isBegaranYttrande = yttrande.type === SearchYttrandenType.BegaranYttranden

	const isSkickadTillCsn = yttrande.status === SearchYttrandenStatus.SkickadTillCsn
	const isBesvaradWithAdHoc = yttrande.status === SearchYttrandenStatus.BesvaradWithAdhocYttrande

	const getDateText = () => {
		const sentToCsnText = t('dateText.sentToCsn')
		const begaranText = t('dateText.begaranReceivedAt')
		const adHocText = t('dateText.adHocCreated')

		if (isSkickadTillCsn || isBesvaradWithAdHoc) {
			return `${sentToCsnText}: ${yttrande.sentToCsnAt ? formatDate(yttrande?.sentToCsnAt) : 'Pågående'}`
		}

		const createdAtFormatted = formatDate(yttrande?.createdAt)
		return isBegaranYttrande ? `${begaranText}: ${createdAtFormatted}` : `${adHocText}: ${createdAtFormatted}`
	}

	const getYttrandeLink = () => {
		if (isAdhocYttrande) return `/medarbetare/csn/ad-hoc-yttrande/redigera/${yttrande.id}`
		const hasKoppling = isNotNil(yttrande?.studieansokanInfo?.arendeId)
		if (hasKoppling) return `/medarbetare/csn/begaran-yttrande/redigera/${yttrande.id}/arende/${yttrande?.studieansokanInfo?.arendeId}`

		return `/medarbetare/csn/begaran-yttrande/redigera-tillhor/${yttrande.id}`
	}

	const isKommentarFeatureEnabled = useIsFeatureEnabled('CSN-Frontend_begaranYttrandeKommentar_temp_241209')

	return (
		<Card>
			<CardContent>
				<Stack spacing={4}>
					<Stack direction={'row'} justifyContent="space-between">
						<Stack spacing={2}>
							<Box data-testid={`yttrande-type-${yttrande.id}`}>
								<Typography variant="h5">{t('yttrandeType', { context: yttrande.type })}</Typography>
								<Typography variant="subtitle2" data-testid={`createdAt-${yttrande.id}`}>
									{getDateText()}
								</Typography>
							</Box>
							<Link target="_self" rel="noopener noreferrer" href={getYttrandeLink()} data-testid={`yttrande-details-link-${yttrande.id}`}>
								<Typography>{t('yttrandeDetailsLinkText')}</Typography>
							</Link>
						</Stack>
						<YttrandeStatusChip status={yttrande.status} />
					</Stack>
					{isBegaranYttrande && (
						<Stack spacing={2}>
							<Typography fontWeight="bold">
								{t('svaraCsnSenast')}: {formatDate(yttrande.lastDateToSvara)}
							</Typography>

							{isKommentarFeatureEnabled && <YttrandeCardKommentar kommentar={yttrande.kommentar} />}
						</Stack>
					)}
					<Grid container spacing={2}>
						<Grid size={{ xs: 4 }}>
							<LabelValue label={tCommon('glossary.namn')} value={yttrande.userInfo.kundnamn} />
						</Grid>
						<Grid size={{ xs: 4 }}>
							<LabelValue label={tCommon('glossary.personnummer')} value={yttrande.userInfo.personnummer} />
						</Grid>
						<Grid size={{ xs: 4 }}>
							<LabelValue
								label={t('studiestodansokanNamnLabel')}
								value={
									<StudieansokanLink
										ansokanNamn={yttrande?.studieansokanInfo?.ansokanNamn}
										ansokanId={yttrande?.studieansokanInfo?.ansokanId}
										arendeId={yttrande?.studieansokanInfo?.arendeId}
										studiestodNummer={yttrande?.studieansokanInfo?.studiestodNummer}
									/>
								}
							/>
						</Grid>

						{isBegaranYttrande && (
							<Fragment>
								<Grid size={{ xs: 4 }} />
								<Grid size={{ xs: 4 }}>
									<LabelValue
										label={t('arbetsgivareOrgNummerLabel')}
										value={yttrande.arbetsgivare.map(({ organisationsnummer }) => (
											<Fragment key={organisationsnummer}>{organisationsnummer}</Fragment>
										))}
									/>
								</Grid>
								<Grid size={{ xs: 4 }}>
									<LabelValue
										label={tCommon('begaranYttrande.orsakTillBegaran.label')}
										value={tCommon('begaranYttrande.orsakTillBegaran.orsak', { context: yttrande.orsakBegaran })}
									/>
								</Grid>
							</Fragment>
						)}
					</Grid>
					<Stack>
						<Typography fontWeight="bold">{t('utbildningarLabel', { count: yttrande.utbildningar.length })}</Typography>
						<List disablePadding>
							{yttrande.utbildningar.map((u, i) => (
								<ListItem divider={i < utbildningarMaxIndex} key={`${u.utbildningsanordnare}-${u.utbildningsnamn}-${i}`} disablePadding>
									<ListItemText
										data-testid={`utbildning-${yttrande.id}-${i}`}
										primary={u.utbildningsnamn || 'Inget värde hittades'}
										secondary={u.utbildningsanordnare || 'Inget värde hittades'}
									/>
								</ListItem>
							))}
						</List>
					</Stack>
				</Stack>
			</CardContent>
		</Card>
	)
}

export default YttrandeCard
