import {
	BegaranYttrandeSvarFormData,
	YttrandeFormModel,
} from '@local/src/Pages/BegaranYttrande/Edit/Components/BegaranYttrandeSvarForm/Models/BegaranYttrandeSvarForm.types'
import { starksFramtidaStallningAvstar } from '@local/src/Pages/Common/Schemas'
import { KundTillhorTrr } from '@local/src/Services/SwaggerApi/data-contracts'
import { ansokanValidationText, requiredValidationText } from '@local/src/Utils/YupHelper'
import { SchemaOf, boolean as yupBoolean, mixed as yupMixed, object as yupObject, string as yupString } from 'yup'

import { YttrandeSchema } from './YttrandeSchema'

export const begaranYttrandeSvarSchema: SchemaOf<BegaranYttrandeSvarFormData> = yupObject({
	grundansokanId: yupString().required(ansokanValidationText),
	shouldSendToCsnFormBool: yupBoolean(),
	idBegaran: yupString().required(),
	kundTillhorTrr: yupMixed()
		.nullable()
		.required(requiredValidationText)
		.oneOf<KundTillhorTrr>([KundTillhorTrr.KundTillhorTrr, KundTillhorTrr.ArbetsgivareTillhorEjTrr, KundTillhorTrr.Utforsakrad]),
	ansokanId: yupString()
		.nullable()
		.when(['kundTillhorTrr', 'yttrande'], {
			is: (kundTillhorTrr: KundTillhorTrr, yttrande: YttrandeFormModel) => {
				if (kundTillhorTrr !== KundTillhorTrr.KundTillhorTrr) return false
				const isAvstar = starksFramtidaStallningAvstar(yttrande?.starksFramtidaStallning)
				return isAvstar ? false : true
			},
			then: (schema) => schema.required(ansokanValidationText),
		}),
	yttrande: YttrandeSchema.when('kundTillhorTrr', {
		is: (kundTillhorTrr: KundTillhorTrr) => kundTillhorTrr === KundTillhorTrr.KundTillhorTrr,
		then: YttrandeSchema,
		otherwise: yupObject().undefinedOrNullSchema(),
	}),
})
