import { useAdHocYttrandeFormContext } from '@local/src/Pages/AdHocYttrande/Hooks'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { Button, FormHelperText, Typography } from '@mui/material'
import React, { Fragment } from 'react'
import { useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { UtbildningBaseForm } from '../UtbildningBaseForm'

export const AddUtbildningar = () => {
	const {
		control,
		formState: { errors },
		isReadOnly,
		trigger,
	} = useAdHocYttrandeFormContext()

	const { append, fields, remove } = useFieldArray({
		control,
		name: 'utbildningar',
	})

	const handleAddNewUtbildning = async () => {
		append({
			isCsnUtbildning: true,
			csnUtbildning: null,
			ejCsnUtbildning: null,
			koptUtbildning: null,
			slutfordTidigare: null,
			specifikArbetsgivare: null,
			tillhandahallasAvArbetsgivare: null,
			vasentligSkillnad: null,
		})
		if (errors?.utbildningar) {
			await trigger('utbildningar')
		}
	}

	const { t } = useTranslation('translation', { keyPrefix: 'adHocYttrande.form.utbildningar.add' })

	return (
		<Fragment>
			<Typography variant="h3" gutterBottom>
				{t('title')}
			</Typography>
			{fields.length > 0 ? (
				fields.map((u, index) => <UtbildningBaseForm key={`utb-${u.id}`} index={index} remove={remove} />)
			) : (
				<Typography>{t('description')}</Typography>
			)}
			{!isReadOnly && (
				<Button
					startIcon={<AddRoundedIcon fontSize="large" />}
					data-testid="add-utbildningar-button"
					variant="text"
					size="large"
					type="button"
					onClick={handleAddNewUtbildning}
					sx={{
						mt: 4,
						mr: 2,
						p: 1,
						'&:hover': {
							backgroundColor: '#fff',
							color: '#3c52b2',
						},
					}}
				>
					{t('knapp')}
				</Button>
			)}

			{errors?.utbildningar && (
				<FormHelperText sx={{ fontSize: 16, mt: 2 }} error data-testid={'utbildningar-general-error'}>
					{errors?.utbildningar?.message}
				</FormHelperText>
			)}
		</Fragment>
	)
}
