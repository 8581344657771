import useEpiContentWithFallback from '@local/src/Hooks/useEpiContentWithFallback'
import { removeDuplicateSlashes } from '@local/src/Utils/urlHelper'
import { generatePath, useHistory } from 'react-router-dom'

interface Props {
	arendeId: string
	ansokanId: string
	adhocYttrandeId?: string
	step?: string
}

const useRedirectToStudieansokan = ({ arendeId, ansokanId, adhocYttrandeId, step = 'csn' }: Props) => {
	const { adHocYttrande } = useEpiContentWithFallback()
	const history = useHistory()
	const redirectUserToStudiestodsansokan = () => {
		const studiestodAnsokanDetailPath = removeDuplicateSlashes(
			`${adHocYttrande?.backToStudieansokan?.link}/:arendeId/studier/ansokan/:ansokanId/:step?`
		)
		const newUrl = generatePath(studiestodAnsokanDetailPath, {
			arendeId,
			ansokanId,
			step,
		})
		history.push(newUrl, {
			adhocYttrandeId,
		})
	}

	return redirectUserToStudiestodsansokan
}

export default useRedirectToStudieansokan
