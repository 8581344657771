import { SelectOption } from '@local/src/Components/FormComponents/Dropdown/Dropdown.types'
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'

interface Props {
	label: string
	value: string | number
	onChange: React.Dispatch<React.SetStateAction<string | number>>
	selectOptions: SelectOption[]
	errorMsg?: string
	testId?: string
	isReadOnly?: boolean
}

const Dropdown = ({ label, value, errorMsg, testId, selectOptions, onChange, isReadOnly = false }: Props) => (
	<FormControl fullWidth error={Boolean(errorMsg)}>
		<InputLabel>{label}</InputLabel>
		<Select
			onChange={(event) => {
				onChange(event.target.value)
			}}
			value={value}
			label={label}
			data-testid={testId}
			inputProps={{ readOnly: isReadOnly }}
			MenuProps={{
				sx: {
					maxHeight: '500px',
				},
			}}
		>
			{selectOptions.map((option) => (
				<MenuItem key={`${option.value}`} value={option.value}>
					{option.label}
				</MenuItem>
			))}
		</Select>
		<FormHelperText>{errorMsg}</FormHelperText>
	</FormControl>
)

export default Dropdown
