import { RadioButtonGroup } from '@local/Components/index'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	header: string
	name: string
	valueType: 'boolean' | 'J/N'
	testId?: string
	disabled?: boolean
}

const JaNejRadioButtonGroup = ({ header, name, valueType, testId, disabled }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'common.glossary' })

	return (
		<RadioButtonGroup
			label={header}
			name={name}
			valueType={valueType === 'boolean' ? 'boolean' : 'string'}
			testId={testId}
			radios={[
				{
					label: t('ja'),
					value: valueType === 'boolean' ? true : 'J',
					disabled: disabled,
				},
				{
					label: t('nej'),
					value: valueType === 'boolean' ? false : 'N',
					disabled: disabled,
				},
			]}
		/>
	)
}

export default JaNejRadioButtonGroup
