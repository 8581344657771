import { CsnUtbildningYttrandeApiModel } from '@local/Services/SwaggerApi/data-contracts'
import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Utbildning } from './Utbildning'

interface Props {
	utbildningar: CsnUtbildningYttrandeApiModel[]
}

export const Utbildningar = ({ utbildningar }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'begaranYttrande.info.utbildningar' })
	return (
		<Stack spacing={2}>
			<Typography variant="h4">{t('title', { count: utbildningar.length })}</Typography>
			<Box>
				{utbildningar.map((u) => (
					<Utbildning utbildning={u} key={u.idUtbildning} />
				))}
			</Box>
		</Stack>
	)
}
