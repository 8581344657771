import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from '@mui/material'
import React from 'react'
import { useController, useFormContext } from 'react-hook-form'

export interface RadioButton {
	label: string
	value: string | number | boolean
	disabled?: boolean
}
interface IRadioButtonGroup {
	label: string
	name: string
	valueType?: 'string' | 'number' | 'boolean'
	radios: RadioButton[]
	isReadOnly?: boolean
	testId?: string
	spacing?: number
}

export const RadioButtonGroup = ({ label, testId, name, radios, valueType = 'string', isReadOnly, spacing = 0 }: IRadioButtonGroup) => {
	const { watch } = useFormContext()
	const {
		field,
		fieldState: { error },
	} = useController({ name, defaultValue: null })

	const fieldValue = watch(name)
	const getTypedValue = (value: string) => {
		switch (valueType) {
			case 'string':
				return value
			case 'number':
				return value ? parseInt(value) : undefined
			case 'boolean':
				if (value === 'true' || value === 'false') {
					return value === 'true'
				}
				return undefined
		}
	}

	return (
		<FormControl component="fieldset" data-testid={testId || name}>
			<FormLabel component="legend">{label}</FormLabel>
			<RadioGroup
				{...field}
				onChange={(_, value) => {
					const typedValue = getTypedValue(value)
					field.onChange(typedValue)
					field.onBlur()
				}}
			>
				{radios.map((r) => (
					<FormControlLabel
						key={`${name}-${r.value.toString()}-radio`}
						value={r.value}
						control={<Radio disabled={r.disabled} checked={(fieldValue as unknown) === r.value} />}
						label={r.label}
						name={name}
						id={`${name}-${r.value.toString()}`}
						disabled={isReadOnly}
						sx={{ mt: spacing }}
					/>
				))}
				<FormHelperText error={Boolean(error?.message)}>{error?.message}</FormHelperText>
			</RadioGroup>
		</FormControl>
	)
}
