import { yupResolver } from '@hookform/resolvers/yup'
import { DatePicker } from '@local/src/Components/FormComponents/DatePicker'
import SubmitResponse from '@local/src/Components/SubmitResponse/SubmitResponse'
import { useCommonTranslation, useCurrentUser } from '@local/src/Hooks'
import { markeraForlangningSchema } from '@local/src/Pages/BegaranYttrande/Components/YttrandeInfo/Components/MarkeraForlangning/Schemas/MarkeraForlangningSchema'
import { usePostLastDateToSvara } from '@local/src/Pages/BegaranYttrande/Edit/Components/BegaranYttrandeSvarForm/Hooks/usePostLastDateToSvara'
import { CsnYttrandeApiModel, UpdateLastDateToSvaraApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { formatDateNull } from '@local/src/Utils/dateFormatHelper'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography } from '@mui/material'
import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { MarkeraForlangningForm, MarkeraForlangningHeader } from './MarkeraForlangningElements'

interface Props {
	yttrande: CsnYttrandeApiModel
	isReadOnly?: boolean
}

const MarkeraForlangning = ({ yttrande, isReadOnly = false }: Props) => {
	const [toggled, setToggled] = useState(false)
	const formMethods = useForm({
		resolver: yupResolver(markeraForlangningSchema),
	})

	const {
		handleSubmit,
		formState: { isSubmitting },
	} = formMethods
	const { userHasRole } = useCurrentUser()
	const enableUserToUpdate = userHasRole(['HANDLAGGARE-STUDIER', 'KOORDINATOR-STUDIER', 'RADGIVNINGS-ADMINISTRATOR'])
	const { t } = useTranslation('translation', { keyPrefix: 'begaranYttrande.info.markeraForlangning' })
	const { tCommon } = useCommonTranslation()

	const isDateExtended = yttrande?.lastDateToSvaraExtension !== null
	const lastDateToSvara = isDateExtended ? yttrande?.lastDateToSvaraExtension?.time : yttrande?.lastDateToSvara

	const { postLastDateToSvaraMutation, apiResponse } = usePostLastDateToSvara(yttrande?.begaranYttrande.grundansokanId)

	const onSubmit = async (formData: UpdateLastDateToSvaraApiModel) => {
		const response = await postLastDateToSvaraMutation.mutateAsync({
			grundansokanId: yttrande?.begaranYttrande.grundansokanId,
			idBegaran: yttrande?.begaranYttrande.idBegaran,
			data: {
				lastDateToSvara: new Date(formData.lastDateToSvara).toISOString(),
			},
		})

		if (response.isSuccess) {
			setToggled(false)
		}
	}

	const updatedBy = yttrande?.lastDateToSvaraExtension?.userProfileMetaApiModel
		? `av ${yttrande?.lastDateToSvaraExtension?.userProfileMetaApiModel?.firstName} ${yttrande?.lastDateToSvaraExtension?.userProfileMetaApiModel?.lastName}`
		: ''

	const titleHeaderText = `${isDateExtended ? t('forlangtDatumAttSvara') : t('svaraSenast')} ${formatDateNull(lastDateToSvara)}`

	const subtitleHeaderText = `${t('uppdateradAv')} ${formatDateNull(yttrande.lastDateToSvaraExtension?.createdAt)} ${updatedBy}`

	if (isReadOnly) {
		return (
			<Box mb={3}>
				<MarkeraForlangningHeader
					isDateExtended={isDateExtended}
					titleHeaderText={titleHeaderText}
					subtitleHeaderText={subtitleHeaderText}
				/>
			</Box>
		)
	}
	const expansionId = `${yttrande?.begaranYttrande.idBegaran}-markera-forlangning`
	return (
		<Accordion
			expanded={toggled}
			onChange={(_, expanded) => setToggled(expanded)}
			sx={(theme) => ({
				border: `1px solid ${theme.palette.grey[400]}`,
				boxShadow: theme.shadows[0],
				borderRadius: '4px',
				mb: 3,
			})}
		>
			<AccordionSummary
				data-testid={`markera-forlangning-accordion`}
				expandIcon={<ExpandMoreIcon />}
				aria-controls={`${expansionId}-content`}
				id={`${expansionId}-header`}
			>
				<MarkeraForlangningHeader
					isDateExtended={isDateExtended}
					titleHeaderText={titleHeaderText}
					subtitleHeaderText={subtitleHeaderText}
				/>
			</AccordionSummary>
			<AccordionDetails>
				{enableUserToUpdate ? (
					<FormProvider {...formMethods}>
						<Typography mb={1} variant="body2">
							{t('sistaDagAttSvaraDescription')}
						</Typography>

						<MarkeraForlangningForm onSubmit={handleSubmit(onSubmit)}>
							<DatePicker name="lastDateToSvara" label={t('sistaDagAttSvara')} minDate={new Date()} disabled={isSubmitting} />
							<Button type="submit" disabled={isSubmitting} data-testid={'spara-last-date-to-svara-button'}>
								{tCommon('buttons.save')}
							</Button>
						</MarkeraForlangningForm>
					</FormProvider>
				) : (
					<Typography variant="body2">{t('markeraForlangningNotAuthorized')}</Typography>
				)}
			</AccordionDetails>
			<SubmitResponse apiResponse={apiResponse} showResponseSuccessfulJson={false} />
		</Accordion>
	)
}

export default MarkeraForlangning
