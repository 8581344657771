import { useCommonTranslation } from '@local/src/Hooks'
import { CsnBeslutsperiodKompletterandeBidragApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { Box, Stack, Typography, styled } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { BidragBeloppInput } from '../BidragBeloppInput'
import { FromTomVeckaInput } from '../FromTomVeckaInput'

const StyledStack = styled(Stack)(({ theme }) => ({
	flexDirection: 'row',
	gap: theme.spacing(1),
	[theme.breakpoints.down('md')]: {
		flexDirection: 'column',
	},
}))

interface Props {
	beslutsPeriod: CsnBeslutsperiodKompletterandeBidragApiModel
	index: number
	isReadOnly: boolean
}
const PeriodKompletterandeBidragRow = ({ beslutsPeriod, index, isReadOnly }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'csnPortal.kompletterandeBidrag.svar' })
	const { tCommon } = useCommonTranslation()

	const bidragsBeloppText = t('bidragsbelopp')
	return (
		<Box
			sx={(theme) => ({
				background: theme.palette.grey[200],
				borderRadius: theme.shape.borderRadius,
				padding: theme.spacing(2),
				marginBottom: theme.spacing(2),
			})}
		>
			<Typography variant="h5">
				{tCommon('glossary.period')} {index + 1}
			</Typography>
			<Box mb={1}>
				<FromTomVeckaInput
					labelYear={t('fromAr')}
					labelWeek={t('fromVecka')}
					field={'fromVecka'}
					index={index}
					begaranPeriod={beslutsPeriod.fromVecka}
					isReadOnly={isReadOnly}
				/>
				<FromTomVeckaInput
					labelYear={t('tomAr')}
					labelWeek={t('tomVecka')}
					field={'tomVecka'}
					index={index}
					begaranPeriod={beslutsPeriod.tomVecka}
					isReadOnly={isReadOnly}
				/>
			</Box>

			<StyledStack>
				<BidragBeloppInput
					label={`${bidragsBeloppText} 100%`}
					field={'kompletterandeBidragsbelopp100'}
					index={index}
					isReadOnly={isReadOnly}
				/>
				<BidragBeloppInput
					label={`${bidragsBeloppText} 75%`}
					field={'kompletterandeBidragsbelopp75'}
					index={index}
					isReadOnly={isReadOnly}
				/>
			</StyledStack>
			<StyledStack>
				<BidragBeloppInput
					label={`${bidragsBeloppText} 60%`}
					field={'kompletterandeBidragsbelopp60'}
					index={index}
					isReadOnly={isReadOnly}
				/>
				<BidragBeloppInput
					label={`${bidragsBeloppText} 50%`}
					field={'kompletterandeBidragsbelopp50'}
					index={index}
					isReadOnly={isReadOnly}
				/>
			</StyledStack>
			<StyledStack>
				<BidragBeloppInput
					label={`${bidragsBeloppText} 40%`}
					field={'kompletterandeBidragsbelopp40'}
					index={index}
					isReadOnly={isReadOnly}
				/>
				<BidragBeloppInput
					label={`${bidragsBeloppText} 20%`}
					field={'kompletterandeBidragsbelopp20'}
					index={index}
					isReadOnly={isReadOnly}
				/>
			</StyledStack>
		</Box>
	)
}

export default PeriodKompletterandeBidragRow
