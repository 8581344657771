import { ApiResponseModel } from '@local/src/Services/ApiResponseModel'
import { CsnArendeBeslutPeriodIsRegisteredPartialUpdateParams } from '@local/src/Services/SwaggerApi/data-contracts'
import { csnBeslutApi } from '@local/src/Services/apiEndpointHelper'
import { apiRequestTryCatchWrapper } from '@local/src/Services/apiRequestHelper'
import { getApiResponseError } from '@local/src/Services/apiResponseHelper'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'

export type UpdateRegisterFenixRequest = CsnArendeBeslutPeriodIsRegisteredPartialUpdateParams

export const usePeriodIsRegisteredInFenix = () => {
	const [apiResponse, setApiResponse] = useState<ApiResponseModel<void>>(undefined)
	const queryClient = useQueryClient()

	const postPeriodIsRegisteredInFenixMutation = useMutation({
		mutationFn: (param: UpdateRegisterFenixRequest) => {
			setApiResponse(undefined)

			return apiRequestTryCatchWrapper('usePeriodIsRegisteredInFenix', csnBeslutApi.csnArendeBeslutPeriodIsRegisteredPartialUpdate, param)
		},
		onSuccess: async (response) => {
			setApiResponse(response)
			if (response.isSuccess) {
				await queryClient.invalidateQueries()
			}
		},
		onError: (response: { message?: string }) => {
			setApiResponse(getApiResponseError(response?.message))
		},
	})

	return { postPeriodIsRegisteredInFenixMutation, apiResponse }
}
