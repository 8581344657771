import { PageWrapper } from '@local/src/Components/PageWrapper'
import { useGetAdHocYttrandeDetail } from '@local/src/Hooks/API'
import AdHocYttrandeCard from '@local/src/Pages/BegaranYttrande/Edit/Components/BesvaradWithAdHoc/Components/AdHocYttrandeCard'
import { Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	adHocYttrandeId: string
}

const BesvaradWithAdHoc = ({ adHocYttrandeId }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'begaranYttrande.besvaradWithAdHoc' })

	const { adhocYttrandeData, isFetching, isError } = useGetAdHocYttrandeDetail(adHocYttrandeId)

	return (
		<Stack spacing={3}>
			<Typography variant="h5">{t('title')}</Typography>

			<PageWrapper isLoading={isFetching} errorLoadingData={isError} errorText={t('error')}>
				<AdHocYttrandeCard adHocYttrande={adhocYttrandeData} />
			</PageWrapper>
		</Stack>
	)
}

export default BesvaradWithAdHoc
