import Form from '@local/src/Components/Form'
import SubmitResponse from '@local/src/Components/SubmitResponse/SubmitResponse'
import {
	KompletterandeBidrag,
	OrsakStarksFramtidaStallning,
	OrsakTillMotivering,
	StarksFramtidaStallning,
} from '@local/src/Components/Yttranden'
import { useRedirectToStudieansokan } from '@local/src/Hooks'
import { ApiResponseModel } from '@local/src/Services/ApiResponseModel'
import { AdHocYttrandeApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { Box, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FormProvider } from 'react-hook-form'

import { useAdHocYttrandeForm } from '../../Hooks'
import useSetDefaultFormDataAdhocYttrande from '../../Hooks/useSetDefaultFormDataAdhocYttrande'

import { AddUtbildningar, MalMedStudierna, SvarForBegaran } from './Components'
import AdHocYttrandeFormActionButtons from './Components/FormActionButtons/AdHocYttrandeFormActionButtons'

interface Props {
	arendeId: string
	ansokanId: string
	personnummer: string
	trrAnsokanDatum: string
	isNewAdHoc: boolean
	isReadOnly: boolean
	adhocYttrandeData?: AdHocYttrandeApiModel
}

const AdHocYttrandeForm = ({ arendeId, ansokanId, personnummer, trrAnsokanDatum, isNewAdHoc, isReadOnly, adhocYttrandeData }: Props) => {
	const [apiResponse, setApiResponse] = useState<ApiResponseModel<void>>(undefined)

	const defaultFormData = useSetDefaultFormDataAdhocYttrande({ ansokanId, personnummer, adhocYttrandeData, isReadOnly, isNewAdHoc })
	const formMethods = useAdHocYttrandeForm(defaultFormData)
	const { watch, setValue } = formMethods
	const { orsakStarksFramtidaStallning, starksFramtidaStallning } = watch()

	const redirectUserToStudiestodsansokan = useRedirectToStudieansokan({
		ansokanId,
		arendeId,
		adhocYttrandeId: adhocYttrandeData?.id,
	})

	useEffect(() => {
		if (isNewAdHoc) {
			setValue('datumAnsokanOo', trrAnsokanDatum)
		}
	}, [setValue, isNewAdHoc, trrAnsokanDatum])

	useEffect(() => {
		if (apiResponse?.isSuccess) {
			redirectUserToStudiestodsansokan()
		}
	}, [redirectUserToStudiestodsansokan, apiResponse?.isSuccess])

	const isLinked = Boolean(adhocYttrandeData?.begaranId)

	return (
		<Box>
			<FormProvider {...formMethods}>
				<Form>
					<Stack spacing={3}>
						<MalMedStudierna />
						<SvarForBegaran adHocYttrandeId={adhocYttrandeData?.id} />
						<StarksFramtidaStallning name="starksFramtidaStallning" isReadOnly={isReadOnly} isAdHoc />
						<OrsakStarksFramtidaStallning
							name="orsakStarksFramtidaStallning"
							starksFramtidaStallning={starksFramtidaStallning}
							isReadOnly={isReadOnly}
						/>
						<OrsakTillMotivering
							name="orsakMotivering"
							isReadOnly={isReadOnly}
							orsakStarksFramtidaStallning={orsakStarksFramtidaStallning}
						/>
						<KompletterandeBidrag name="kompletterandeBidrag" isReadOnly={isReadOnly} />
						<AddUtbildningar />

						{!isReadOnly && (
							<AdHocYttrandeFormActionButtons
								isNewAdHoc={isNewAdHoc}
								setApiResponse={setApiResponse}
								adhocYttrandeId={adhocYttrandeData?.id}
								isDeletable={!isLinked}
							/>
						)}
					</Stack>
				</Form>
				<SubmitResponse apiResponse={apiResponse} />
			</FormProvider>
			{/* <DevTool control={formMethods.control} placement="bottom-right"  /> */}
		</Box>
	)
}

export default AdHocYttrandeForm
