import { useGetArende, useGetSimpleStudiestod } from '@local/src/Hooks/API'
import { isInvalidDate } from '@local/src/Utils/dateFormatHelper'
import { not } from 'ramda'

const useGetAnsokanKomplettDatum = (isNotSentToCsn: boolean, ansokanId?: string) => {
	const { isFetched, simpleStudiestodData } = useGetSimpleStudiestod(ansokanId)
	const { ansokanKomplettDatum, isFetched: arendeFetched } = useGetArende(simpleStudiestodData?.arendeId, isFetched && isNotSentToCsn)

	const hasInvalidAnsokanKomplettDatum = arendeFetched && isNotSentToCsn && isInvalidDate(ansokanKomplettDatum)

	return {
		ansokanKomplettDatum,
		hasInvalidAnsokanKomplettDatum,
		hasValidAnsokanKomplettDatum: not(hasInvalidAnsokanKomplettDatum),
	}
}

export default useGetAnsokanKomplettDatum
