import { SearchBeslutRequestApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { Stack, Typography } from '@mui/material'
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { PageErrorWrapper, PageWrapper } from '@local/src/Components/PageWrapper'
import { EmptyResult, Loading } from '@local/src/Components'
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from '@local/src/Hooks'

import { CsnBeslutPagination, CsnBeslutSearchFilter, BeslutListItem, BeslutListItemSkeleton } from './Components'
import useSearchCsnBeslut from './Hooks/useSearchCsnBeslut'
import { useEncryptBeslutFilter } from './Hooks/useEncryptBeslutFilter'

const CsnBeslutTab = () => {
	const { getUserMdId } = useCurrentUser()
	const { decryptFilter, encryptFilter } = useEncryptBeslutFilter(getUserMdId())

	const { t } = useTranslation('translation', { keyPrefix: 'csnPortal.beslut' })

	const [searchQuery, setSearchQuery] = useState<SearchBeslutRequestApiModel>(decryptFilter())

	const { beslutSearchResponse, isLoading, isError, isFetched, isFetching } = useSearchCsnBeslut(searchQuery)

	useEffect(() => {
		encryptFilter(searchQuery)
	}, [encryptFilter, searchQuery])

	useEffect(() => {
		setSearchQuery((prevState) => ({ ...prevState, page: 1 }))
	}, [searchQuery.csnBeslutsutfall, searchQuery.studieforsakran, searchQuery.personnummer])

	const hasSearchResult = Boolean(beslutSearchResponse?.values?.length)
	const showLoader = !isFetched && isFetching
	const skeletonList = Array.from({ length: searchQuery.pageSize }, (_, i) => i)
	const skeletonCards = useMemo(() => skeletonList.map((y) => <BeslutListItemSkeleton key={y} />), [skeletonList])
	const beslutCards = useMemo(
		() => beslutSearchResponse?.values?.map((b) => <BeslutListItem key={b.id} beslut={b} />),
		[beslutSearchResponse?.values]
	)

	return (
		<PageWrapper isLoading={isLoading}>
			<Stack spacing={2}>
				<Typography variant="h4">{t('title')}</Typography>

				<CsnBeslutSearchFilter searchQuery={searchQuery} setSearchQuery={setSearchQuery} />

				{isError ? (
					<PageErrorWrapper errorText={t('errors.hamtningsfel')} />
				) : (
					<Fragment>
						<CsnBeslutPagination
							setSearchQuery={setSearchQuery}
							beslutSearchResponse={beslutSearchResponse}
							rowsPerPageQuery={searchQuery.pageSize}
						/>
						<Stack spacing={4}>
							<Loading isLoading={showLoader} fallback={skeletonCards}>
								{hasSearchResult ? (
									beslutCards
								) : (
									<EmptyResult isSmall heading={t('emptyResult.title')} description={t('emptyResult.Description')} />
								)}
							</Loading>
						</Stack>
						<CsnBeslutPagination
							setSearchQuery={setSearchQuery}
							beslutSearchResponse={beslutSearchResponse}
							rowsPerPageQuery={searchQuery.pageSize}
						/>
					</Fragment>
				)}
			</Stack>
		</PageWrapper>
	)
}

export default CsnBeslutTab
