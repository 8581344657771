import { EmptyResult, Loading } from '@local/src/Components'
import { PageWrapper, PageErrorWrapper } from '@local/src/Components/PageWrapper'
import { useCurrentUser } from '@local/src/Hooks'
import YttrandeCard from '@local/src/Pages/CsnPortal/Yttranden/Components/YttrandeCard/YttrandeCard'
import YttrandeCardSkeleton from '@local/src/Pages/CsnPortal/Yttranden/Components/YttrandeCard/YttrandeCardSkeleton'
import YttrandenTableSorting from '@local/src/Pages/CsnPortal/Yttranden/Components/YttrandenTableSorting/YttrandenTableSorting'
import useSearchYttrande from '@local/src/Pages/CsnPortal/Yttranden/Hooks/useSearchYttrande'
import { SearchYttrandenRequestApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { SelectChangeEvent, Stack, TablePagination, Typography } from '@mui/material'
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import YttrandeFilterCard from './Components/YttrandeFilterCard/YttrandeFilterCard'
import { useEncryptYttrandeFilter } from './Hooks/useEncryptYttrandeFilter'

const YttrandenTab = () => {
	const { getUserMdId } = useCurrentUser()
	const { decryptFilter, encryptFilter } = useEncryptYttrandeFilter(getUserMdId())

	const { t } = useTranslation('translation', { keyPrefix: 'csnPortal.yttranden' })
	const [searchFilter, setSearchFilter] = useState<SearchYttrandenRequestApiModel>(decryptFilter())
	const [currentPage, setCurrentPage] = useState(searchFilter.page - 1)
	const { searchResult, isLoading, isError, isFetching, isFetched } = useSearchYttrande(searchFilter)

	const setSearchFilterWithPage = (newSearchFilter: SearchYttrandenRequestApiModel) => {
		setSearchFilter({ ...newSearchFilter, page: 1 })
		setCurrentPage(0)
	}

	const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		setSearchFilter({ ...searchFilter, page: newPage + 1 })
		setCurrentPage(newPage)
	}

	const handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<number | string>) => {
		const pageSize = typeof event.target.value === 'number' ? event.target.value : parseInt(event.target.value, 10)
		setSearchFilter({ ...searchFilter, pageSize, page: 1 })
		setCurrentPage(0)
	}

	useEffect(() => {
		encryptFilter(searchFilter)
	}, [encryptFilter, searchFilter])
	const rowsPerPageOptions = [5, 10, 20, 50, 100]
	const showLoader = !isFetched && isFetching
	const skeletonList = Array.from({ length: searchFilter.pageSize }, (_, i) => i)
	const yttrandeList = searchResult?.values
	const hasSearchResult = yttrandeList?.length > 0
	const skeletonCards = useMemo(() => skeletonList.map((y) => <YttrandeCardSkeleton key={y} />), [skeletonList])
	const yttrandeCards = useMemo(() => yttrandeList?.map((y) => <YttrandeCard key={y.id} yttrande={y} />), [yttrandeList])

	return (
		<PageWrapper isLoading={isLoading}>
			<Stack spacing={2}>
				<Typography variant="h4">{t('title')}</Typography>

				<YttrandeFilterCard searchFilter={searchFilter} setSearchFilterWithPage={setSearchFilterWithPage} />

				{isError ? (
					<PageErrorWrapper errorText={t('errors.hamtningsfel')} />
				) : (
					<Fragment>
						{hasSearchResult && (
							<YttrandenTableSorting
								yttrandeListLength={yttrandeList?.length}
								searchResultTotalCount={searchResult?.totalCount}
								searchFilter={searchFilter}
								handleChangePageSize={handleChangePageSize}
								rowsPerPageOptions={rowsPerPageOptions}
								setSearchFilter={setSearchFilter}
							/>
						)}

						<Stack spacing={2}>
							<Loading isLoading={showLoader} fallback={skeletonCards}>
								{hasSearchResult ? (
									yttrandeCards
								) : (
									<EmptyResult isSmall heading={t('yttrandenEmptyResultHeader')} description={t('yttrandenEmptyResultDescription')} />
								)}
							</Loading>
						</Stack>

						{hasSearchResult && (
							<TablePagination
								component="div"
								count={searchResult?.totalCount}
								page={currentPage}
								onPageChange={handleChangePage}
								rowsPerPage={searchFilter.pageSize}
								rowsPerPageOptions={rowsPerPageOptions}
								onRowsPerPageChange={handleChangePageSize}
								labelRowsPerPage={t('yttrandenTableSorting.yttrandenPerPage')}
								SelectProps={{ sx: { marginLeft: 0 } }}
							/>
						)}
					</Fragment>
				)}
			</Stack>
		</PageWrapper>
	)
}

export default YttrandenTab
