import React, { ReactElement, ReactNode } from 'react'

interface KeyRouteProps {
	urlKey?: string
	children: JSX.Element
}

export const KeyRoute = ({ children }: KeyRouteProps) => children

export const AppShellRouter = ({ currentKey, children }: { currentKey: string; children: ReactNode }) => {
	let component: ReactElement

	React.Children.forEach(children, (child: ReactElement<{ urlKey: string }>) => {
		if (React.isValidElement(child)) {
			if (child.props.urlKey === currentKey) {
				component = child
			}
		} else {
			component = null
		}
	})

	return component ?? null
}
