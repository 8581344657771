import { FilterChip } from '@local/src/Components'
import getFacets from '@local/src/Pages/CsnPortal/Yttranden/Hooks/useSearchYttrande'
import { SearchYttrandenRequestApiModel, LastDateToSvaraSearchRange } from '@local/src/Services/SwaggerApi/data-contracts'
import { Grid2 as Grid, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { getFacetCounts } from '../Helpers/getFacetCounts'

interface Props {
	searchFilter: SearchYttrandenRequestApiModel
	setSearchFilterWithPage: (searchFilter: SearchYttrandenRequestApiModel) => void
}

const YttrandeFilterLastDateToSvara = ({ searchFilter, setSearchFilterWithPage }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'csnPortal.yttranden.yttrandeFilterCard.yttrandeFilterLastDateToSvara' })

	const {
		filters: { lastDateToSvaraSearchRanges },
	} = searchFilter

	const facetSearchQuery: SearchYttrandenRequestApiModel = {
		...searchFilter,
		pageSize: 1,
		page: 1,
		filters: { ...searchFilter.filters, lastDateToSvaraSearchRanges: [] },
	}

	const { searchResult } = getFacets(facetSearchQuery)

	const counts = getFacetCounts(searchResult, 'LastDateToSvara', LastDateToSvaraSearchRange)

	const handleLastDateToSvaraChipClick = (dateRange: LastDateToSvaraSearchRange) => {
		const updatedDateRanges = lastDateToSvaraSearchRanges.includes(dateRange)
			? lastDateToSvaraSearchRanges.filter((dr) => dr !== dateRange)
			: [...lastDateToSvaraSearchRanges, dateRange]

		setSearchFilterWithPage({
			...searchFilter,
			filters: { ...searchFilter.filters, lastDateToSvaraSearchRanges: updatedDateRanges },
		})
	}

	const getSelectedDateRange = (dateRange: LastDateToSvaraSearchRange) => lastDateToSvaraSearchRanges.find((dr) => dr === dateRange)

	return (
		<Grid container direction="column" spacing={1} data-testid={'yttrande-filter-last-date-to-svara'}>
			<Grid>
				<Typography>{t('title')}</Typography>
			</Grid>
			<Grid container spacing={1}>
				<Grid>
					<FilterChip
						dataTestId={`yttrande-filter-last-date-to-svara-${LastDateToSvaraSearchRange.PriorToToday}-chip`}
						label={t('priorToToday', { count: counts.PriorToToday })}
						filter={LastDateToSvaraSearchRange.PriorToToday}
						selectedFilter={getSelectedDateRange(LastDateToSvaraSearchRange.PriorToToday)}
						onFilterClicked={handleLastDateToSvaraChipClick}
					/>
				</Grid>
				<Grid>
					<FilterChip
						dataTestId={`yttrande-filter-last-date-to-svara-${LastDateToSvaraSearchRange.WithinTwoWeeks}-chip`}
						label={t('withinTwoWeeks', { count: counts.WithinTwoWeeks })}
						filter={LastDateToSvaraSearchRange.WithinTwoWeeks}
						selectedFilter={getSelectedDateRange(LastDateToSvaraSearchRange.WithinTwoWeeks)}
						onFilterClicked={handleLastDateToSvaraChipClick}
					/>
				</Grid>
				<Grid>
					<FilterChip
						dataTestId={`yttrande-filter-last-date-to-svara-${LastDateToSvaraSearchRange.LaterThanTwoWeeks}-chip`}
						label={t('laterThanTwoWeeks', { count: counts.LaterThanTwoWeeks })}
						filter={LastDateToSvaraSearchRange.LaterThanTwoWeeks}
						selectedFilter={getSelectedDateRange(LastDateToSvaraSearchRange.LaterThanTwoWeeks)}
						onFilterClicked={handleLastDateToSvaraChipClick}
					/>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default YttrandeFilterLastDateToSvara
