import { FilterChip } from '@local/src/Components'
import getFacets from '@local/src/Pages/CsnPortal/Yttranden/Hooks/useSearchYttrande'
import { SearchYttrandenRequestApiModel, SearchYttrandenStatus } from '@local/src/Services/SwaggerApi/data-contracts'
import { Grid2 as Grid, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { getFacetCounts } from '../Helpers/getFacetCounts'

interface Props {
	searchFilter: SearchYttrandenRequestApiModel
	setSearchFilterWithPage: (searchFilter: SearchYttrandenRequestApiModel) => void
}

const YttrandeFilterStatus = ({ searchFilter, setSearchFilterWithPage }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'csnPortal.yttranden.yttrandeFilterCard.yttrandeFilterStatus' })

	const facetSearchQuery: SearchYttrandenRequestApiModel = {
		...searchFilter,
		pageSize: 1,
		page: 1,
		filters: { ...searchFilter?.filters, status: undefined },
	}

	const { searchResult } = getFacets(facetSearchQuery)

	const counts = getFacetCounts(searchResult, 'Status', SearchYttrandenStatus)

	const handleStatusChipClick = (status: SearchYttrandenStatus) => {
		const toggledStatus = searchFilter?.filters?.status === status ? undefined : status
		setSearchFilterWithPage({
			...searchFilter,
			filters: { ...searchFilter?.filters, status: toggledStatus },
		})
	}

	const selectedFilter = searchFilter?.filters?.status

	return (
		<Grid container direction="column" spacing={1} data-testid={'yttrande-filter-status'}>
			<Grid>
				<Typography>{t('title')}</Typography>
			</Grid>
			<Grid container spacing={1}>
				<Grid>
					<FilterChip
						dataTestId={`yttrande-filter-status-${SearchYttrandenStatus.Ny}-chip`}
						label={t('nyFilterChipLabel', { count: counts.Ny })}
						filter={SearchYttrandenStatus.Ny}
						selectedFilter={selectedFilter}
						onFilterClicked={handleStatusChipClick}
					/>
				</Grid>
				<Grid>
					<FilterChip
						dataTestId={`yttrande-filter-status-${SearchYttrandenStatus.UnderBedomning}-chip`}
						label={t('underBedomningFilterChipLabel', { count: counts.UnderBedomning })}
						filter={SearchYttrandenStatus.UnderBedomning}
						selectedFilter={selectedFilter}
						onFilterClicked={handleStatusChipClick}
					/>
				</Grid>
				<Grid>
					<FilterChip
						dataTestId={`yttrande-filter-status-${SearchYttrandenStatus.SkickadTillCsn}-chip`}
						label={t('skickadTillCsnFilterChipLabel', { count: counts.SkickadTillCsn })}
						filter={SearchYttrandenStatus.SkickadTillCsn}
						selectedFilter={selectedFilter}
						onFilterClicked={handleStatusChipClick}
					/>
				</Grid>
				<Grid>
					<FilterChip
						dataTestId={`yttrande-filter-status-${SearchYttrandenStatus.BesvaradWithAdhocYttrande}-chip`}
						label={t('besvaradWithAdHoc', { count: counts.BesvaradWithAdhocYttrande })}
						filter={SearchYttrandenStatus.BesvaradWithAdhocYttrande}
						selectedFilter={selectedFilter}
						onFilterClicked={handleStatusChipClick}
					/>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default YttrandeFilterStatus
