import { Loading } from '@local/src/Components'
import { SelectOption } from '@local/src/Components/FormComponents/Dropdown/Dropdown.types'
import { Separator } from '@local/src/Components/Separator'
import SubmitResponse from '@local/src/Components/SubmitResponse/SubmitResponse'
import { useGetPublishedStudieansokanList } from '@local/src/Hooks/API'
import { usePostKopplaBegaranYttrande } from '@local/src/Pages/BegaranYttrande/Hooks/usePostKopplaBegaranYttrande'
import { StudiestodListApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { Alert, AlertTitle, Autocomplete, Box, Button, FormControl, Link, Stack, TextField, Typography } from '@mui/material'
import { isNil } from 'ramda'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router-dom'

interface Props {
	grundansokanId: string
	begaranId: string
	kopplatMedAnsokanId: string
	isReadOnly: boolean
	adHocYttrandeId: string
}

interface KopplaBegaranYttrandeParam {
	arendeId?: string
}

const KopplaBegaranYttrande = ({ grundansokanId, begaranId, kopplatMedAnsokanId, isReadOnly, adHocYttrandeId }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'begaranYttrande.koppla' })

	const pathEdit = `*/arende/:arendeId`
	const routeMatch = useRouteMatch<KopplaBegaranYttrandeParam>(pathEdit)
	const { isFetched, studiestodAnsokanList, isError } = useGetPublishedStudieansokanList(routeMatch?.params?.arendeId)
	const { postKopplaBegaranYttrandeMutation, apiResponse } = usePostKopplaBegaranYttrande()
	const [isSubmitting, setIsSubmitting] = useState(false)

	const [selectedStudiestodAnsokan, setSelectedStudiestodAnsokan] = useState<StudiestodListApiModel>(null)
	const [koppladAnsokan, setKoppladAnsokan] = useState<StudiestodListApiModel>(null)

	const getStudiestodLabel = (nummer: number, namn: string) => (nummer ? `#${nummer} - ${namn}` : namn)

	const selectedStudiestodAnsokanOptions: SelectOption | null = selectedStudiestodAnsokan
		? {
				label: getStudiestodLabel(selectedStudiestodAnsokan.studiestodNummer, selectedStudiestodAnsokan.ansokanNamn),
				value: selectedStudiestodAnsokan.id,
			}
		: null

	const options: SelectOption[] =
		studiestodAnsokanList?.map((ansokan) => ({
			label: getStudiestodLabel(ansokan.studiestodNummer, ansokan.ansokanNamn),
			value: ansokan.id,
		})) || []

	const hasStudieansokan = studiestodAnsokanList?.length > 0

	useEffect(() => {
		if (!isError && isFetched && hasStudieansokan && kopplatMedAnsokanId) {
			const ansokan: StudiestodListApiModel = studiestodAnsokanList.find((ansokan) => ansokan.id === kopplatMedAnsokanId)

			setKoppladAnsokan(ansokan)
			setSelectedStudiestodAnsokan(ansokan)
		}
	}, [isError, isFetched, kopplatMedAnsokanId, koppladAnsokan, studiestodAnsokanList, hasStudieansokan])

	const hasSavedAdHocYttrande = Boolean(adHocYttrandeId)

	const disableKopplaButton =
		isNil(selectedStudiestodAnsokan) ||
		!hasStudieansokan ||
		isSubmitting ||
		selectedStudiestodAnsokan?.isLegacyAnsokan ||
		koppladAnsokan?.id === selectedStudiestodAnsokan?.id ||
		hasSavedAdHocYttrande

	const disableDropdown = isReadOnly || !hasStudieansokan || isSubmitting || hasSavedAdHocYttrande

	const handleKopplaStudieansokan = async () => {
		setIsSubmitting(true)
		await postKopplaBegaranYttrandeMutation.mutateAsync({
			grundansokanId,
			begaranId,
			ansokanId: selectedStudiestodAnsokan.id,
		})
		setIsSubmitting(false)
	}

	return (
		<Stack spacing={2}>
			<Typography variant="h5">{t('labels.tillhorStudiesansokan')}</Typography>
			<Typography>{t('info.begaranDescription')}</Typography>

			<Stack spacing={3}>
				<Loading isLoading={!isFetched}>
					<Stack direction="row" alignItems="center" spacing={2}>
						<FormControl fullWidth>
							<Autocomplete
								isOptionEqualToValue={(option, value) => option.value === value.value}
								options={options}
								onChange={(_, newValue) => {
									const studiestodAnsokan = studiestodAnsokanList.find((s) => s.id === newValue?.value?.toString())
									if (!studiestodAnsokan) return
									setSelectedStudiestodAnsokan(studiestodAnsokan)
								}}
								value={selectedStudiestodAnsokanOptions}
								disabled={disableDropdown}
								slotProps={{
									popper: {
										id: `koppla-begaran-yttrande-select`,
									},
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										disabled={disableDropdown}
										inputProps={{
											...params.inputProps,
											'data-testid': 'koppla-begaran-yttrande-dropdown',
										}}
										label={t('labels.studieansokanDropdown')}
									/>
								)}
							/>
						</FormControl>

						{!isReadOnly && (
							<Button
								size="small"
								variant="outlined"
								onClick={handleKopplaStudieansokan}
								data-testid="koppla-begaran-yttrande-button"
								disabled={disableKopplaButton}
							>
								{t('labels.studieansokanButton')}
							</Button>
						)}
					</Stack>
					{hasSavedAdHocYttrande && (
						<Alert severity="warning" data-testid="ad-hoc-yttrande-alert">
							<AlertTitle>{t('adHocYttrandeAlert.title')}</AlertTitle>
							<Stack spacing={2}>
								<Typography>{t('adHocYttrandeAlert.description')}</Typography>
								<Box>
									<Link
										href={`/medarbetare/csn/ad-hoc-yttrande/redigera/${adHocYttrandeId}`}
										underline="hover"
										target="_blank"
										rel="noopener noreferrer"
									>
										{t('adHocYttrandeAlert.linkText')}
									</Link>
								</Box>
							</Stack>
						</Alert>
					)}
				</Loading>
				{selectedStudiestodAnsokan?.isLegacyAnsokan && (
					<Alert data-testid="nya-avtalet-highlight-box" severity="warning" icon={false}>
						<AlertTitle>
							<Typography variant="h4">{t('preNyaAvtaletHighlightBox.title')}</Typography>
						</AlertTitle>
						{t('preNyaAvtaletHighlightBox.description')}
					</Alert>
				)}

				<SubmitResponse apiResponse={apiResponse} failureMessage={t('toaster.error')} successMessage={t('toaster.success')} />

				{koppladAnsokan && (
					<Alert severity="info">
						<Typography>
							{t('headers.koppladTillStudieAnsokan')}
							<Typography component="span" fontWeight="fontWeightBold" ml={1}>
								{getStudiestodLabel(koppladAnsokan.studiestodNummer, koppladAnsokan.ansokanNamn)}
							</Typography>
						</Typography>
					</Alert>
				)}

				<Separator />
			</Stack>
		</Stack>
	)
}

export default KopplaBegaranYttrande
