import { SearchYttrandenRequestApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded'
import { Box, Button, Typography } from '@mui/material'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	searchFilter: SearchYttrandenRequestApiModel
	setSearchFilter: Dispatch<SetStateAction<SearchYttrandenRequestApiModel>>
}

const SwapSortButton = ({ searchFilter, setSearchFilter }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'csnPortal.yttranden.yttrandenTableSorting.swapSortButton' })
	const [orderByDesc, setOrderByDesc] = useState(searchFilter?.orderBy === 'CreatedAt desc' ? true : false)
	return (
		<Box>
			<Button
				variant="text"
				onClick={() => {
					const newOrderByDesc = !orderByDesc
					setOrderByDesc(newOrderByDesc)
					setSearchFilter({ ...searchFilter, orderBy: `CreatedAt ${newOrderByDesc ? 'desc' : 'asc'}` })
				}}
				data-testid="yttrande-tab-swap-sort-button"
			>
				<Typography variant="body2" sx={{ color: 'black' }}>
					{t('createdDate')} - {t('orderBy', { context: orderByDesc ? 'desc' : 'asc' })}
				</Typography>
				{orderByDesc ? <KeyboardArrowDownRoundedIcon sx={{ color: 'black' }} /> : <KeyboardArrowUpRoundedIcon sx={{ color: 'black' }} />}
			</Button>
		</Box>
	)
}
export default SwapSortButton
