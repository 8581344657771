import { useDebouncedEffect } from '@local/src/Hooks'
import { Stack, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	setSearchQuery: React.Dispatch<React.SetStateAction<string>>
}

const SearchBar = ({ setSearchQuery }: Props) => {
	const [searchTerm, setSearchTerm] = useState('')
	const { t } = useTranslation('translation', { keyPrefix: 'csnPortal.utbildningarFromCsn.searchBar' })

	useDebouncedEffect(
		() => {
			setSearchQuery(searchTerm)
		},
		300,
		[searchTerm]
	)

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value)

	return (
		<Stack display="flex" alignItems="center" gap={1} mb={6}>
			<TextField placeholder={t('placeholder')} label={t('label')} onChange={handleChange} fullWidth />
		</Stack>
	)
}
export default SearchBar
