import { GoBackButton } from '@local/src/Components'
import { useCommonTranslation } from '@local/src/Hooks'
import { UserTimestampApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { Box, Chip, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import UserTimeStampInfo from './UserTimeStampInfo'

const AdHocYttrandeState = {
	0: 'new',
	1: 'edit',
	2: 'sent',
} as const

interface Props {
	adHocYttrandeState: keyof typeof AdHocYttrandeState
	createdBy?: UserTimestampApiModel
	savedBy?: UserTimestampApiModel
	sentToCsnBy?: UserTimestampApiModel
}
const AdHocYttrandeHeader = ({ adHocYttrandeState, createdBy, savedBy, sentToCsnBy }: Props) => {
	const currentState = AdHocYttrandeState[adHocYttrandeState]
	const { t } = useTranslation('translation', { keyPrefix: 'adHocYttrande.header' })
	const { tCommon } = useCommonTranslation()

	const isCreated = adHocYttrandeState > 0
	const isSentToCsn = adHocYttrandeState > 1

	return (
		<Box>
			<Box mb={2}>
				<GoBackButton />
			</Box>
			<Stack direction="row" justifyContent="space-between">
				<Typography data-testid={`ad-hoc-yttrande-header-${currentState}`} variant="h4" component="div" gutterBottom>
					{t('title', { context: currentState })}
				</Typography>
				{isCreated && (
					<Chip
						data-testid={`ad-hoc-yttrande-status-chip-${isSentToCsn ? 'Sent' : 'Edit'}`}
						variant={isSentToCsn ? 'status-success' : 'status-info'}
						label={isSentToCsn ? tCommon('status.csnFormState_sent') : tCommon('status.csnFormState_edit')}
					/>
				)}
			</Stack>
			<Grid container spacing={1}>
				<Grid item xs={4}>
					<UserTimeStampInfo headerText={t('timestamp_created')} actionType="skapad" actionDone={isCreated} userTimeStamp={createdBy} />
				</Grid>
				<Grid item xs={4}>
					<UserTimeStampInfo headerText={t('timestamp_changed')} actionType="sparad" actionDone={isCreated} userTimeStamp={savedBy} />
				</Grid>
				<Grid item xs={4}>
					<UserTimeStampInfo
						headerText={t('timestamp_sentToCsn')}
						actionType="skickad"
						actionDone={isSentToCsn}
						userTimeStamp={sentToCsnBy}
					/>
				</Grid>
			</Grid>
		</Box>
	)
}
export default AdHocYttrandeHeader
