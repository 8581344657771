import { Box, BoxProps, styled } from '@mui/material'
import React from 'react'

interface StyledTableEntryProps extends BoxProps {
	isNested?: boolean
}

export const StyledTableEntry = styled(({ isNested, ...props }: StyledTableEntryProps) => <Box {...props} />)(({ theme, isNested }) => ({
	width: '250px',
	p: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		fontSize: '1rem',
		lineHeight: 2,
	},
	paddingLeft: isNested ? theme.spacing(2) : 0,
}))
