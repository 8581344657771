const formatPlanePersonnummer = (personnummer?: string): string => {
	if (!personnummer) {
		return ''
	}
	if (personnummer.length !== 12) {
		return 'Fel format på personnummer.'
	}
	const insert = '-'
	const position = 8
	return [personnummer.slice(0, position), insert, personnummer.slice(position)].join('')
}

export default formatPlanePersonnummer
