import { JaNejRadioButtonGroup } from '@local/src/Pages/Common/Fragments'
import React from 'react'
import { useTranslation } from 'react-i18next'

export interface Props {
	isReadOnly?: boolean
	name: string
}

const KompletterandeBidrag = ({ name, isReadOnly }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'components.yttranden.labels' })

	return <JaNejRadioButtonGroup valueType="boolean" header={t('kompletterandeBidrag')} name={name} disabled={isReadOnly} />
}

export default KompletterandeBidrag
