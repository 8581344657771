import { format, isValid } from 'date-fns'
import { isNil } from 'ramda'

export const formatDateTime = (dateTime: string | Date | undefined) => {
	if (isNil(dateTime)) return 'No time value'
	return format(new Date(dateTime), 'yyyy-MM-dd HH:mm')
}

export const formatDate = (dateTime: string | Date) => format(new Date(dateTime), 'yyyy-MM-dd')
export const formatDateNull = (dateTime: string | Date) => (isValidDate(dateTime) ? formatDate(dateTime) : null)

export const isValidDate = (dateTime: string | Date) => {
	if (isNil(dateTime)) return false
	return isValid(new Date(dateTime))
}
export const isInvalidDate = (dateTime: string | Date) => !isValidDate(dateTime)

export const currentYear = new Date().getFullYear()
