import SwapSortButton from '@local/src/Pages/CsnPortal/Yttranden/Components/SwapSortButton/SwapSortButton'
import { SearchYttrandenRequestApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	yttrandeListLength: number
	searchResultTotalCount: number
	searchFilter: SearchYttrandenRequestApiModel
	handleChangePageSize: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<number | string>) => void
	rowsPerPageOptions: number[]
	setSearchFilter: React.Dispatch<React.SetStateAction<SearchYttrandenRequestApiModel>>
}

const YttrandenTableSorting = ({
	yttrandeListLength,
	searchResultTotalCount,
	searchFilter,
	handleChangePageSize,
	rowsPerPageOptions,
	setSearchFilter,
}: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'csnPortal.yttranden.yttrandenTableSorting' })
	const amountOfYttrandenText = `${t('yttrandeAmount', { count: yttrandeListLength })} av ${searchResultTotalCount}`

	return (
		<Stack direction={'row'} justifyContent="space-between" alignItems="center">
			<Typography variant="body2" padding={1}>
				{amountOfYttrandenText}
			</Typography>
			<Stack spacing={2} direction="row">
				<Stack spacing={1} direction="row" alignItems="center">
					<Typography variant="body2">{t('yttrandenPerPage')}</Typography>
					<Select
						variant="standard"
						disableUnderline
						defaultValue={searchFilter.pageSize}
						value={searchFilter.pageSize}
						onChange={handleChangePageSize}
						data-testid="yttranden-per-page"
						renderValue={(value) => (
							<Typography variant="body2" paddingLeft={1} paddingTop="2px">
								{value}
							</Typography>
						)}
					>
						{rowsPerPageOptions.map((item) => (
							<MenuItem key={item} value={item}>
								{item}
							</MenuItem>
						))}
					</Select>
				</Stack>
				<SwapSortButton searchFilter={searchFilter} setSearchFilter={setSearchFilter} />
			</Stack>
		</Stack>
	)
}

export default YttrandenTableSorting
