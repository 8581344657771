import PageWrapper from '@local/src/Components/PageWrapper/PageWrapper'
import { useGetUtbildningarFromCsn } from '@local/src/Hooks/API'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import UtbildningarTable from './Components/UtbildningarTable/UtbildningarTable'

const UtbildningarFromCsnTab = () => {
	const { t } = useTranslation('translation', { keyPrefix: 'csnPortal.utbildningarFromCsn' })
	const [searchQuery, setSearchQuery] = useState('')

	const { utbildningar, isLoading, isFetching } = useGetUtbildningarFromCsn({ searchTerm: searchQuery })

	return (
		<PageWrapper isLoading={isLoading} headerText={t('title')}>
			<UtbildningarTable isRefetching={isFetching} data={utbildningar} searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
		</PageWrapper>
	)
}

export default UtbildningarFromCsnTab
