import { LabelValue } from '@local/src/Components'
import { useCommonTranslation, useEpiContentWithFallback } from '@local/src/Hooks'
import { CsnYttrandeBeslutApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { formatDateTime } from '@local/src/Utils/dateFormatHelper'
import { renderValueOrPlaceholderText } from '@local/src/Utils/renderValueOrPlaceholderText'
import { Box, Link, Stack, Typography } from '@mui/material'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	beslut: CsnYttrandeBeslutApiModel
}

const CommonKlientInformation = ({ beslut }: Props) => {
	const { beslut: content } = useEpiContentWithFallback()
	const { t } = useTranslation('translation', { keyPrefix: 'csnPortal.beslut.tabs.common' })
	const { tCommon } = useCommonTranslation()

	const studiestodAnsokanNamn = beslut?.studiestodMeta?.studiestodNummer
		? `#${beslut?.studiestodMeta?.studiestodNummer} - ${beslut?.studiestodMeta?.ansokanNamn}`
		: beslut?.studiestodMeta?.ansokanNamn

	return (
		<Fragment>
			<Stack direction={{ xs: 'column', sm: 'row' }}>
				<Box flex={1}>
					<LabelValue
						label={tCommon('glossary.namn')}
						value={`${renderValueOrPlaceholderText(
							beslut?.userProfileCsnBeslut?.firstName,
							t('noValuePlaceholder')
						)} ${renderValueOrPlaceholderText(beslut?.userProfileCsnBeslut?.lastName, t('noValuePlaceholder'))}`}
					/>
				</Box>
				<Box flex={1}>
					<LabelValue
						label={tCommon('glossary.personnummer')}
						value={renderValueOrPlaceholderText(beslut?.userProfileCsnBeslut?.personnummer, t('noValuePlaceholder'))}
					/>
				</Box>
				<Box flex={1}>
					<LabelValue
						label={t('studiestodansokanNamn')}
						value={
							beslut?.studiestodMeta ? (
								<Link
									target="_blank"
									rel="noopener noreferrer"
									href={`${content.ansokanBaseLink}${beslut?.studiestodMeta?.arendeId}/studier/ansokan/${beslut?.studiestodMeta?.ansokanId}`}
									data-testid="ansokan-link"
								>
									<Stack direction="row" alignContent={'center'} spacing={1}>
										<Typography>{studiestodAnsokanNamn}</Typography>
									</Stack>
								</Link>
							) : (
								t('noValuePlaceholder')
							)
						}
					/>
				</Box>
			</Stack>
			<Stack direction={{ xs: 'column', sm: 'row' }}>
				<Box flex={1}>
					<LabelValue label={'Inkom från CSN'} value={formatDateTime(beslut.inkomFranCsn)} />
				</Box>
				<Box flex={1}>
					<LabelValue
						label={'Beslut senast uppdaterat'}
						value={beslut.beslutSenastUppdaterat === null ? null : formatDateTime(beslut.beslutSenastUppdaterat)}
					/>
				</Box>
				<Box flex={1} />
			</Stack>
		</Fragment>
	)
}

export default CommonKlientInformation
