import { Stack, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'

interface MarkeraForlangningProps {
	titleHeaderText: string
	subtitleHeaderText: string
	isDateExtended: boolean
}

export const MarkeraForlangningHeader = ({ titleHeaderText, subtitleHeaderText, isDateExtended }: MarkeraForlangningProps) => (
	<Stack>
		<Typography variant="subtitle1" gutterBottom={false} data-testid={'last-date-to-svara-date'}>
			{titleHeaderText}
		</Typography>
		{isDateExtended ? (
			<Typography variant="subtitle2" gutterBottom={false}>
				{subtitleHeaderText}
			</Typography>
		) : null}
	</Stack>
)

export const MarkeraForlangningForm = styled('form')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	'> div': {
		marginBottom: 0,
		marginRight: theme.spacing(1),
	},
}))
