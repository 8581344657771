import { Loading, StudieansokanBackendLink } from '@local/src/Components'
import { useCommonTranslation } from '@local/src/Hooks'
import { formatDateNull } from '@local/src/Utils/dateFormatHelper'
import { Card, CardContent, Grid2 as Grid, Skeleton, Typography } from '@mui/material'
import { isNil } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	clientName: string
	clientSsn: string
	ansokanId: string
	trrAnsokanDatum: string
}
const AdHocYttrandeInfo = ({ clientName, clientSsn, ansokanId, trrAnsokanDatum }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'adHocYttrande.info' })
	const { tCommon } = useCommonTranslation()

	return (
		<Card>
			<CardContent>
				<Typography variant="h6" component="div" gutterBottom>
					{t('title')}
				</Typography>
				<Grid container spacing={1}>
					<Grid container size={12}>
						<Grid size={{ xs: 6 }} data-testid={'ad-hoc-yttrande-info-name'}>
							<Typography variant="subtitle2">{tCommon('glossary.namn')}</Typography>
							<Typography variant="subtitle1">{clientName}</Typography>
						</Grid>
						<Grid size={{ xs: 6 }} data-testid={'ad-hoc-yttrande-info-ansokan'}>
							<Typography variant="subtitle2">{t('koppladTillStudieAnsokan')}</Typography>
							<StudieansokanBackendLink ansokanId={ansokanId} />
						</Grid>
					</Grid>

					<Grid container size={12}>
						<Grid size={{ xs: 6 }} data-testid={'ad-hoc-yttrande-info-personnummer'}>
							<Typography variant="subtitle2">{tCommon('glossary.personnummer')}</Typography>
							<Typography variant="subtitle1">{clientSsn}</Typography>
						</Grid>

						<Grid size={{ xs: 6 }} data-testid={'ad-hoc-yttrande-info-datumAnsokanOo'}>
							<Typography variant="subtitle2">{t('datumAnsokanOo')}</Typography>
							<Loading
								isLoading={isNil(trrAnsokanDatum)}
								fallback={
									<Skeleton>
										<Typography variant="subtitle1">XXXX-XX-XX</Typography>
									</Skeleton>
								}
							>
								<Typography variant="subtitle1">{formatDateNull(trrAnsokanDatum)}</Typography>
							</Loading>
						</Grid>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	)
}

export default AdHocYttrandeInfo
