import { RadioButtonGroup } from '@local/src/Components'
import { RadioButton } from '@local/src/Components/FormComponents/RadioButtonGroup/RadioButtonGroup'
import { StarksFramtidaStallning } from '@local/src/Services/SwaggerApi/data-contracts'
import { Box } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	starksFramtidaStallning: StarksFramtidaStallning
	name: string
	testId?: string
	isReadOnly?: boolean
}
const OrsakStarksFramtidaStallning = ({ starksFramtidaStallning, name, isReadOnly, testId }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'components.yttranden.orsakStarksFramtidaStallning' })
	const getRadioButtonItem = (value: number): RadioButton => ({
		label: t('option', { context: value }),
		value: value,
		disabled: isReadOnly,
	})

	const jaRadioButtons: RadioButton[] = Array.from([1, 2, 3, 4], (v) => getRadioButtonItem(v))
	const nejRadioButtons: RadioButton[] = Array.from([5, 6, 7], (v) => getRadioButtonItem(v))
	const getRadioButtons = starksFramtidaStallning === StarksFramtidaStallning.Ja ? jaRadioButtons : nejRadioButtons
	const hideOrsak = !starksFramtidaStallning || starksFramtidaStallning === StarksFramtidaStallning.Avstar
	if (hideOrsak) return null
	return (
		<Box
			p={2}
			sx={(theme) => ({
				background: theme.palette.grey[100],
				legend: {
					fontWeight: theme.typography.fontWeightBold,
				},
			})}
			data-testid={testId}
		>
			<RadioButtonGroup label={t('label')} name={name} valueType="number" radios={getRadioButtons} isReadOnly={isReadOnly} spacing={2} />
		</Box>
	)
}

export default OrsakStarksFramtidaStallning
