import { StarksFramtidaStallning } from '@local/Services/SwaggerApi/data-contracts'
import { YttrandeFormModel } from '@local/src/Pages/BegaranYttrande/Edit/Components/BegaranYttrandeSvarForm/Models/BegaranYttrandeSvarForm.types'
import {
	SvarUtbildningSchema,
	SvarUtbildningSchemaAvstar,
} from '@local/src/Pages/BegaranYttrande/Edit/Components/BegaranYttrandeSvarForm/Schemas/SvarUtbildningSchema'
import { OrsakMotiveringSchema, orsakMotiveringRequired } from '@local/src/Pages/Common/Schemas'
import OrsakStarksFramtidaStallningSchema, {
	starksFramtidaStallningAvstar,
} from '@local/src/Pages/Common/Schemas/OrsakStarksFramtidaStallningSchema'
import { kompletterandeBidragValidationText, requiredValidationText } from '@local/src/Utils/YupHelper'
import { SchemaOf, array as yupArray, boolean as yupBoolean, mixed as yupMixed, object as yupObject, string as yupString } from 'yup'

export const YttrandeSchema: SchemaOf<YttrandeFormModel> = yupObject({
	kompletterandeBidrag: yupBoolean()
		.nullable()
		.required(requiredValidationText)
		.when('starksFramtidaStallning', {
			is: StarksFramtidaStallning.Nej,
			then: (kompletterandeBidrag) =>
				kompletterandeBidrag.test(
					'kompletterandeBidrag',
					kompletterandeBidragValidationText,
					(kompletterandeBidrag) => kompletterandeBidrag === false
				),
		}),
	starksFramtidaStallning: yupMixed().oneOf<StarksFramtidaStallning>([1, 2, 3]).required(requiredValidationText),
	orsakStarksFramtidaStallning: OrsakStarksFramtidaStallningSchema,
	orsakMotivering: yupString().nullable().when('orsakStarksFramtidaStallning', {
		is: orsakMotiveringRequired,
		then: OrsakMotiveringSchema,
		otherwise: yupString().undefinedOrNullSchema(),
	}),
	datumAnsokanOo: yupString()
		.nullable()
		.when('starksFramtidaStallning', {
			is: starksFramtidaStallningAvstar,
			then: yupString().undefinedOrNullSchema(),
			otherwise: (schema) => schema.required('Inget datum kunde hittas för att begäran yttrandet är inte kopplad till en studieansökan.'),
		}),
	utbildningar: yupArray()
		.required()
		.of(SvarUtbildningSchema)
		.when('starksFramtidaStallning', {
			is: starksFramtidaStallningAvstar,
			then: (schema) => schema.of(SvarUtbildningSchemaAvstar),
		}),
}).nullable()
