import { Loading, StudieansokanLink } from '@local/src/Components'
import { useGetSimpleStudiestod } from '@local/src/Hooks/API'
import { Skeleton, Typography } from '@mui/material'
import { isNotNil } from 'ramda'
import React from 'react'

interface Props {
	noLinkFoundText?: string
	ansokanId: string
}

const StudieansokanBackendLink = ({ ansokanId, noLinkFoundText }: Props) => {
	const { simpleStudiestodData, isLoading } = useGetSimpleStudiestod(ansokanId)

	return (
		<Loading
			isLoading={isLoading && isNotNil(ansokanId)}
			fallback={
				<Skeleton>
					<Typography component="span">xxxxxxxxxxxxxxxxxx</Typography>
				</Skeleton>
			}
		>
			<StudieansokanLink
				ansokanNamn={simpleStudiestodData?.ansokanNamn}
				ansokanId={simpleStudiestodData?.ansokanId}
				arendeId={simpleStudiestodData?.arendeId}
				noLinkFoundText={noLinkFoundText}
				studiestodNummer={simpleStudiestodData?.studiestodNummer}
			/>
		</Loading>
	)
}

export default StudieansokanBackendLink
