import { ApiResponseModel } from '@local/Services/ApiResponseModel'
import { pushFeedback } from '@local/src/Components/Toaster/Toaster'
import { Portal } from '@mui/material'
import { useIsFeatureEnabled } from '@trr/app-shell-data'
import { useSnackbar } from 'notistack'
import React, { HTMLAttributes, useEffect, useState } from 'react'

import ErrorResponse from './ErrorResponse'
import SubmitResponseAlert from './SubmitResponseAlert'

interface Props {
	apiResponse: ApiResponseModel<unknown>
	successMessage?: string
	failureMessage?: string
	hideApiResponseErrorAlert?: boolean
}

const SubmitResponse = ({
	apiResponse,
	successMessage = 'Anropet lyckades',
	failureMessage = 'Anropet misslyckades',
	hideApiResponseErrorAlert = false,
}: Props) => {
	const isSnackbarEnabled = useIsFeatureEnabled('CSN-portalen_snackbar_temp_241119') //specific featureflag for CSN
	const { enqueueSnackbar } = useSnackbar()
	const [showErrorAlert, setShowErrorAlert] = useState(false)

	const csnPortalContainer = document.getElementById('csn-fe')
	const portalContainer = document.createElement('div')
	csnPortalContainer?.insertBefore(portalContainer, csnPortalContainer.firstChild)

	useEffect(() => {
		if (apiResponse !== undefined) {
			const isSuccess = apiResponse.isSuccess
			const message = isSuccess ? successMessage : failureMessage

			if (isSnackbarEnabled) {
				if (isSuccess) {
					enqueueSnackbar({
						message,
						SnackbarProps: { 'data-testid': 'snackbar-success' } as HTMLAttributes<HTMLDivElement>,
					})
				} else {
					setShowErrorAlert(true)
					if (csnPortalContainer) {
						portalContainer.scrollIntoView({ behavior: 'smooth', block: 'center' })
					}
				}
			} else {
				pushFeedback({
					body: message,
					testSelector: isSuccess ? 'toaster-confirmation' : 'toaster-error',
					isSuccess: isSuccess,
				})
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [apiResponse])

	if (!apiResponse || hideApiResponseErrorAlert || apiResponse.isSuccess) return <></>

	return isSnackbarEnabled ? (
		showErrorAlert && (
			<Portal container={portalContainer}>
				<SubmitResponseAlert apiResponse={apiResponse} onClose={setShowErrorAlert} />
			</Portal>
		)
	) : (
		<ErrorResponse error={apiResponse.error} />
	)
}

export default SubmitResponse
