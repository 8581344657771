import { AdHocYttrandeKoptUtbildningApiModel } from '@local/Services/SwaggerApi/data-contracts'
import { Dropdown, RangeDropdown } from '@local/src/Components/FormComponents/Dropdown'
import { NumberInputField } from '@local/src/Components/FormComponents/NumberInputField'
import { useAdHocYttrandeFormContext } from '@local/src/Pages/AdHocYttrande/Hooks'
import { currentYear } from '@local/src/Utils/dateFormatHelper'
import { Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Path } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface Props {
	index: number
}

export const KoptUtbildningForm = ({ index }: Props) => {
	const {
		formState: { errors },
		setValue,
		watch,
		isReadOnly,
	} = useAdHocYttrandeFormContext()
	const currentErrors = errors.utbildningar?.[index]?.koptUtbildning
	const currentKoptUtbildning = watch(`utbildningar.${index}.koptUtbildning`)
	const currentKoptUtbildningPath = `utbildningar[${index}].koptUtbildning`

	const [selectedYear, setSelectedYear] = useState(
		currentKoptUtbildning?.starthalvar ? Number(currentKoptUtbildning?.starthalvar.substring(0, 4)) : currentYear
	)

	const { t } = useTranslation('translation', { keyPrefix: 'adHocYttrande.form.utbildningar.form.kopt' })
	const starthalvarDropdownOptions = [
		{
			label: t('starthalvar.option_vartermin'),
			value: '01',
		},
		{
			label: t('starthalvar.option_hosttermin'),
			value: '07',
		},
	]
	const [selectedTerm, setSelectedTerm] = useState(starthalvarDropdownOptions[0].value)

	useEffect(() => {
		setValue(`utbildningar.${index}.koptUtbildning.starthalvar`, `${selectedYear}${selectedTerm}`)
	}, [selectedYear, selectedTerm, setValue, index])

	const getKoptUtbildningPath = (path: Path<AdHocYttrandeKoptUtbildningApiModel>) => `${currentKoptUtbildningPath}.${path}`

	return (
		<Stack spacing={3} maxWidth="66%">
			<Typography fontWeight="bold">{t('title')}</Typography>
			<NumberInputField name={getKoptUtbildningPath('antalKoptaVeckor')} label={t('labels.antalKoptaVeckor')} disabled={isReadOnly} />
			<NumberInputField
				name={getKoptUtbildningPath('omfattningKoptaVeckor')}
				label={t('labels.omfattningKoptaVeckor')}
				disabled={isReadOnly}
			/>
			<Stack spacing={2} direction={{ xs: 'column', md: 'row' }}>
				<RangeDropdown
					rangeFrom={currentYear}
					rangeLength={5}
					label={t('labels.startAr')}
					errorMsg={currentErrors?.starthalvar?.message}
					setValue={setSelectedYear}
					value={selectedYear}
					isReadOnly={isReadOnly}
					testId={`utbildningar.${index}.koptUtbildning.startAr`}
				/>
				<Dropdown
					selectOptions={starthalvarDropdownOptions}
					label={t('starthalvar.label')}
					errorMsg={currentErrors?.starthalvar?.message}
					onChange={setSelectedTerm}
					value={selectedTerm}
					isReadOnly={isReadOnly}
					testId={`utbildningar.${index}.koptUtbildning.startTermin`}
				/>
			</Stack>
		</Stack>
	)
}
