import { Box, Stack, Typography, styled } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

const StyledBox = styled(Box)(() => ({
	width: '250px',
}))

const TableHeader = () => {
	const { t } = useTranslation('translation', { keyPrefix: 'csnPortal.beslut.tabs.historik.tableHeaders' })
	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			sx={(theme) => ({
				background: theme.palette.primary.main,
				color: 'white',
			})}
		>
			<Box width="150px">
				<Typography variant="h6">{t('datumOchTid')}</Typography>
			</Box>
			<StyledBox>
				<Typography variant="h6">{t('uppgift')}</Typography>
			</StyledBox>
			<StyledBox>
				<Typography variant="h6">{t('nyttVarde')}</Typography>
			</StyledBox>
			<StyledBox>
				<Typography variant="h6">{t('tidigareVarde')}</Typography>
			</StyledBox>
		</Stack>
	)
}

export default TableHeader
