import { CsnRegisterUtbildningApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { Box } from '@mui/material'
import {
	createColumnHelper,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable,
} from '@tanstack/react-table'
import React from 'react'

import { Pagination, SearchBar, SearchResultWrapper } from './Components'
import Table from './TableComponents'

const columnHelper = createColumnHelper<CsnRegisterUtbildningApiModel>()

const utbildningarColumns = [
	columnHelper.accessor('utbildningsnamn', {
		header: 'Utbildningsnamn',
	}),
	columnHelper.accessor('skolnamn', {
		header: () => 'Skolnamn',
	}),
	columnHelper.accessor('utbildningskod', {
		header: 'Utbildningskod',
	}),
	columnHelper.accessor('utbildningsort', {
		header: () => 'Ort',
	}),
]

interface Props {
	data: CsnRegisterUtbildningApiModel[]
	searchQuery: string
	setSearchQuery: React.Dispatch<React.SetStateAction<string>>
	isRefetching: boolean
}

const UtbildningarTable = ({ data, searchQuery, setSearchQuery, isRefetching }: Props) => {
	const [sorting, setSorting] = React.useState<SortingState>([])
	const table = useReactTable({
		data,
		columns: utbildningarColumns,
		getCoreRowModel: getCoreRowModel(),
		state: { sorting },
		getFilteredRowModel: getFilteredRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		onSortingChange: setSorting,
		getSortedRowModel: getSortedRowModel(),
	})

	const { getHeaderGroups, getRowModel } = table

	const renderEmptySearchInformation = searchQuery.length === 0 || data?.length === 0 || !data

	return (
		<Box
			p={2}
			mt={2}
			sx={(theme) => ({
				border: `1px solid ${theme.palette.grey[400]}`,
			})}
		>
			<SearchBar setSearchQuery={setSearchQuery} />
			<SearchResultWrapper isRefetching={isRefetching} showNoResult={renderEmptySearchInformation}>
				<Table.Container>
					<Table.Head getHeaderGroups={getHeaderGroups} />
					<Table.Body getRowModel={getRowModel} />
				</Table.Container>
				<Pagination table={table} totalCount={data?.length} />
			</SearchResultWrapper>
		</Box>
	)
}

export default UtbildningarTable
