import { AdHocYttrandeEjCsnUtbildningApiModel } from '@local/Services/SwaggerApi/data-contracts'
import {
	emailValidationText,
	maxLengthValidationText,
	minLengthValidationText,
	onlyNumbersValidationText,
	phoneNumberValidationText,
	requiredValidationText,
	specificLengthValidationText,
} from '@local/Utils/YupHelper'
import { SchemaOf, number as yupNumber, object as yupObject, string as yupString } from 'yup'

export const EjCsnUtbildningSchema: SchemaOf<AdHocYttrandeEjCsnUtbildningApiModel> = yupObject({
	ort: yupString()
		.required(requiredValidationText)
		.max(20, maxLengthValidationText)
		.matches(/^[a-zåäöA-ZÅÄÖÀ-ú\-\s]+$/, 'Får bara innehålla bokstäver, mellanslag och bindestreck')
		.nullable(),
	utbildningsanordnare: yupString().required(requiredValidationText).max(40, maxLengthValidationText).nullable(),
	utbildningsnamn: yupString().required(requiredValidationText).max(60, maxLengthValidationText).nullable(),
	antalVeckor: yupNumber().required(requiredValidationText).min(1, minLengthValidationText).max(999, maxLengthValidationText).nullable(),
	omfattning: yupNumber().required(requiredValidationText).min(1, minLengthValidationText).max(100, maxLengthValidationText).nullable(),
	organisationsnamn: yupString().required(requiredValidationText).max(100, maxLengthValidationText).nullable(),
	organisationsnummer: yupString()
		.required(requiredValidationText)
		.length(10, specificLengthValidationText)
		.matches(/^\d+$/, onlyNumbersValidationText)
		.nullable(),
	kontaktperson: yupString().required(requiredValidationText).max(40, maxLengthValidationText).nullable(),
	epostadress: yupString().required(requiredValidationText).email(emailValidationText).max(254, maxLengthValidationText).nullable(),
	telefonnummer: yupString()
		.required(requiredValidationText)
		.max(18, maxLengthValidationText)
		.matches(/^[0-9\-+]+$/, phoneNumberValidationText)
		.nullable(),
}).nullable()

export default EjCsnUtbildningSchema
