import { HighlightBox } from '@local/src/Components'
import Icons from '@local/src/Components/Icons'
import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, Stack, Tooltip } from '@mui/material'
import { themeOptions } from '@trr/mui-theme'
import React, { useState } from 'react'

declare type MessageType = 'error' | 'warning' | 'confirmation' | 'info'
interface Props {
	content: React.ReactNode
	type: MessageType
	hideCloseButton?: boolean
	testSelector?: string
}

interface SystemFeedbackBgColorAndIcon {
	bgColor: keyof typeof themeOptions.palette.surface
	icon: JSX.Element
}

type SystemFeedbackType = {
	[x in MessageType]: SystemFeedbackBgColorAndIcon
}

const systemFeedbackType: SystemFeedbackType = {
	error: {
		bgColor: 'pink',
		icon: <Icons.Error />,
	},
	warning: {
		bgColor: 'orange',
		icon: <Icons.Warning />,
	},
	info: {
		bgColor: 'blue',
		icon: <Icons.Info />,
	},
	confirmation: {
		bgColor: 'green',
		icon: <Icons.Success />,
	},
}

const SystemFeedback = ({ type, testSelector, hideCloseButton, content }: Props) => {
	const { bgColor, icon } = systemFeedbackType[type]
	const [isHidden, setIsHidden] = useState(false)

	return (
		<HighlightBox
			sx={{ display: isHidden ? 'none' : 'block', mb: 2 }}
			backgroundcolor={bgColor}
			spacing={2}
			breakpointspacing={2}
			data-testid={testSelector}
		>
			<Stack direction="row" justifyContent="space-between">
				<Stack direction="row" spacing={2} alignItems="center">
					{icon}
					<Box>{content}</Box>
				</Stack>
				{!hideCloseButton && (
					<Tooltip title="Stäng">
						<IconButton sx={{ p: 0 }} onClick={() => setIsHidden(true)} color="inherit">
							<CloseIcon />
						</IconButton>
					</Tooltip>
				)}
			</Stack>
		</HighlightBox>
	)
}

export default SystemFeedback
