import { AdHocYttrandeEjCsnUtbildningApiModel } from '@local/Services/SwaggerApi/data-contracts'
import { TextInputField } from '@local/src/Components'
import { NumberInputField } from '@local/src/Components/FormComponents/NumberInputField'
import { useCommonTranslation } from '@local/src/Hooks'
import { useAdHocYttrandeFormContext } from '@local/src/Pages/AdHocYttrande/Hooks'
import { Stack, Typography } from '@mui/material'
import React from 'react'
import { Path } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface Props {
	index: number
}
export const EjCsnUtbildningForm = ({ index }: Props) => {
	const currentEjCsnUtbildningPath = `utbildningar[${index}].ejCsnUtbildning`
	const getEjCsnUtbildningPath = (path: Path<AdHocYttrandeEjCsnUtbildningApiModel>) => `${currentEjCsnUtbildningPath}.${path}`
	const { isReadOnly } = useAdHocYttrandeFormContext()
	const { t } = useTranslation('translation', { keyPrefix: 'adHocYttrande.form.utbildningar.form.ejCsn' })

	const { tCommon } = useCommonTranslation()
	return (
		<Stack spacing={5}>
			<Stack spacing={3} maxWidth="66%">
				<Typography fontWeight="bold">{t('titles.utbildningenOchOrt')}</Typography>
				<TextInputField
					label={tCommon('glossary.utbildningsnamn')}
					name={getEjCsnUtbildningPath('utbildningsnamn')}
					disabled={isReadOnly}
				/>
				<TextInputField
					label={tCommon('glossary.utbildningsanordnare')}
					name={getEjCsnUtbildningPath('utbildningsanordnare')}
					disabled={isReadOnly}
				/>
				<TextInputField label={t('labels.ort')} name={getEjCsnUtbildningPath('ort')} disabled={isReadOnly} />
			</Stack>
			<Stack spacing={3} maxWidth="33%">
				<Typography fontWeight="bold">{t('titles.utbildningensLangd')}</Typography>
				<NumberInputField label={t('labels.antalVeckor')} name={getEjCsnUtbildningPath('antalVeckor')} disabled={isReadOnly} />
				<NumberInputField label={t('labels.omfattning')} name={getEjCsnUtbildningPath('omfattning')} disabled={isReadOnly} />
			</Stack>
			<Stack spacing={3} maxWidth="66%">
				<Typography fontWeight="bold">{t('titles.organisationen')}</Typography>
				<TextInputField label={t('labels.organisationsnamn')} name={getEjCsnUtbildningPath('organisationsnamn')} disabled={isReadOnly} />
				<TextInputField
					label={t('labels.organisationsnummer')}
					name={getEjCsnUtbildningPath('organisationsnummer')}
					disabled={isReadOnly}
				/>
			</Stack>
			<Stack spacing={3} maxWidth="66%">
				<Typography fontWeight="bold">{t('titles.kontaktpersonOchKontaktuppgifter')}</Typography>
				<TextInputField label={t('labels.kontaktperson')} name={getEjCsnUtbildningPath('kontaktperson')} disabled={isReadOnly} />
				<TextInputField label={t('labels.epostadress')} name={getEjCsnUtbildningPath('epostadress')} disabled={isReadOnly} />
				<TextInputField label={t('labels.telefonnummer')} name={getEjCsnUtbildningPath('telefonnummer')} disabled={isReadOnly} />
			</Stack>
		</Stack>
	)
}
