import { StarksFramtidaStallning } from '@local/Services/SwaggerApi/data-contracts'
import { requiredValidationText } from '@local/src/Utils/YupHelper'
import { number as yupNumber } from 'yup'

const errorMessageJaRange = 'Måste vara mellan 1-4'
const errorMessageNejRange = 'Måste vara mellan 5-7'

export const starksFramtidaStallningJa = (starksFramtidaStallning: StarksFramtidaStallning) =>
	starksFramtidaStallning === StarksFramtidaStallning.Ja
export const starksFramtidaStallningNej = (starksFramtidaStallning: StarksFramtidaStallning) =>
	starksFramtidaStallning === StarksFramtidaStallning.Nej
export const starksFramtidaStallningAvstar = (starksFramtidaStallning: StarksFramtidaStallning) =>
	starksFramtidaStallning === StarksFramtidaStallning.Avstar

export const OrsakStarksFramtidaStallningSchema = yupNumber()
	.nullable()
	.when('starksFramtidaStallning', {
		is: starksFramtidaStallningJa,
		then: (schema) => schema.required(requiredValidationText).min(1, errorMessageJaRange).max(4, errorMessageJaRange),
	})
	.when('starksFramtidaStallning', {
		is: starksFramtidaStallningNej,
		then: (schema) => schema.required(requiredValidationText).min(5, errorMessageNejRange).max(7, errorMessageNejRange),
	})
	.when('starksFramtidaStallning', {
		is: starksFramtidaStallningAvstar,
		then: yupNumber().undefinedOrNullSchema(),
	})

export default OrsakStarksFramtidaStallningSchema
