import FormDropdown from '@local/src/Components/FormComponents/Dropdown/FormDropdown'
import { useCommonTranslation } from '@local/src/Hooks'
import { BegaranYttrandeSvarFormData } from '@local/src/Pages/BegaranYttrande/Edit/Components/BegaranYttrandeSvarForm/Models/BegaranYttrandeSvarForm.types'
import { usePostSvarBegaranYttrande } from '@local/src/Pages/BegaranYttrande/Hooks/usePostSvarBegaranYttrande'
import { ApiResponseModel } from '@local/src/Services/ApiResponseModel'
import { SvarBegaranYttrandeApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormHelperText, Stack } from '@mui/material'
import { isNotNil } from 'ramda'
import React, { Fragment, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface Props {
	setApiResponse: React.Dispatch<React.SetStateAction<ApiResponseModel<void>>>
}

const KundTillhorInteTrrAction = ({ setApiResponse }: Props) => {
	const {
		handleSubmit,
		formState: { errors },
		clearErrors,
	} = useFormContext<BegaranYttrandeSvarFormData>()

	const { tCommon } = useCommonTranslation()
	const { t } = useTranslation('translation', { keyPrefix: 'begaranYttrande.tillhor' })

	const { postSvarBegaranYttrandeMutation, apiResponse } = usePostSvarBegaranYttrande()
	const [openDialog, setOpenDialog] = useState(false)

	const formDataIsValid = isNotNil(errors)
	const handleOnOpenDialog = () => {
		setOpenDialog(true)
	}

	const handleOnCloseDialog = () => {
		setOpenDialog(false)
		clearErrors('kundTillhorTrr')
	}

	const onSubmit = async (formData: BegaranYttrandeSvarFormData) => {
		setOpenDialog(false)

		await postSvarBegaranYttrandeMutation.mutateAsync({
			grundansokanId: formData.grundansokanId,
			begaranId: formData.idBegaran,
			shouldSendToCsn: true,
			data: formData as SvarBegaranYttrandeApiModel,
		})
	}

	useEffect(() => {
		if (apiResponse) setApiResponse(apiResponse)
	}, [apiResponse, setApiResponse])

	const kundTillhorTrrOptions = [
		{
			label: t('kundTillhorInteTRRDialog.dropdown_arbetsgivareTillhorInteTrr'),
			value: 2,
		},
		{
			label: t('kundTillhorInteTRRDialog.dropdown_utforsakrad'),
			value: 3,
		},
	]

	return (
		<Fragment>
			<Button
				variant="contained"
				onClick={handleOnOpenDialog}
				data-testid="open-kund-tillhor-inte-trr-dialog-button"
				disabled={postSvarBegaranYttrandeMutation.isLoading}
			>
				{t('buttons.kundTillhorInteTrr')}
			</Button>
			<Dialog fullWidth open={openDialog} data-testid="begaran-yttrande-kund-tillhor-inte-trr-dialog" onClose={handleOnCloseDialog}>
				<DialogTitle>{t('kundTillhorInteTRRDialog.title')}</DialogTitle>
				<DialogContent>
					<Stack spacing={2}>
						<DialogContentText>{t('kundTillhorInteTRRDialog.description_utforsakrad')}</DialogContentText>
						{!formDataIsValid && <FormHelperText error>{t('invalidFormDataText')}</FormHelperText>}
						<FormDropdown name={'kundTillhorTrr'} options={kundTillhorTrrOptions} label={t('kundTillhorInteTRRDialog.dropdown_label')} />
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={handleOnCloseDialog} data-testid="dialog--cancel-button">
						{tCommon('buttons.cancel')}
					</Button>
					<Button disabled={!formDataIsValid} variant="contained" onClick={handleSubmit(onSubmit)} data-testid="dialog--confirm-button">
						{tCommon('buttons.confirm')}
					</Button>
				</DialogActions>
			</Dialog>
		</Fragment>
	)
}

export default KundTillhorInteTrrAction
