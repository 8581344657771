import {
	CsnBeslutsperiodBeslutApiModel,
	CsnUtbildningBeslutApiModel,
	CsnYttrandeBeslutApiModel,
} from '@local/src/Services/SwaggerApi/data-contracts'
import { formatDateTime } from '@local/src/Utils/dateFormatHelper'
import { formatCurrencySEK } from '@local/src/Utils/formatCurrencySek'
import { isNil } from 'ramda'
import { useTranslation } from 'react-i18next'

export type BeslutForandringPropName =
	| keyof CsnYttrandeBeslutApiModel
	| keyof CsnBeslutsperiodBeslutApiModel
	| keyof CsnUtbildningBeslutApiModel

export const useFormatProp = () => {
	const { t } = useTranslation('translation', { keyPrefix: 'common.glossary' })

	const formatProp = (andring: string | number, name: BeslutForandringPropName) => {
		if (isNil(andring)) return ''
		switch (name) {
			case 'godkandStudieforsakran':
			case 'koptUtbildning':
				return andring === 'True' ? t('ja') : t('nej')
			case 'arligInkomst':
			case 'arligInkomstMotsvarandeHeltidsarbete':
			case 'bidragsBelopp':
				return formatCurrencySEK(Number(andring))
			case 'omfattningBidrag':
				return `${andring}%`
			case 'tidpunktBeslutEllerUtbetalning': {
				return formatDateTime(andring as string)
			}
			default:
				return andring
		}
	}

	return { formatProp }
}
