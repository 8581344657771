import { SearchYttrandenRequestApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { Card, CardContent, Grid2 as Grid } from '@mui/material'
import React from 'react'

import { YttrandeFilterType, YttrandeFilterStatus, YttrandeFilterLastDateToSvara, YttrandeSokPersonnummer } from './Components'

interface Props {
	searchFilter: SearchYttrandenRequestApiModel
	setSearchFilterWithPage: (searchFilter: SearchYttrandenRequestApiModel) => void
}

const YttrandeFilterCard = ({ searchFilter, setSearchFilterWithPage }: Props) => (
	<Card>
		<CardContent>
			<Grid container spacing={2} direction={{ xs: 'column', sm: 'row' }}>
				<Grid container size={{ xs: 12, sm: 8 }} direction="column">
					<YttrandeFilterType searchFilter={searchFilter} setSearchFilterWithPage={setSearchFilterWithPage} />
					<YttrandeFilterStatus searchFilter={searchFilter} setSearchFilterWithPage={setSearchFilterWithPage} />
					<YttrandeFilterLastDateToSvara searchFilter={searchFilter} setSearchFilterWithPage={setSearchFilterWithPage} />
				</Grid>
				<Grid container size={{ xs: 12, sm: 4 }} mt={2}>
					<YttrandeSokPersonnummer searchFilter={searchFilter} setSearchFilterWithPage={setSearchFilterWithPage} />
				</Grid>
			</Grid>
		</CardContent>
	</Card>
)
export default YttrandeFilterCard
