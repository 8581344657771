import { SearchBeslutRequestApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { Box, Card, CardContent, Grid2 as Grid, Stack } from '@mui/material'
import React from 'react'

import { BeslutsutfallFilter, StudieforsakranFilter, PersonnummerFilter } from './Components'

interface Props {
	searchQuery: SearchBeslutRequestApiModel
	setSearchQuery: React.Dispatch<React.SetStateAction<SearchBeslutRequestApiModel>>
}

const CsnBeslutSearchFilter = ({ searchQuery, setSearchQuery }: Props) => (
	<Card>
		<CardContent>
			<Stack spacing={4}>
				<Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
					<Box flex={1}>
						<BeslutsutfallFilter searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
					</Box>
					<Box flex={1}>
						<StudieforsakranFilter searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
					</Box>
				</Stack>
				<Grid container>
					<Grid size={{ xs: 12, md: 4 }}>
						<PersonnummerFilter personnummerQuery={searchQuery.personnummer} setSearchQuery={setSearchQuery} />
					</Grid>
				</Grid>
			</Stack>
		</CardContent>
	</Card>
)

export default CsnBeslutSearchFilter
