import { Link, Typography } from '@mui/material'
import { isNotNil } from 'ramda'
import React from 'react'

interface Props {
	noLinkFoundText?: string
	ansokanNamn: string
	ansokanId: string
	arendeId: string
	studiestodNummer: number
}

const StudieansokanLink = ({ ansokanId, ansokanNamn, arendeId, studiestodNummer, noLinkFoundText = 'Ingen koppling finns' }: Props) => {
	const hasStudieansokanInfo = isNotNil(arendeId) && isNotNil(ansokanId) && isNotNil(ansokanNamn)

	const studiestodAnsokanNamn = studiestodNummer ? `#${studiestodNummer} - ${ansokanNamn}` : ansokanNamn

	if (hasStudieansokanInfo)
		return (
			<Link
				target="_blank"
				rel="noopener noreferrer"
				href={`/medarbetare/arenden/alla/arende/${arendeId}/studier/ansokan/${ansokanId}`}
				data-testid="ansokan-link"
			>
				{studiestodAnsokanNamn}
			</Link>
		)

	return <Typography color="text.secondary">{noLinkFoundText}</Typography>
}

export default StudieansokanLink
