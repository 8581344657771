import { studiestodMedarbetareApi } from '@local/src/Services/apiEndpointHelper'
import { apiGetTryCatchWrapper } from '@local/src/Services/apiRequestHelper'
import { useQuery } from '@tanstack/react-query'

export const useGetPublishedStudieansokanList = (arendeId: string, ejAktuell = false) => {
	const { data: studiestodAnsokanList, ...query } = useQuery({
		queryKey: ['getStudiestodAnsokanList', arendeId, ejAktuell],
		queryFn: async () => {
			const data = await apiGetTryCatchWrapper('useGetPublishedStudieansokanList', studiestodMedarbetareApi.arendeAnsokanDetail, {
				arendeId,
				ejAktuell,
			})
			return data.filter((s) => s.isPublicerad)
		},
		placeholderData: [],
		enabled: !!arendeId,
	})

	return { studiestodAnsokanList, ...query }
}
