import { FacetChip } from '@local/src/Components'
import useSearchYttrande from '@local/src/Pages/CsnPortal/Yttranden/Hooks/useSearchYttrande'
import { SearchYttrandenRequestApiModel, SearchYttrandenStatus } from '@local/src/Services/SwaggerApi/data-contracts'
import { Grid, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	searchFilter: SearchYttrandenRequestApiModel
	setSearchFilterWithPage: (searchFilter: SearchYttrandenRequestApiModel) => void
}
const YttrandeFacetStatus = ({ searchFilter, setSearchFilterWithPage }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'csnPortal.yttranden.yttrandeFilterCard.yttrandeFacetStatus' })

	const { searchResult } = useSearchYttrande({ ...searchFilter, searchYttrandenStatus: undefined, pageSize: 1, page: 1 })

	const statusFacets = searchResult?.facets?.find((f) => f.name === 'Status')?.facet || []
	const nyStatusCount = statusFacets.find((y) => y.name === '1')?.count ?? 0
	const underBedomningStatusCount = statusFacets.find((y) => y.name === '2')?.count ?? 0
	const skickadTillCsnStatusCount = statusFacets.find((y) => y.name === '3')?.count ?? 0
	const besvaradWithAdHocStatusCount = statusFacets.find((y) => y.name === '4')?.count ?? 0

	const handleStatusChipClick = (status: SearchYttrandenStatus) => {
		const toggledStatus = searchFilter.searchYttrandenStatus === status ? undefined : status
		setSearchFilterWithPage({ ...searchFilter, searchYttrandenStatus: toggledStatus })
	}

	return (
		<Grid item container direction="column" spacing={1}>
			<Grid item>
				<Typography>{t('title')}</Typography>
			</Grid>
			<Grid item container xs={3} spacing={1}>
				<Grid item>
					<FacetChip
						dataTestId={'ny-begaran-filter-chip'}
						label={t('nyFilterChipLabel', { count: nyStatusCount })}
						facet={SearchYttrandenStatus.Ny}
						selectedFacet={searchFilter.searchYttrandenStatus}
						onFacetClicked={handleStatusChipClick}
					/>
				</Grid>
				<Grid item>
					<FacetChip
						dataTestId={'under-bedomning-filter-chip'}
						label={t('underBedomningFilterChipLabel', { count: underBedomningStatusCount })}
						facet={SearchYttrandenStatus.UnderBedomning}
						selectedFacet={searchFilter.searchYttrandenStatus}
						onFacetClicked={handleStatusChipClick}
					/>
				</Grid>
				<Grid item>
					<FacetChip
						dataTestId={'skickad-till-csn-filter-chip'}
						label={t('skickadTillCsnFilterChipLabel', { count: skickadTillCsnStatusCount })}
						facet={SearchYttrandenStatus.SkickadTillCsn}
						selectedFacet={searchFilter.searchYttrandenStatus}
						onFacetClicked={handleStatusChipClick}
					/>
				</Grid>
				<Grid item>
					<FacetChip
						dataTestId={'skickad-till-csn-filter-chip'}
						label={t('besvaradWithAdHoc', { count: besvaradWithAdHocStatusCount })}
						facet={SearchYttrandenStatus.BesvaradWithAdhocYttrande}
						selectedFacet={searchFilter.searchYttrandenStatus}
						onFacetClicked={handleStatusChipClick}
					/>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default YttrandeFacetStatus
