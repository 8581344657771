import { RadioButton, RadioButtonGroup } from '@local/src/Components/FormComponents/RadioButtonGroup/RadioButtonGroup'
import SubmitResponse from '@local/src/Components/SubmitResponse/SubmitResponse'
import { useCurrentUser } from '@local/src/Hooks'
import SvaraAvstarAction from '@local/src/Pages/BegaranYttrande/EditTillhor/Components/TillhorSvarForm/Components/SvaraAvstarAction'
import { JaNejAvstarRadioButtonGroup } from '@local/src/Pages/Common/Fragments'
import { ApiResponseModel } from '@local/src/Services/ApiResponseModel'
import { Stack } from '@mui/material'
import React, { useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StarksFramtidaStallning } from '@local/src/Services/SwaggerApi/data-contracts'

import useBegaranYttrandeSvarForm from '../../../Edit/Components/BegaranYttrandeSvarForm/Hooks/useBegaranYttrandeSvarForm'
import { BegaranYttrandeSvarFormData } from '../../../Edit/Components/BegaranYttrandeSvarForm/Models/BegaranYttrandeSvarForm.types'

import KundTillhorInteTrrAction from './Components/KundTillhorInteTrrAction'

interface Props {
	defaultFormData: BegaranYttrandeSvarFormData
	isReadOnly?: boolean
}

const TillhorSvarForm = ({ defaultFormData, isReadOnly }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'begaranYttrande.tillhor' })

	const { userHasRole } = useCurrentUser()
	const [apiResponse, setApiResponse] = useState<ApiResponseModel<void>>(undefined)

	const hasValidRole = userHasRole(['HANDLAGGARE-STUDIER', 'KOORDINATOR-STUDIER', 'RADGIVNINGS-ADMINISTRATOR'])

	const formMethods = useBegaranYttrandeSvarForm(defaultFormData, isReadOnly)

	const showActionButtons = !isReadOnly && hasValidRole

	const kundTillhorRadioButtons: RadioButton[] = [
		{
			label: t('radioButtons.ja'),
			value: 1,
		},
		{
			label: t('radioButtons.arbetsgivareTillhorEjTrr'),
			value: 2,
		},
		{
			label: t('radioButtons.utforsakrad'),
			value: 3,
		},
	]

	return (
		<FormProvider {...formMethods}>
			<form data-testid="svar-begaran-yttrande-form">
				<Stack spacing={3}>
					{isReadOnly && (
						<Stack spacing={3}>
							<RadioButtonGroup
								label={t('radioButtons.kundTillhor')}
								name={'kundTillhorTrr'}
								valueType="number"
								radios={kundTillhorRadioButtons}
								isReadOnly={true}
								testId={'kund-tillhor-radio-button'}
							/>
							{defaultFormData?.yttrande?.starksFramtidaStallning === StarksFramtidaStallning.Avstar && (
								<JaNejAvstarRadioButtonGroup
									name="yttrande.starksFramtidaStallning"
									header={t('radioButtons.starksFramtidaStallning')}
									disabled
									testId="starks-framtidastallning-radio-button"
								/>
							)}
						</Stack>
					)}

					{showActionButtons && (
						<Stack direction="row" spacing={2}>
							<KundTillhorInteTrrAction setApiResponse={setApiResponse} />
							<SvaraAvstarAction defaultFormData={defaultFormData} setApiResponse={setApiResponse} />
						</Stack>
					)}

					<SubmitResponse apiResponse={apiResponse} />
				</Stack>
			</form>
		</FormProvider>
	)
}

export default TillhorSvarForm
