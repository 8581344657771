import { StarksFramtidaStallning, UtbildningSvarYttrandeApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { useCallback, useEffect } from 'react'
import { FieldPath, Path, useFormContext } from 'react-hook-form'

import { BegaranYttrandeSvarFormData, UtbildningSvarFormModel } from '../../../Models/BegaranYttrandeSvarForm.types'
import { ejCsnUtbildningRequired } from '../../../Schemas/EjCsnUtbildningSchema'

const useUtbildningFormContext = (index: number, franCsnUr: boolean) => {
	const methods = useFormContext<BegaranYttrandeSvarFormData>()

	const {
		watch,
		setValue,
		formState: { errors },
	} = methods
	const utbildningForm = watch(`yttrande.utbildningar.${index}`)
	const yttrande = watch('yttrande')
	const currentUtbildningPath = `yttrande.utbildningar[${index}]`
	const getUtbildningPath = (path: Path<UtbildningSvarYttrandeApiModel>) => `${currentUtbildningPath}.${path}`

	const getFormPathFor = useCallback(
		(prop: FieldPath<UtbildningSvarFormModel>): FieldPath<BegaranYttrandeSvarFormData> => `yttrande.utbildningar.${index}.${prop}`,
		[index]
	)
	const showEjCsnUtbildning = ejCsnUtbildningRequired(franCsnUr, utbildningForm?.ooFinansierarKoptUtb)
	const showVasentligSkillnad = utbildningForm?.slutfordTidigare
	const utbildningarHasErrors = errors?.yttrande?.utbildningar?.length > 0

	useEffect(() => {
		if (!showEjCsnUtbildning) {
			setValue(getFormPathFor('ejCsnUtbildning'), undefined)
		}
		if (!showVasentligSkillnad) {
			setValue(getFormPathFor('vasentligSkillnad'), undefined)
		}
	}, [showEjCsnUtbildning, showVasentligSkillnad, getFormPathFor, setValue])

	const starksFramtidaStallningIsAvstar = yttrande?.starksFramtidaStallning === StarksFramtidaStallning.Avstar

	return {
		getUtbildningPath,
		utbildningarHasErrors,
		utbildningForm,
		showEjCsnUtbildning,
		showVasentligSkillnad,
		starksFramtidaStallningIsAvstar,
	}
}

export default useUtbildningFormContext
