import { SelectOption } from '@local/src/Components/FormComponents/Dropdown/Dropdown.types'
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SxProps, Theme } from '@mui/material'
import React, { useEffect, useState } from 'react'

interface Props {
	label: string
	value: string | number
	setValue: React.Dispatch<React.SetStateAction<number | string>>
	rangeFrom?: number
	rangeLength?: number
	errorMsg?: string
	testId?: string
	isReadOnly?: boolean
	wrapperSx?: SxProps<Theme>
}

const RangeDropdown = ({
	label,
	value,
	setValue,
	rangeFrom = 1,
	rangeLength = 10,
	errorMsg,
	testId,
	isReadOnly = false,
	wrapperSx = {},
}: Props) => {
	const [selectedOption, setSelectedOption] = useState<string | number>(value || undefined)

	useEffect(() => {
		if (!selectedOption) return
		setValue(selectedOption)
	}, [selectedOption, setValue])

	const options = Array.from({ length: rangeLength }, (_, i) => i + rangeFrom).map(
		(val): SelectOption => ({
			label: val.toString(),
			value: val < 10 ? `0${val}` : val.toString(),
		})
	)
	const getOptions = () => {
		const currentValueExistInOptions = options.find((o) => o.value === value.toString())
		if (currentValueExistInOptions) return options
		options.push({
			label: value.toString(),
			value: Number(value) < 10 ? `0${value}` : value.toString(),
		})
		return options
	}
	return (
		<FormControl fullWidth error={Boolean(errorMsg)}>
			<InputLabel>{label}</InputLabel>
			<Select
				onChange={(event) => {
					setSelectedOption(event.target.value)
				}}
				value={selectedOption}
				label={label}
				data-testid={testId}
				inputProps={{ disabled: isReadOnly }}
				MenuProps={{
					sx: {
						maxHeight: '500px',
					},
				}}
				sx={wrapperSx}
			>
				{getOptions().map((option) => (
					<MenuItem key={`${option.value}`} value={option.value}>
						{option.label}
					</MenuItem>
				))}
			</Select>
			<FormHelperText>{errorMsg}</FormHelperText>
		</FormControl>
	)
}

export default RangeDropdown
