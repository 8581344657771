import { studiestodApi } from '@local/src/Services/apiEndpointHelper'
import { apiGetTryCatchWrapper } from '@local/src/Services/apiRequestHelper'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { isNotNil } from 'ramda'

const useGetCurrentStudiestodansokan = (arendeId: string, ansokanId: string, enabled = true) => {
	const queryClient = useQueryClient()
	const queryKey = ['getStudiestodsansokanById', ansokanId]
	const paramsAreDefined = isNotNil(arendeId) && isNotNil(ansokanId)

	const { data: studiestodAnsokan, ...query } = useQuery({
		queryKey,
		queryFn: () => apiGetTryCatchWrapper('useGetCurrentStudiestodansokan', studiestodApi.arendeAnsokanDetail, arendeId, ansokanId),
		enabled: enabled && paramsAreDefined,
	})

	const invalidateQuery = async () => await queryClient.invalidateQueries(queryKey)

	return { studiestodAnsokan, ...query, invalidateQuery }
}

export default useGetCurrentStudiestodansokan
