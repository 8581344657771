import { csnKompletterandeBidragApi } from '@local/src/Services/apiEndpointHelper'
import { apiGetTryCatchWrapper } from '@local/src/Services/apiRequestHelper'
import { useQuery } from '@tanstack/react-query'

export const useGetKompletterandeBidrag = () => {
	const { data: csnKompletterandeBidragFromCsn, ...query } = useQuery({
		queryKey: ['getKompletterandeBidrag'],
		queryFn: () => apiGetTryCatchWrapper('useGetKompletterandeBidrag', csnKompletterandeBidragApi.csnArendeKompletterandeBidragList),
		placeholderData: [],
	})

	return { csnKompletterandeBidragFromCsn, ...query }
}
