import { useUrlQuery } from '@local/src/Hooks'
import { Box, Button, Popover } from '@mui/material'
import React from 'react'
import ReactJson, { OnCopyProps } from 'react-json-view'

interface Props {
	data: object
}
const TechnicalDetailPopover = ({ data }: Props) => {
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

	const query = useUrlQuery()

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const open = Boolean(anchorEl)
	const id = open ? 'simple-popover' : undefined
	const copyToClipboard = async (copy: OnCopyProps) => {
		const hasNavigator = Boolean(navigator.clipboard)
		try {
			if (hasNavigator) {
				await navigator.clipboard.writeText(JSON.stringify(copy.src))
				return
			}
			const el = document.createElement('textarea')
			el.value = JSON.stringify(copy.src)
			el.setAttribute('readonly', '')
			el.style.position = 'absolute'
			el.style.left = '-9999px'
			document.body.appendChild(el)
			el.select()
			document.execCommand('copy')
			document.body.removeChild(el)
		} catch (error) {
			console.log(error)
		}
	}

	if (query.get('detailView') !== 'true') return null
	return (
		<Box mb={3}>
			<Button aria-describedby={id} variant="contained" onClick={handleClick}>
				Visa tekniska detaljer
			</Button>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<ReactJson iconStyle="square" name={null} src={data} theme="flat" displayDataTypes={false} enableClipboard={copyToClipboard} />
			</Popover>
		</Box>
	)
}

export default TechnicalDetailPopover
