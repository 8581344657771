import { KompletterandeBidragWithUserProfileMetaApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { useState } from 'react'

const useKompletterandeBidragFilter = (data: KompletterandeBidragWithUserProfileMetaApiModel[]) => {
	const initialState = {
		sentToCsn: false,
		newBegaran: false,
	}
	const [filters, setFilters] = useState(initialState)
	const [ssnFilter, setSsnFilter] = useState('')

	const filterCounter = {
		sentToCsn: data?.filter((b) => b.shouldSendToCsn).length,
		newBegaran: data?.filter((b) => !b.shouldSendToCsn).length,
	}

	const handleToggleSentToCsn = () =>
		setFilters({
			...initialState,
			sentToCsn: !filters.sentToCsn,
		})

	const handleToggleNewBegaran = () =>
		setFilters({
			...initialState,
			newBegaran: !filters.newBegaran,
		})

	const filteredData = data
		?.filter((k) => {
			if (filters.sentToCsn) return k.shouldSendToCsn
			if (filters.newBegaran) return !k.shouldSendToCsn
			return true
		})
		.filter((k) => {
			if (ssnFilter) return k?.userProfileKompletterandeBidrag?.personnummer?.includes(ssnFilter)
			return true
		})

	const hasFilterData = filteredData?.length > 0
	return {
		ssnFilter,
		setSsnFilter,
		filters,
		handleToggleSentToCsn,
		handleToggleNewBegaran,
		filteredData,
		filterCounter,
		hasFilterData,
	}
}

export default useKompletterandeBidragFilter
