import { useEpiContentWithFallback } from '@local/src/Hooks'
import { isValidUrl, removeDuplicateSlashes } from '@local/src/Utils/urlHelper'
import React, { Fragment, useEffect, useState } from 'react'

export interface RedirectPageContent {
	redirectTo: string
}

const RedirectPage = () => {
	const { redirectPage } = useEpiContentWithFallback()
	const [isRedirecting, setIsRedirecting] = useState(false)
	useEffect(() => {
		if (isRedirecting) return
		const fullUrl = removeDuplicateSlashes(`${window.location.origin}/${redirectPage?.redirectTo}${window.location.search}`)

		if (isValidUrl(fullUrl)) {
			setIsRedirecting(true)
			window.location.href = `${redirectPage?.redirectTo}${window.location.search}`
		}
	}, [isRedirecting, redirectPage?.redirectTo])
	return <Fragment />
}

export default RedirectPage
