/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
	AnySchema,
	addMethod,
	array as yupArray,
	boolean as yupBoolean,
	number as yupNumber,
	object as yupObject,
	string as yupString,
} from 'yup'

declare module 'yup' {
	interface BaseSchema {
		undefinedOrNullSchema(): this
	}
}
function undefinedOrNullSchema(this: AnySchema) {
	return this.nullable()
		.default(undefined)
		.test((obj?: unknown) => obj === undefined || obj === null)
}
addMethod(yupObject, 'undefinedOrNullSchema', undefinedOrNullSchema)
addMethod(yupBoolean, 'undefinedOrNullSchema', undefinedOrNullSchema)
addMethod(yupNumber, 'undefinedOrNullSchema', undefinedOrNullSchema)
addMethod(yupString, 'undefinedOrNullSchema', undefinedOrNullSchema)
addMethod(yupArray, 'undefinedOrNullSchema', undefinedOrNullSchema)
addMethod(yupArray, 'undefinedOrNullOrEmptySchema', function undefinedOrNullOrEmptySchema(this: AnySchema) {
	return this.nullable()
		.default(undefined)
		.test((arr?: unknown[]) => arr === undefined || arr === null || arr.length === 0)
})
