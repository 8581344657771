import { ResourceContent } from '@local/src/Content/resources'
import { ChangeRecordApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { BeslutForandringPropName, useFormatProp } from '../../HistorikTabHelpers'

import { StyledTableEntry } from './StyledTableEntry'

type TranslationKeys = keyof ResourceContent['csnPortal']['beslut']['tabs']['labels']
interface Props {
	change: ChangeRecordApiModel
	nestedEntry?: boolean
}

const TableEntry = ({ change, nestedEntry = false }: Props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'csnPortal.beslut.tabs.labels' })
	const { formatProp } = useFormatProp()
	const propertyName = change.propertyName as TranslationKeys
	return (
		<Stack direction={'row'} justifyContent={'space-between'} data-testid={`${change.occuredAt}-${change.propertyName}`}>
			<Box width="150px" />
			<StyledTableEntry isNested={nestedEntry}>
				<Typography title={t(propertyName)}>{t(propertyName)}</Typography>
			</StyledTableEntry>
			<StyledTableEntry>
				<Typography fontWeight={'bold'} data-testid={`${change.occuredAt}-${change.propertyName}-newValue`}>
					{formatProp(change.newValue, change.propertyName as BeslutForandringPropName)}
				</Typography>
			</StyledTableEntry>
			<StyledTableEntry>
				<Typography data-testid={`${change.occuredAt}-${change.propertyName}-prevValue`}>
					{formatProp(change.prevValue, change.propertyName as BeslutForandringPropName)}
				</Typography>
			</StyledTableEntry>
		</Stack>
	)
}

export default TableEntry
