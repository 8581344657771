import { csnArendeApi } from '@local/src/Services/apiEndpointHelper'
import { apiGetTryCatchWrapper } from '@local/src/Services/apiRequestHelper'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useRouteMatch } from 'react-router-dom'

export const useGetCsnArendeDetail = (grundansokanId?: string, isEnabled = false) => {
	const match = useRouteMatch<{ grundansokanId: string }>({ path: '*/medarbetare/csn-arende/:grundansokanId' })

	const currentGrundAnsokanId = grundansokanId || match?.params?.grundansokanId

	const queryClient = useQueryClient()
	const queryKey = ['csnArendeDetail', currentGrundAnsokanId]
	const { data: csnArendeApiModel, ...query } = useQuery({
		queryKey,
		queryFn: () => apiGetTryCatchWrapper('useGetCsnArendeDetail', csnArendeApi.csnArendeDetail, currentGrundAnsokanId),
		enabled: Boolean(currentGrundAnsokanId) && isEnabled,
	})

	const invalidateQuery = async () => await queryClient.invalidateQueries(queryKey)

	return { csnArendeApiModel, ...query, invalidateQuery }
}
