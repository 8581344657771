/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
	CsnArendeApiModel,
	SearchYttrandenRequestApiModel,
	SearchYttrandenResponseApiModel,
	TrrProblemDetails,
	TrrValidationProblemDetails,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class CsnArende<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
	/**
	 * No description
	 *
	 * @tags CsnArende
	 * @name CsnArendeDetail
	 * @request GET:/studiestod/csn-arende/{grundansokanId}
	 * @secure
	 * @response `200` `CsnArendeApiModel` OK
	 * @response `500` `TrrProblemDetails` Internal Server Error
	 */
	csnArendeDetail = (grundansokanId: string, params: RequestParams = {}) =>
		this.request<CsnArendeApiModel, TrrProblemDetails>({
			path: `/studiestod/csn-arende/${grundansokanId}`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		})
	/**
	 * No description
	 *
	 * @tags CsnArende
	 * @name CsnArendeYttrandenSearchCreate
	 * @request POST:/studiestod/csn-arende/yttranden/search
	 * @secure
	 * @response `200` `SearchYttrandenResponseApiModel` OK
	 * @response `400` `TrrValidationProblemDetails` Bad Request
	 * @response `500` `TrrProblemDetails` Internal Server Error
	 */
	csnArendeYttrandenSearchCreate = (data: SearchYttrandenRequestApiModel, params: RequestParams = {}) =>
		this.request<SearchYttrandenResponseApiModel, TrrValidationProblemDetails | TrrProblemDetails>({
			path: `/studiestod/csn-arende/yttranden/search`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		})
}
