import { ApiResponseModel } from '@local/src/Services/ApiResponseModel'
import { SvarBegaranYttrandeApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { csnBegaranYttrandeApi } from '@local/src/Services/apiEndpointHelper'
import { apiRequestTryCatchWrapper } from '@local/src/Services/apiRequestHelper'
import { getApiResponseError } from '@local/src/Services/apiResponseHelper'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'

export interface PostSvarBegaranYttrandeProps {
	grundansokanId: string
	begaranId: string
	data: SvarBegaranYttrandeApiModel
	shouldSendToCsn?: boolean
}

export const usePostSvarBegaranYttrande = () => {
	const queryClient = useQueryClient()

	const [apiResponse, setApiResponse] = useState<ApiResponseModel<void>>(undefined)

	const postSvarBegaranYttrandeMutation = useMutation({
		mutationFn: (param: PostSvarBegaranYttrandeProps) => {
			setApiResponse(undefined)

			const queryData = { shouldSendToCsn: param.shouldSendToCsn, grundansokanId: param.grundansokanId, begaranId: param.begaranId }
			return apiRequestTryCatchWrapper(
				'usePostSvarBegaranYttrande',
				csnBegaranYttrandeApi.csnArendeBegaranYttrandeSvarCreate,
				queryData,
				param.data
			)
		},
		onSuccess: async (response) => {
			setApiResponse(response)
			if (response.isSuccess) {
				await queryClient.invalidateQueries()
			}
		},
		onError: (response: { message?: string }) => {
			setApiResponse(getApiResponseError(response?.message))
		},
	})
	return { postSvarBegaranYttrandeMutation, apiResponse }
}
